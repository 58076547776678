<div class="row">
    <div class="col-sm-12">
        <div class="text-center">
            <h3 class="">User Guide</h3>
            <p class="text-muted mt-3"> Nisi praesentium similique totam odio obcaecati, reprehenderit,
                dignissimos rem temporibus ea inventore alias!<br /> Beatae animi nemo ea
                tempora, temporibus laborum facilis ut!</p>

            <button type="button" class="btn btn-success btn-sm mt-2"><i class="mdi  me-1"></i> Submit a Ticket</button>
            <button type="button" class="btn btn-info btn-sm mt-2 ms-1"><i class="mdi mdi-headset me-1"></i> Send an Email</button>
        </div>
    </div><!-- end col -->
</div><!-- end row -->

<!-- 
<div class="row pt-5">
    <ng-container *ngFor="let item of rawFAQ">
        <div class="col-lg-5 offset-lg-1" *ngIf="item.id%2!=0">
            <div>
                <div class="faq-question-q-box">Q.</div>
                <h4 class="faq-question" [class]="item.titleClass">{{item.question}}</h4>
                <p class="faq-answer mb-4" [class]="item.textClass">{{item.answer}}</p>
            </div>
        </div>

        <div class="col-lg-5" *ngIf="item.id%2==0">
            <div>
                <div class="faq-question-q-box">Q.</div>
                <h4 class="faq-question" [class]="item.titleClass">{{item.question}}</h4>
                <p class="faq-answer mb-4" [class]="item.textClass">{{item.answer}}</p>
            </div>
        </div>

    </ng-container>
</div> -->