<app-ui-preloader [display]="loading"></app-ui-preloader>

<div class="row">
    <div class="col-12">
        <div class="page-title-box">
            <div class="page-title-right">
                <form class="d-flex">

                </form>
            </div>
            <h4 class="page-title" i18n>Dashboard</h4>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card widget-inline">
            <div class="card-body p-0">
                <div class="row g-0">
                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-multiple-outline text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span *ngIf="allProjectCount">{{allProjectCount}}</span>
                                    <span *ngIf="!allProjectCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Total Projects</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-secondary"
                                        *ngIf="projectCommitteeRecommendationPendingCount">{{projectCommitteeRecommendationPendingCount}}</span>
                                    <span *ngIf="!projectCommitteeRecommendationPendingCount">0</span>
                                    <span>/</span>
                                    <span *ngIf="allProjectCount">{{allProjectCount}}</span>
                                    <span *ngIf="!allProjectCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Pending Projects</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-primary"
                                        *ngIf="projectCommitteeRecommendationUnderReviewCount">{{projectCommitteeRecommendationUnderReviewCount}}</span>
                                    <span *ngIf="!projectCommitteeRecommendationUnderReviewCount">0</span>
                                    <span>/</span>
                                    <span *ngIf="allProjectCount">{{allProjectCount}}</span>
                                    <span *ngIf="!allProjectCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Under Review Projects</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="card shadow-none m-0 border-start">
                            <div class="card-body text-center">
                                <i class="mdi mdi-file-sign text-muted" style="font-size: 24px;"></i>
                                <h3>
                                    <span class="text-success"
                                        *ngIf="projectCommitteeRecommendationApprovedCount">{{projectCommitteeRecommendationApprovedCount}}</span>
                                    <span *ngIf="!projectCommitteeRecommendationApprovedCount">0</span>
                                    <span>/</span>
                                    <span *ngIf="allProjectCount">{{allProjectCount}}</span>
                                    <span *ngIf="!allProjectCount">0</span>
                                </h3>
                                <p class="text-muted font-15 mb-0">Approved Projects</p>
                            </div>
                        </div>
                    </div>

                </div> <!-- end row -->
            </div>
        </div> <!-- end card-box-->
    </div> <!-- end col-->
</div>

<div class="row">
    <div class="col-xl-3 col-lg-4">

        <div class="card tilebox-one" *ngFor="let m of statWidget">
            <div class="card-body">
                <i class="{{m.icon}} float-end"></i>
                <h6 class="text-uppercase mt-0" i18n>{{m.title}}</h6>
                <h2 class="my-2">{{m.value}}</h2>
                <p class="mb-0 text-muted">
                    <span class="text-success me-2" *ngIf="m.arrow=='up'"><span
                            class="mdi mdi-arrow-up-bold"></span>{{m.change}}</span>
                    <span class="text-danger me-2" *ngIf="m.arrow=='down'"><span
                            class="mdi mdi-arrow-down-bold"></span>{{m.change}}</span>
                    <span class="text-nowrap" i18n>{{m.subtitle}}</span>
                </p>
            </div> <!-- end card-body-->
        </div>

    </div>

    <div class="col-xl-9 col-lg-8">
        <div class="card">
            <div class="card-header">Active Stations</div>
            <div class="card-body">
                <google-map #myGoogleMap height="300px" width="100%" (mapClick)="moveMap($event)"
                    (mapMousemove)="move($event)" [zoom]="zoom" [center]="center" [options]="options">
                    <map-marker *ngIf="markerPosition" [position]="markerPosition"
                        [options]="markerOptions"></map-marker>
                    <map-info-window>
                        <span>Nairobi Chapel</span>
                    </map-info-window>
                    <map-rectangle [bounds]="bounds"></map-rectangle>
                </google-map>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-lg-6">
        <div class="card text-white bg-info overflow-hidden" role="button" (click)="navigateTo('new project')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-document-edit-outline"></i> Create a project.</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card text-white bg-danger overflow-hidden" role="button" (click)="navigateTo('project')">
            <div class="card-body">
                <div class="toll-free-box text-center">
                    <h4> <i class="mdi mdi-file-chart-outline"></i> View existing projects.</h4>
                </div>
            </div>
        </div>
    </div>

</div>