import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgbAlertModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { ProjectService } from 'src/app/core/service/project.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { GoogleMapsModule } from '@angular/google-maps';

type MapConfig = {
  lat: number;
  lng: number;
  title?: string;
  markers?: MapConfig[];
  styles?: any[];
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    GoogleMapsModule,
    NgbTooltipModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  statWidget: { title: string; value: number; change?: string; arrow?: string; subtitle?: string; icon: string; }[];
  loading: boolean = false;
  subscription: Subscription = new Subscription();
  allProjectsArray: ProjectDetails[];

  allProjectCount: number = 0;
  projectCommitteeRecommendationPendingCount: number = 0;
  projectCommitteeRecommendationUnderReviewCount: number = 0;
  projectCommitteeRecommendationApprovedCount: number = 0;
  projectCommitteeRecommendationRejectedCount: number = 0;
  currentYearTotalCommitment: number = 0;
  currentYearTotalProfit: number = 0;
  currentYear: any;
  workingCapitalYear3: any;
  countryFXRateLocalToUsd: any;
  countryFXRateUsdToLocal: number;
  capitalExpenditure: any;
  loanToDealer: any;
  estimatedGroupCommitment: any;
  estimatedGroupCommitmentUSD: number;
  capitalExpenditureUSD: number;
  workingCapitalYear3USD: number;
  loanToDealerUSD: number;

  // Map
  zoom = 5;
  center: google.maps.LatLngLiteral = { lat: -1.129573, lng: 36.9845406, };
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false, label: `Delta Service Station`, title: `Delta Service Station` };
  markerPosition: { lat: number; lng: number; } = { lat: -1.129573, lng: 36.9845406, };

  display: google.maps.LatLngLiteral;
  gmapConfig2!: MapConfig;
  bounds: google.maps.LatLngBoundsLiteral = {
    east: 10,
    north: 10,
    south: -10,
    west: -10,
  };

  stationMapLocationLatitude: number = -1.129573;
  stationMapLocationLongitude: number = 36.9845406;
  stationName: string = "Delta Service Station";

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private projectService: ProjectService,
  ) {

    let date = new Date();
    this.currentYear = date.getFullYear();

    this.subscription.add(
      this.projectService.getProjectArray().pipe().subscribe((res: ProjectDetails[]) => {
        if (res) {
          this.allProjectsArray = res;
          let xy = res;
          this.allProjectCount = 0;
          this.projectCommitteeRecommendationPendingCount = 0;
          this.projectCommitteeRecommendationUnderReviewCount = 0;
          this.projectCommitteeRecommendationApprovedCount = 0;
          this.projectCommitteeRecommendationRejectedCount = 0;
          xy.forEach(element => {
            if (element[0]) {
              if (element[0].projectCommitteeRecommendation === "Pending") {
                this.projectCommitteeRecommendationPendingCount += 1;
              };
              if (element[0].projectCommitteeRecommendation === "Under Review") {
                this.projectCommitteeRecommendationUnderReviewCount += 1;
              };
              if (element[0].projectCommitteeRecommendation === "Approved") {
                this.projectCommitteeRecommendationApprovedCount += 1;
              };
              if (element[0].projectCommitteeRecommendation === "Rejected") {
                this.projectCommitteeRecommendationRejectedCount += 1;
              };

              if (element[0].sensitivityAnalysis) {
                if (element[0].sensitivityAnalysis.yearlyEscalation) {
                  if (element[0].sensitivityAnalysis.yearlyEscalation.companyInvestment) {
                    this.capitalExpenditure = (element[0].sensitivityAnalysis.yearlyEscalation.companyInvestment)[0];
                    this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure));
                    if (!(Number(this.countryFXRateLocalToUsd) === 0)) {
                      this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd));
                      this.capitalExpenditureUSD = (this.capitalExpenditure / Number(this.countryFXRateLocalToUsd));
                    }
                  };
                }
              }
              if (element[0].country) {
                if (element[0].country.usdFxRate) {
                  this.countryFXRateLocalToUsd = element[0].country.usdFxRate;
                  this.countryFXRateUsdToLocal = ((1) / Number(this.countryFXRateLocalToUsd));
                  if (!(this.countryFXRateUsdToLocal === 0)) {
                    if (this.estimatedGroupCommitment) { this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd)); }
                    if (this.capitalExpenditure) { this.capitalExpenditureUSD = (this.capitalExpenditure / Number(this.countryFXRateLocalToUsd)); }
                    if (this.workingCapitalYear3) { this.workingCapitalYear3USD = (this.workingCapitalYear3 / Number(this.countryFXRateLocalToUsd)); }
                    if (this.estimatedGroupCommitment) { this.estimatedGroupCommitmentUSD = (this.estimatedGroupCommitment / Number(this.countryFXRateLocalToUsd)); }
                    if (this.loanToDealer) { this.loanToDealerUSD = (this.loanToDealer / Number(this.countryFXRateLocalToUsd)); }
                  }
                }
              };
              if (element[0].companyWorkingCapital) {
                if (element[0].companyWorkingCapital.yearlyEscalation) {
                  if (element[0].companyWorkingCapital.yearlyEscalation.workingCapital) {
                    this.workingCapitalYear3 = (element[0].companyWorkingCapital.yearlyEscalation.workingCapital)[2];
                    if (this.workingCapitalYear3) { this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure)); }
                  }
                }
              };
              if (element[0].companyFinancing) {
                if (element[0].companyFinancing.loanAmount) {
                  this.loanToDealer = element[0].companyFinancing.loanAmount;
                  if (this.loanToDealer) { this.estimatedGroupCommitment = ((this.loanToDealer) + (this.workingCapitalYear3) + (this.capitalExpenditure)); }
                };
              }

              if (this.estimatedGroupCommitment) { this.currentYearTotalCommitment += this.estimatedGroupCommitment; }

              this.allProjectCount += 1;
            }
          });

          this.statWidget = [
            {
              "title": "Total Commitment",
              "value": this.currentYearTotalCommitment,
              "icon": 'uil uil-users-alt',
            },
            {
              "title": `Total Profit ${this.currentYear}`,
              "value": this.currentYearTotalProfit,
              "icon": 'uil uil-window-restore'
            }
          ]

        }
      }));
  }

  ngOnInit(): void {

    this.initMapConfig();

    this.statWidget = [
      {
        "title": "Total Commitment",
        "value": this.currentYearTotalCommitment,
        "icon": 'uil uil-users-alt',
      },
      {
        "title": `Total Profit ${this.currentYear}`,
        "value": this.currentYearTotalProfit,
        "icon": 'uil uil-window-restore'
      }
    ];
  }

  // Map
  /**
   * initialize map configuration
   */
  initMapConfig(): void {

    this.gmapConfig2 = {
      lat: this.stationMapLocationLatitude,
      lng: this.stationMapLocationLongitude,
      markers: [
        {
          lat: this.stationMapLocationLatitude,
          lng: this.stationMapLocationLongitude,
          title: `${this.stationName}`
        },
      ]
    }
  }

  mapReady(map: any) {
    map.setOptions({
      zoomControl: "true",
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.TOP_LEFT
      // }
    });
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }
  // Map

  navigateTo(type: string) {

    if (type === "new project") {
      this.router.navigate([`/portal/project/new`]);
      this.toastr.info(`Create a new project!`, "Info!");
    }

    if (type === "project") {
      this.router.navigate([`/portal/project`]);
      this.toastr.info(`View/Edit Project List`, "Info!");
    }

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
