import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { ClockService } from 'src/app/core/service/clock.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { UserService } from 'src/app/core/service/user.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { SecondNavbarComponent } from 'src/app/shared/second-navbar/second-navbar.component';

type MapConfig = {
  lat: number;
  lng: number;
  title?: string;
  markers?: MapConfig[];
  styles?: any[];
}

// data
interface Feature {
  id: number;
  title: string;
  description: string;
  image: string;
  features: string[];
  route: string;
}

interface Layout {
  name: string;
  title: string;
  imageUrl: string;
}

interface Layout2 {
  name: string;
  imageUrl: string;
}

interface ReusableModelArray {
  title: string;
  description: string;
  url: string;
  item: ReusableModelArray[];
}

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterComponent,
    SecondNavbarComponent,
    GoogleMapsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {

  contactForm!: FormGroup;
  submitted: boolean = false;
  gmapConfig2!: MapConfig;
  subscription: Subscription = new Subscription();
  show_alert: boolean;
  alert_message: string;
  alert_type: string;

  zoom = 14;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: { 
    lat: number; 
    lng: number; 
  };
  display: google.maps.LatLngLiteral;

  typesOfWebApplications: ReusableModelArray[];
  typesOfWebDevelopment: ReusableModelArray[];
  techTrendsArray: ReusableModelArray[];
  ourTechStackArray: ReusableModelArray[];
  ourWebDevelopmentProcessArray: ReusableModelArray[];
  meanStackArray: { title: string; description: string; url: string; item: any[]; }[];
  costEstimationArray: { title: string; rate: number; hours: number; cost: number; }[];
  paymentScheduleArray: { date: number; deliverable: string; payment: string; amount: number; }[];
  tick: Subscription;
  maxIndexTT: number = 0;
  maxIndexDP: number = 0;
  enableTabsRotation: any = true;
  invalidPhoneNumber: boolean;
  formSubmitted: boolean;
  loading: boolean;
  error: string;
  companyDetails: { name: string; phoneNumber: string; email: string; apartmentHouseNumber: string; floor: string; building: string; street: string; cityTown: string; country: string; openTimeMondayToFriday: string; openTimeSaturday: string; openTimeSunday: string; };
  userId: string;
  id: string;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private clockService: ClockService,
    private projectService: ProjectService
  ) {

    // Check if user is logged in
    this.subscription.add(
      this.authenticationService.currentUser.pipe().subscribe((res:AppUser) => {
        if(res){
          if(res.userId){
            this.userId = res.userId;
            this.contactForm.get("uid").setValue(res.userId);
          };
          if(res.email){
            this.contactForm.get("email").setValue(res.email);
          };
          if(res.phoneNumber){
            this.contactForm.get("phoneNumber").setValue(res.phoneNumber);
          };
          if(res.firstName){
            this.contactForm.get("firstName").setValue(res.firstName);
          };
          if(res.otherName){
            this.contactForm.get("otherName").setValue(res.otherName);
          };
          if(res.surname){
            this.contactForm.get("surname").setValue(res.surname);
          };
        };

      })
    )

  }


  ngOnInit(): void {

    this.companyDetails = {
      name: "Project Appraisal",
      phoneNumber: "722000000",
      email: "info@financialforecast.cloud",
      apartmentHouseNumber: "210",
      floor: "2nd",
      building: "Delta Suites",
      street: "Wayaki Way",
      cityTown: "Nairobi",
      country: "Kenya",
      openTimeMondayToFriday: "9:00am - 4:00pm",
      openTimeSaturday: "10:00am - 12:00pm",
      openTimeSunday: "Closed",
    }

    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    document.body.classList.remove('a-bg');

    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      otherName: ['', Validators.required],
      surname: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      uid: ['Anonymous']
    });

    this.subscription.add(
      this.contactForm.get("phoneNumber").valueChanges.subscribe(x => {
        if (x) {
          //Check if phoneNumber is 9 digits
          if ((Number(x) > 100000000) && (Number(x) < 999999999)) {
            // If so isValid
            this.invalidPhoneNumber = false;
          } else {
            // If not isInvalid
            this.invalidPhoneNumber = true;
          }
        }

      })
    );

    this.initMapConfig();
  }

  sendMessage(messageLoad) {

    if (!(messageLoad.firstName) || (messageLoad.firstName === "")) {
      this.toastr.error("First name is missing", "Error!");
      return;
    }

    if (!(messageLoad.email) || (messageLoad.email === "")) {
      this.toastr.error("Email is missing", "Error!");
      return;
    }

    if (!(messageLoad.subject) || (messageLoad.subject === "")) {
      this.toastr.error("Subject is missing", "Error!");
      return;
    }

    if (!(messageLoad.message) || (messageLoad.message === "")) {
      this.toastr.error("Message is missing", "Error!");
      return;
    }

    this.subscription.add(
      this.projectService.sendWebsiteMessage(messageLoad).pipe().subscribe((res) => {
        this.contactForm.reset();
        this.toastr.success("Message sent", "Info!");
        return;
      })
    )
  }

  /**
   * initialize map configuration
   */
  initMapConfig(): void {
    this.gmapConfig2 = {
      lat: -1.2624907,
      lng: 36.7663822,
      markers: [
        {
          lat: -1.2624907,
          lng: 36.7663822,
          title: `Galana Energies`
        },
      ]
    }

    this.markerPosition = {
      lat: -1.2624907,
      lng: 36.7663822,
    }

    this.center = {
      lat: -1.2624907,
      lng: 36.7663822,
    }

  }

  mapReady(map: any) {
    map.setOptions({
      zoomControl: "true",
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.TOP_LEFT
      // }
    });
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}