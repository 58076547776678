<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Results</h1>
                    <p class="lead text-muted">Project appraisal results.</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 1. Main Inputs -->
    <div id="stepOne" class="border border-secondary card stepOne">
        <div class="card-header mb-0">
            <span>
                1. Main Inputs
            </span>
        </div>
        <div class="card-body">

            <div class="row mb-3">
                <div class="table-responsive-xxl">
                    <table class="table table-sm table-borderless table-centered mb-0">
                        <thead>
                            <tr>
                                <th class="py-0 px-1">
                                </th>
                                <th class="py-0">
                                </th>
                                <th class="py-0">
                                </th>
                                <th class="py-0">
                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Type of Investment
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="typeOfInvestment">
                                        <span *ngIf="typeOfInvestment">
                                            {{typeOfInvestment}}
                                        </span>
                                    </span>
                                    <span *ngIf="!typeOfInvestment">-</span>
                                </td>
                                <td class="py-0">
                                    <span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Period of Operations
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="(periodOfOperation === 0)">-</span>
                                    <span *ngIf="!(periodOfOperation === 0)">
                                        {{periodOfOperation | number : '1.0-0'}}
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        Years
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Project Start Date
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="(investmentStartDate === 0)">-</span>
                                    <span *ngIf="!(investmentStartDate === 0)">
                                        {{investmentStartDate | date : 'medium'}}
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>

                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Months of Work Before Operations
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="(monthsOfWorkBeforeOperations === 0)">-</span>
                                    <span *ngIf="!(monthsOfWorkBeforeOperations === 0)">
                                        {{monthsOfWorkBeforeOperations | number : '1.0-0'}}
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        Months
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Country FX Rate
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="(countryFXRateLocalToUsd === 0)">-</span>
                                    <span *ngIf="!(countryFXRateLocalToUsd === 0)">
                                        {{countryFXRateLocalToUsd | number : '1.2-2'}}
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/USD
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>

                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        :
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span *ngIf="(countryFXRateUsdToLocal === 0)">-</span>
                                    <span *ngIf="!(countryFXRateUsdToLocal === 0)">
                                        {{(countryFXRateUsdToLocal) | number : '1.5-5'}}
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        USD/<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

    <!-- 2. Main Inputs -->
    <div id="stepTwo" class="border border-secondary card stepTwo">
        <div class="card-header mb-0">
            <span>
                2. Valuation Results
            </span>
        </div>
        <div class="card-body">

            <div class="card">
                <div class="card-header mb-0">
                    <span>
                        Valuation
                    </span>
                </div>
                <div class="card-body">
                    <div class="row mb-3 text-center">
                        <span>
                            Valuation Method: Net Book Value (NBV)
                        </span>
                    </div>

                    <div class="row mb-3">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="py-0 px-1">
                                        </th>
                                        <th class="py-0">
                                        </th>
                                        <th class="py-0 text-end">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Project NPV
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="py-0 text-end">
                                            <span *ngIf="(netPresentValue === 0)">-</span>
                                            <span *ngIf="!(netPresentValue === 0)">
                                                {{netPresentValue | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Project IRR
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="py-0 text-end">
                                            <span *ngIf="(internalRateOfReturn === 0)">-</span>
                                            <span *ngIf="!(internalRateOfReturn === 0)">
                                                {{internalRateOfReturn | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Payback Period
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span>
                                                :
                                            </span>
                                        </td>
                                        <td class="py-0 text-end">
                                            <span *ngIf="(paybackPeriod === 0)">-</span>
                                            <span *ngIf="!(paybackPeriod === 0)">
                                                {{paybackPeriod | number : '1.2-2'}} Years
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>



            <div class="card">
                <div class="card-header mb-0">
                    <span>
                        Valuation Summary
                    </span>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="py-0 px-1">
                                            Valuation
                                        </th>
                                        <th class="py-0">
                                            NPV @
                                            <span *ngIf="discountRateForNetPresentValue">
                                                {{discountRateForNetPresentValue}}
                                            </span>
                                        </th>
                                        <th class="py-0">
                                            0/W Terminal Value
                                        </th>
                                        <th class="py-0">
                                            Project IIR
                                        </th>
                                        <th class="py-0">
                                            Wealth Incr.
                                        </th>
                                        <th class="py-0">
                                            Payback (Years)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                EBITDA multiple 0x
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(ebitdaMultiple0xNetPresentValue === 0)">-</span>
                                            <span *ngIf="!(ebitdaMultiple0xNetPresentValue === 0)">
                                                {{ebitdaMultiple0xNetPresentValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(ebitdaMultiple0xTerminalValue === 0)">-</span>
                                            <span *ngIf="!(ebitdaMultiple0xTerminalValue === 0)">
                                                {{ebitdaMultiple0xTerminalValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(ebitdaMultiple0xIRR === 0)">-</span>
                                            <span *ngIf="!(ebitdaMultiple0xIRR === 0)">
                                                {{ebitdaMultiple0xIRR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(ebitdaMultiple0xWealthINCR === 0)">-</span>
                                            <span *ngIf="!(ebitdaMultiple0xWealthINCR === 0)">
                                                {{ebitdaMultiple0xWealthINCR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(ebitdaMultiple0xPaybackPeriod === 0)">-</span>
                                            <span *ngIf="!(ebitdaMultiple0xPaybackPeriod === 0)">
                                                {{ebitdaMultiple0xPaybackPeriod | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Gordon Shapiro (g=1.5%)
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(gordonShapiroNetPresentValue === 0)">-</span>
                                            <span *ngIf="!(gordonShapiroNetPresentValue === 0)">
                                                {{gordonShapiroNetPresentValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(gordonShapiroTerminalValue === 0)">-</span>
                                            <span *ngIf="!(gordonShapiroTerminalValue === 0)">
                                                {{gordonShapiroTerminalValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(gordonShapiroIRR === 0)">-</span>
                                            <span *ngIf="!(gordonShapiroIRR === 0)">
                                                {{gordonShapiroIRR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(gordonShapiroWealthINCR === 0)">-</span>
                                            <span *ngIf="!(gordonShapiroWealthINCR === 0)">
                                                {{gordonShapiroWealthINCR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(gordonShapiroPaybackPeriod === 0)">-</span>
                                            <span *ngIf="!(gordonShapiroPaybackPeriod === 0)">
                                                {{gordonShapiroPaybackPeriod | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Capital Employed NBV
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(capitalEmployedNBVNetPresentValue === 0)">-</span>
                                            <span *ngIf="!(capitalEmployedNBVNetPresentValue === 0)">
                                                {{capitalEmployedNBVNetPresentValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(capitalEmployedNBVTerminalValue === 0)">-</span>
                                            <span *ngIf="!(capitalEmployedNBVTerminalValue === 0)">
                                                {{capitalEmployedNBVTerminalValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(capitalEmployedNBVIRR === 0)">-</span>
                                            <span *ngIf="!(capitalEmployedNBVIRR === 0)">
                                                {{capitalEmployedNBVIRR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(capitalEmployedNBVWealthINCR === 0)">-</span>
                                            <span *ngIf="!(capitalEmployedNBVWealthINCR === 0)">
                                                {{capitalEmployedNBVWealthINCR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(capitalEmployedNBVPaybackPeriod === 0)">-</span>
                                            <span *ngIf="!(capitalEmployedNBVPaybackPeriod === 0)">
                                                {{capitalEmployedNBVPaybackPeriod | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Terminal Value as Input
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(terminalValueAsInputNetPresentValue === 0)">-</span>
                                            <span *ngIf="!(terminalValueAsInputNetPresentValue === 0)">
                                                {{terminalValueAsInputNetPresentValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(terminalValueAsInputTerminalValue === 0)">-</span>
                                            <span *ngIf="!(terminalValueAsInputTerminalValue === 0)">
                                                {{terminalValueAsInputTerminalValue | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(terminalValueAsInputIRR === 0)">-</span>
                                            <span *ngIf="!(terminalValueAsInputIRR === 0)">
                                                {{terminalValueAsInputIRR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(terminalValueAsInputWealthINCR === 0)">-</span>
                                            <span *ngIf="!(terminalValueAsInputWealthINCR === 0)">
                                                {{terminalValueAsInputWealthINCR | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0">
                                            <span *ngIf="(terminalValueAsInputPaybackPeriod === 0)">-</span>
                                            <span *ngIf="!(terminalValueAsInputPaybackPeriod === 0)">
                                                {{terminalValueAsInputPaybackPeriod | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <!-- 3. Main Inputs -->
    <div id="stepThree" class="border border-secondary card stepThree">
        <div class="card-header mb-0">
            <span>
                3. Estimated Group Commitment
            </span>
        </div>
        <div class="card-body">

            <div class="row mb-3">
                <div class="table-responsive-xxl">
                    <table class="table table-sm table-borderless table-centered mb-0">
                        <thead>
                            <tr>
                                <th class="py-0 px-1">
                                </th>
                                <th class="py-0">
                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                </th>
                                <th class="py-0">
                                    USD
                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Capitalized Land Lease & Fees
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(capitalizedLandLeaseAndFees === 0)">-</span>
                                        <span *ngIf="!(capitalizedLandLeaseAndFees === 0)">
                                            {{capitalizedLandLeaseAndFees | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(capitalizedLandLeaseAndFeesUSD === 0)">-</span>
                                        <span *ngIf="!(capitalizedLandLeaseAndFeesUSD === 0)">
                                            {{capitalizedLandLeaseAndFeesUSD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Capital Expenditure
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(capitalExpenditure === 0)">-</span>
                                        <span *ngIf="!(capitalExpenditure === 0)">
                                            {{capitalExpenditure | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(capitalExpenditureUSD === 0)">-</span>
                                        <span *ngIf="!(capitalExpenditureUSD === 0)">
                                            {{capitalExpenditureUSD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Other Initial Costs
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(otherInitialCosts === 0)">-</span>
                                        <span *ngIf="!(otherInitialCosts === 0)">
                                            {{otherInitialCosts | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(otherInitialCostsUSD === 0)">-</span>
                                        <span *ngIf="!(otherInitialCostsUSD === 0)">
                                            {{otherInitialCostsUSD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Working Capital - Year 3
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(workingCapitalYear3 === 0)">-</span>
                                        <span *ngIf="!(workingCapitalYear3 === 0)">
                                            {{workingCapitalYear3 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(workingCapitalYear3USD === 0)">-</span>
                                        <span *ngIf="!(workingCapitalYear3USD === 0)">
                                            {{workingCapitalYear3USD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Loan to Dealer
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(loanToDealer === 0)">-</span>
                                        <span *ngIf="!(loanToDealer === 0)">
                                            {{loanToDealer | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(loanToDealerUSD === 0)">-</span>
                                        <span *ngIf="!(loanToDealerUSD === 0)">
                                            {{loanToDealerUSD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Estimated Group Commitment
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(estimatedGroupCommitment === 0)">-</span>
                                        <span *ngIf="!(estimatedGroupCommitment === 0)">
                                            {{estimatedGroupCommitment | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(estimatedGroupCommitmentUSD === 0)">-</span>
                                        <span *ngIf="!(estimatedGroupCommitmentUSD === 0)">
                                            {{estimatedGroupCommitmentUSD | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

    <!-- 4. Key Performance Indicators -->
    <div id="stepFour" class="border border-secondary card stepFour">
        <div class="card-header mb-0">
            <span>
                4. Key Performance Indicators
            </span>
        </div>
        <div class="card-body">

            <div class="row mb-3">
                <div class="table-responsive-xxl">
                    <table class="table table-sm table-borderless table-centered mb-0">
                        <thead>
                            <tr>
                                <th class="py-0 px-1">
                                </th>
                                <th class="py-0">
                                    Company
                                </th>
                                <th class="py-0">

                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        L/C in Volume (%)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(lOverCInVolume === 0)">-</span>
                                        <span *ngIf="!(lOverCInVolume === 0)">
                                            {{lOverCInVolume | number : '1.2-2'}}%
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Investments Per M3 (USD/M3)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(investmentPerM3 === 0)">-</span>
                                        <span *ngIf="!(investmentPerM3 === 0)">
                                            {{investmentPerM3 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Investments Per M3 (Incl. Capitalized Land Lease) (USD/M3)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(investmentIncludingCapitalizedLandLeasePerM3 === 0)">-</span>
                                        <span *ngIf="!(investmentIncludingCapitalizedLandLeasePerM3 === 0)">
                                            {{investmentIncludingCapitalizedLandLeasePerM3 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Shop Turnover Per M2 (USD/M2)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(shopTurnoverPerM2 === 0)">-</span>
                                        <span *ngIf="!(shopTurnoverPerM2 === 0)">
                                            {{shopTurnoverPerM2 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Turnover Per M3 (USD/M3)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(turnoverPerM3 === 0)">-</span>
                                        <span *ngIf="!(turnoverPerM3 === 0)">
                                            {{turnoverPerM3 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Rental Lease Per M2 (USD/M2)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(rentalLeasePerM2 === 0)">-</span>
                                        <span *ngIf="!(rentalLeasePerM2 === 0)">
                                            {{rentalLeasePerM2 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                            <tr>
                                <td class="py-0 px-1">
                                    <span>
                                        Rental Lease Per M3 (USD/M3)
                                    </span>
                                </td>
                                <td class="py-0">
                                    <span>
                                        <span *ngIf="(rentalLeasePerM3 === 0)">-</span>
                                        <span *ngIf="!(rentalLeasePerM3 === 0)">
                                            {{rentalLeasePerM3 | number : '1.2-2'}}
                                        </span>
                                    </span>
                                </td>
                                <td class="py-0">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- 5. EBITDA & Net Cash After Tax -->
    <div id="stepFive" class="border border-secondary card stepFive">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:66%">
                                5. EBITDA & Net Cash After Tax
                            </td>
                            <td class="p-0" style="width:33%">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <div class="board">
                <table class="table table-sm table-bordeless table-centered mb-0">
                    <thead>
                        <tr>
                            <th style="min-width:50px">

                            </th>
                            <th>
                                Y - 0
                            </th>
                            <th>
                                Y - 1
                            </th>
                            <th>
                                Y - 2
                            </th>
                            <th>
                                Y - 3
                            </th>
                            <th>
                                Y - 4
                            </th>
                            <th>
                                Y - 5
                            </th>
                            <th>
                                Y - 6
                            </th>
                            <th>
                                Y - 7
                            </th>
                            <th>
                                Y - 8
                            </th>
                            <th>
                                Y - 9
                            </th>
                            <th>
                                Y - 10
                            </th>
                            <th>
                                Y - 11
                            </th>
                            <th>
                                Y - 12
                            </th>
                            <th>
                                Y - 13
                            </th>
                            <th>
                                Y - 14
                            </th>
                            <th>
                                Y - 15
                            </th>
                        </tr>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Fuel volume (m3)
                                </span>
                            </td>
                            <td class="px-1 py-0" *ngFor="let m of fuelVolumeArray;let i = index;">
                                <span>
                                    <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                </span>
                            </td>
                            <td class="px-1 py-0" *ngFor="let m of fuelMarginArray;let i = index;">
                                <span>
                                    <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Fuel Margin</strong></p>
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryNetFuelMarginArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Shop Margin
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companySummaryDiversificationShopMarginArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Car Wash Margin
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companySummaryDiversificationCarWashMarginArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Others
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companySummaryDiversificationMarginOthersArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Diversification Margin</strong></p>
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companySummaryDiversificationMarginArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>

                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Operating Losses (Variable)
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesOperatingLossesVariableArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Other Costs
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesOtherCostsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Electricity & Miscellaneous Operating Expenses
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesElectricityAndMiscellaneousOperatingExpensesArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Staff Cost (Partly Variable)
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesStaffCostsPartlyVariableArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Operating Expenses (From Investments)
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesOperatingExpensesFromInvestmentsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Others Expenses
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesOperatingOtherExpensesArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Land Lease Rents
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesLandLeaseRentsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Yearly Rent of Equipment
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesYearlyRentOfEquipmentArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Earnings from 3rd Party Contracts
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesEarningsFrom3rdPartyContractsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Other Earnings
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesOtherEarningsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Tax on Fuel Turnover
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyOperatingExpensesTaxOnFuelTurnoverArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>

                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Operating Expenses - Excl. Overheads</strong></p>
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companySummaryDiversificationMarginArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Variable Fees - Fuel
                                </span>
                            </td>
                            <td class="py-0 px-1"
                                *ngFor="let m of companyFeesReceivedVariableFeesFuelArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Fixed Fees - Fuel
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companyFeesReceivedFixedFeesFuelArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Variable Fees - Diversification
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companyFeesReceivedVariableFeesDiversificationArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Fixed Fees - Diversification
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companyFeesReceivedFixedFeesDiversificationArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Variable Fees - Card Commission
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companyFeesReceivedVariableFeesCardCommissionArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Variable Fees - Due
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companyFeesReceivedVariableFeesDueArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Fixed Fees - Due
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companyFeesReceivedFixedFeesDueArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Fees & Other Revenues</strong></p>
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryOtherRevenueArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>EBITDA - Before Overhead Costs</strong></p>
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companySummaryEBITDABeforeOverheadCostsArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Financial Interest - Loan to Dealer
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Overhead Costs
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryOperatingExpensesArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>EBITDA</strong></p>
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryEBITDAArray;let i = index;">
                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Investments
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryInvestmentArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Change in Working Capital
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryChangeInWorkingCapitalArray;let i = index;">
                                <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Taxes
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryTaxesArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Loan to Dealer
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryOtherCashItemsArray;let i = index;">
                                <span *ngIf="!(i===0)">
                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                                <span *ngIf="(i===0)">
                                    <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span class="ps-1">
                                    Terminal Value - Nominal Terms
                                </span>
                            </td>
                            <td class="py-0"
                                *ngFor="let m of companySummaryTerminalValueNominalTermsArray;let i = index;">
                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                <span *ngIf="m===0">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong>
                                    </p>
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                <span *ngIf="!(i===0)">
                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                                <span *ngIf="(i===0)">
                                    <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="p-0 px-1">
                                <span>
                                    <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong>
                                    </p>
                                </span>
                            </td>
                            <td class="py-0" *ngFor="let m of companySummaryNetCashAfterTaxRealArray;let i = index;">
                                <span *ngIf="!(i===0)">
                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                                <span *ngIf="(i===0)">
                                    <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                    <span *ngIf="m===0">-</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card">
                <div class="card-header mb-0">
                    <span>Cumulated Cash Flow (Constant Money)</span>
                </div>
                <div class="card-body">
                    <div id="line-chart-datalabel">
                        <apx-chart class="apex-charts" #cashFlowChart [series]="lineChartCumulatedCashFlow.series!"
                            [chart]="lineChartCumulatedCashFlow.chart!" [xaxis]="lineChartCumulatedCashFlow.xaxis!"
                            [dataLabels]="lineChartCumulatedCashFlow.dataLabels!"
                            [grid]="lineChartCumulatedCashFlow.grid!" [stroke]="lineChartCumulatedCashFlow.stroke!"
                            [labels]="lineChartCumulatedCashFlow.labels!"
                            [annotations]="lineChartCumulatedCashFlow.annotations!"
                            [colors]="lineChartCumulatedCashFlow.colors!"></apx-chart>
                    </div>
                </div>
                <!-- end card body-->
            </div>
        </div>
    </div>

    <!-- 6. Sensitivity -->
    <div id="stepSix" class="border border-secondary card stepSix">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:66%">
                                6. Sensitivity
                            </td>
                            <td class="p-0" style="width:33%">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">

            <div class="card">
                <div class="card-header">
                    Investment (-10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentMinus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentMinus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(investmentMinus10NPV === 0)">-</span>
                        <span *ngIf="!(investmentMinus10NPV === 0)">
                            {{investmentMinus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(investmentMinus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(investmentMinus10PaybackPeriod === 0)">
                            {{investmentMinus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(investmentMinus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(investmentMinus10PaybackPeriodDiscounted === 0)">
                            {{investmentMinus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(investmentMinus10IRR === 0)">-</span>
                        <span *ngIf="!(investmentMinus10IRR === 0)">
                            {{investmentMinus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Investment (+10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of investmentPlus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of investmentPlus10PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(investmentPlus10NPV === 0)">-</span>
                        <span *ngIf="!(investmentPlus10NPV === 0)">
                            {{investmentPlus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(investmentPlus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(investmentPlus10PaybackPeriod === 0)">
                            {{investmentPlus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(investmentPlus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(investmentPlus10PaybackPeriodDiscounted === 0)">
                            {{investmentPlus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(investmentPlus10IRR === 0)">-</span>
                        <span *ngIf="!(investmentPlus10IRR === 0)">
                            {{investmentPlus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Margin (-20%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginMinus20GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginMinus20GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginMinus20FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginMinus20FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of marginMinus20FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of marginMinus20GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of marginMinus20PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginMinus20PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(marginMinus20NPV === 0)">-</span>
                        <span *ngIf="!(marginMinus20NPV === 0)">
                            {{marginMinus20NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(marginMinus20PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(marginMinus20PaybackPeriod === 0)">
                            {{marginMinus20PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(marginMinus20PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(marginMinus20PaybackPeriodDiscounted === 0)">
                            {{marginMinus20PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(marginMinus20IRR === 0)">-</span>
                        <span *ngIf="!(marginMinus20IRR === 0)">
                            {{marginMinus20IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Margin (+20%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginPlus20GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginPlus20GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginPlus20FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of marginPlus20FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of marginPlus20FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of marginPlus20GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of marginPlus20PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of marginPlus20PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of marginPlus20PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(marginPlus20NPV === 0)">-</span>
                        <span *ngIf="!(marginPlus20NPV === 0)">
                            {{marginPlus20NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(marginPlus20PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(marginPlus20PaybackPeriod === 0)">
                            {{marginPlus20PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(marginPlus20PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(marginPlus20PaybackPeriodDiscounted === 0)">
                            {{marginPlus20PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(marginPlus20IRR === 0)">-</span>
                        <span *ngIf="!(marginPlus20IRR === 0)">
                            {{marginPlus20IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Operating Cost (-10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostMinus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostMinus10PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(operatingCostMinus10NPV === 0)">-</span>
                        <span *ngIf="!(operatingCostMinus10NPV === 0)">
                            {{operatingCostMinus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(operatingCostMinus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(operatingCostMinus10PaybackPeriod === 0)">
                            {{operatingCostMinus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(operatingCostMinus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(operatingCostMinus10PaybackPeriodDiscounted === 0)">
                            {{operatingCostMinus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(operatingCostMinus10IRR === 0)">-</span>
                        <span *ngIf="!(operatingCostMinus10IRR === 0)">
                            {{operatingCostMinus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Operating Cost (+10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of operatingCostPlus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of operatingCostPlus10PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(operatingCostPlus10NPV === 0)">-</span>
                        <span *ngIf="!(operatingCostPlus10NPV === 0)">
                            {{operatingCostPlus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(operatingCostPlus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(operatingCostPlus10PaybackPeriod === 0)">
                            {{operatingCostPlus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(operatingCostPlus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(operatingCostPlus10PaybackPeriodDiscounted === 0)">
                            {{operatingCostPlus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(operatingCostPlus10IRR === 0)">-</span>
                        <span *ngIf="!(operatingCostPlus10IRR === 0)">
                            {{operatingCostPlus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Volume (-15%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumeMinus15GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumeMinus15GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumeMinus15FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumeMinus15FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of volumeMinus15FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of volumeMinus15GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of volumeMinus15PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumeMinus15PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(volumeMinus15NPV === 0)">-</span>
                        <span *ngIf="!(volumeMinus15NPV === 0)">
                            {{volumeMinus15NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(volumeMinus15PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(volumeMinus15PaybackPeriod === 0)">
                            {{volumeMinus15PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(volumeMinus15PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(volumeMinus15PaybackPeriodDiscounted === 0)">
                            {{volumeMinus15PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(volumeMinus15IRR === 0)">-</span>
                        <span *ngIf="!(volumeMinus15IRR === 0)">
                            {{volumeMinus15IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Volume (+15%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumePlus15GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumePlus15GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumePlus15FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of volumePlus15FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of volumePlus15FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of volumePlus15GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of volumePlus15PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of volumePlus15PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of volumePlus15PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(volumePlus15NPV === 0)">-</span>
                        <span *ngIf="!(volumePlus15NPV === 0)">
                            {{volumePlus15NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(volumePlus15PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(volumePlus15PaybackPeriod === 0)">
                            {{volumePlus15PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(volumePlus15PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(volumePlus15PaybackPeriodDiscounted === 0)">
                            {{volumePlus15PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(volumePlus15IRR === 0)">-</span>
                        <span *ngIf="!(volumePlus15IRR === 0)">
                            {{volumePlus15IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Working Capital (-10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalMinus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalMinus10PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(workingCapitalMinus10NPV === 0)">-</span>
                        <span *ngIf="!(workingCapitalMinus10NPV === 0)">
                            {{workingCapitalMinus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(workingCapitalMinus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(workingCapitalMinus10PaybackPeriod === 0)">
                            {{workingCapitalMinus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(workingCapitalMinus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(workingCapitalMinus10PaybackPeriodDiscounted === 0)">
                            {{workingCapitalMinus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(workingCapitalMinus10IRR === 0)">-</span>
                        <span *ngIf="!(workingCapitalMinus10IRR === 0)">
                            {{workingCapitalMinus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Working Capital (+10%)
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10GasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10GasVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10FuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10FuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10FuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of workingCapitalPlus10GasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentCompanyDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentCompanyOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentCompanyOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentCompanyRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentEbitdaArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentCompanyInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentTaxAmountArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Nominal Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax - Real Terms</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of workingCapitalPlus10PercentNetCashAfterTaxRealArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="px-2 py-0">
                        Net Present Value :
                        <span *ngIf="(workingCapitalPlus10NPV === 0)">-</span>
                        <span *ngIf="!(workingCapitalPlus10NPV === 0)">
                            {{workingCapitalPlus10NPV|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period :
                        <span *ngIf="(workingCapitalPlus10PaybackPeriod === 0)">-</span>
                        <span *ngIf="!(workingCapitalPlus10PaybackPeriod === 0)">
                            {{workingCapitalPlus10PaybackPeriod|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Payback Period (Discounted):
                        <span *ngIf="(workingCapitalPlus10PaybackPeriodDiscounted === 0)">-</span>
                        <span *ngIf="!(workingCapitalPlus10PaybackPeriodDiscounted === 0)">
                            {{workingCapitalPlus10PaybackPeriodDiscounted|number:'1.2-2'}}
                        </span>
                    </span>
                    <span class="px-2 py-0">
                        Internal Rate Of Return :
                        <span *ngIf="(workingCapitalPlus10IRR === 0)">-</span>
                        <span *ngIf="!(workingCapitalPlus10IRR === 0)">
                            {{workingCapitalPlus10IRR|number:'1.2-2'}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="card">
                <div class="card-header mb-0">
                    <span>
                        Internal Rate Of Return Summary
                    </span>
                </div>
                <div class="card-body">
                    
                </div>
                <div class="card-body">
                    <div id="line-chart-datalabel">
                        <apx-chart class="apex-charts" #irrChartObj [series]="lineChartIRR.series!"
                            [chart]="lineChartIRR.chart!" [xaxis]="lineChartIRR.xaxis!"
                            [dataLabels]="lineChartIRR.dataLabels!" [grid]="lineChartIRR.grid!"
                            [stroke]="lineChartIRR.stroke!" [labels]="lineChartIRR.labels!"
                            [annotations]="lineChartIRR.annotations!"
                            [colors]="lineChartIRR.colors!"></apx-chart>
                    </div>
                </div>
            </div>


            <div class="card">
                <div class="card-header mb-0">
                    <span>
                        Sensitivity Summary
                    </span>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="py-0 px-1">
                                        </th>
                                        <th class="py-0 px-1">
                                        </th>
                                        <th class="py-0 text-center">
                                            <span>
                                                Project IRR
                                            </span>
                                        </th>
                                        <th class="py-0 text-center">
                                            <span>
                                                Project IRR
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th class="py-0 px-1">
                                        </th>
                                        <th class="py-0 px-1">
                                        </th>
                                        <th class="py-0 text-center">
                                            <span>
                                                (+)
                                            </span>
                                        </th>
                                        <th class="py-0 text-center">
                                            <span>
                                                (-)
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Volume
                                            </span>
                                        </td>
                                        <td class="py-0 px-1">
                                            <span>
                                                +15% / -15%
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(volumePlus15PercentIrr === 0)">-</span>
                                            <span *ngIf="!(volumePlus15PercentIrr === 0)">
                                                {{volumePlus15PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(volumeMinus15PercentIrr === 0)">-</span>
                                            <span *ngIf="!(volumeMinus15PercentIrr === 0)">
                                                {{volumeMinus15PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Gross Margin
                                            </span>
                                        </td>
                                        <td class="py-0 px-1">
                                            <span>
                                                +20% / -20%
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(marginPlus20PercentIrr === 0)">-</span>
                                            <span *ngIf="!(marginPlus20PercentIrr === 0)">
                                                {{marginPlus20PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(marginMinus20PercentIrr === 0)">-</span>
                                            <span *ngIf="!(marginMinus20PercentIrr === 0)">
                                                {{marginMinus20PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Investment
                                            </span>
                                        </td>
                                        <td class="py-0 px-1">
                                            <span>
                                                +10% / -10%
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(investmentPlus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(investmentPlus10PercentIrr === 0)">
                                                {{investmentPlus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(investmentMinus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(investmentMinus10PercentIrr === 0)">
                                                {{investmentMinus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Working Capital
                                            </span>
                                        </td>
                                        <td class="py-0 px-1">
                                            <span>
                                                +10% / -10%
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(workingCapitalPlus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(workingCapitalPlus10PercentIrr === 0)">
                                                {{workingCapitalPlus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(workingCapitalMinus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(workingCapitalMinus10PercentIrr === 0)">
                                                {{workingCapitalMinus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-0 px-1">
                                            <span>
                                                Operating Cost
                                            </span>
                                        </td>
                                        <td class="py-0 px-1">
                                            <span>
                                                +10% / -10%
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(operatingCostPlus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(operatingCostPlus10PercentIrr === 0)">
                                                {{operatingCostPlus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="py-0 text-center">
                                            <span *ngIf="(operatingCostMinus10PercentIrr === 0)">-</span>
                                            <span *ngIf="!(operatingCostMinus10PercentIrr === 0)">
                                                {{operatingCostMinus10PercentIrr | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div id="line-chart-datalabel">
                        <apx-chart class="apex-charts" #chartObj [series]="lineChartSensitivity.series!"
                            [chart]="lineChartSensitivity.chart!" [xaxis]="lineChartSensitivity.xaxis!"
                            [dataLabels]="lineChartSensitivity.dataLabels!" [grid]="lineChartSensitivity.grid!"
                            [stroke]="lineChartSensitivity.stroke!" [labels]="lineChartSensitivity.labels!"
                            [annotations]="lineChartSensitivity.annotations!"
                            [colors]="lineChartSensitivity.colors!"></apx-chart>
                    </div>
                </div>
            </div>


        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="card bg-success text-white" role="button" (click)="navigateTo('resultPDF')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-download-outline"></i> Print Result Summary (PDF)</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card text-white bg-info overflow-hidden" role="button" (click)="navigateTo('projectAppraisal')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-document-edit-outline"></i> View/Edit Project Appraisal</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card bg-danger text-white" role="button" (click)="navigateTo('report')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-document-edit-outline"></i> View/Edit Report</h4>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>