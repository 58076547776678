<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Post Investment Analysis</h1>
                    <p class="lead text-muted">Fill in the post investment detail.</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Summary -->
    <div class="card border border-primary">
        <div class="card-group">
            <div class="card mb-0">
                <div class="card-header text-center">
                    <span>Project Info</span>
                </div>
                <div class="card-body">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        Project Name
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].name">{{projectArray[0].name}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        Country
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].country">
                                            <span *ngIf="projectArray[0].country.name">
                                                {{projectArray[0].country.name}}
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        1st Year of Investment
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].startDate">{{projectArray[0].startDate}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section Break -->
    <div class="border border-secondary card">
        <div class="card-body text-center text-primary">
            <span>Section A - Year Of Investment.</span>
        </div>
    </div>

    <!-- Year Of Investment -->
    <div class="border border-secondary card stepOne" id="stepOne">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 1. Year Of Investment.
                            </td>
                            <td class="p-0" style="width:30%">
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <form name="year-picker-edit-form" [formGroup]="yearPickerForm">
                <div class="row mb-3">
                    <label for="year" class="col-sm-4 col-form-label">Pick a Year :</label>
                    <div class="col-sm-8">
                        <select class="form-select form-select-sm" name="year" formControlName="year" id="year" required
                            [ngClass]="{'is-invalid': (formSubmitted && yearPickerFormValues['year'].invalid)||(yearPickerFormValues['year'].touched && yearPickerFormValues['year'].invalid)}">
                            <option *ngFor="let m of yearArray" [value]="m.year">
                                {{m.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Section Break -->
    <div class="border border-secondary card" *ngIf="pickedYear">
        <div class="card-body text-center text-primary">
            <span>Section B - Projection vs Actual.</span>
        </div>
    </div>

    <!-- Economic Environment -->
    <div class="border border-secondary card stepTwo" id="stepTwo" *ngIf="pickedYear">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 2. Economic Environment.
                            </td>
                            <td class="p-0" style="width:30%">
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" [formGroup]="economicEnvironmentActualForm">

            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2"></span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Projection<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Actual<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Inflation :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearInflationRate === 0)">-</span>
                            <span *ngIf="!(pickedYearInflationRate === 0)">
                                {{pickedYearInflationRate*100|number: "1.2-2"}} %
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showEconomicEnvironmentEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="100" id="inflation"
                                formControlName="inflation"
                                [ngClass]="{'is-invalid': (formSubmitted && economicEnvironmentActualFormValues['inflation'].invalid)||(economicEnvironmentActualFormValues['inflation'].touched && economicEnvironmentActualFormValues['inflation'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showEconomicEnvironmentEdit">
                            <span *ngIf="(pickedYearInflationRateActual === 0)">-</span>
                            <span *ngIf="!(pickedYearInflationRateActual === 0)">
                                {{pickedYearInflationRateActual*100|number: "1.2-2"}} %
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Interest Rate :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearInterestRate === 0)">-</span>
                            <span *ngIf="!(pickedYearInterestRate === 0)">
                                {{pickedYearInterestRate|number: "1.2-2"}} %
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showEconomicEnvironmentEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="100"
                                id="interestRate" formControlName="interestRate"
                                [ngClass]="{'is-invalid': (formSubmitted && economicEnvironmentActualFormValues['interestRate'].invalid)||(economicEnvironmentActualFormValues['interestRate'].touched && economicEnvironmentActualFormValues['interestRate'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showEconomicEnvironmentEdit">
                            <span *ngIf="(pickedYearInterestRateActual === 0)">-</span>
                            <span *ngIf="!(pickedYearInterestRateActual === 0)">
                                {{pickedYearInterestRateActual|number: "1.2-2"}} %
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Exchange Rate (<span
                                    *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/USD):</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearExchangeRate === 0)">-</span>
                            <span *ngIf="!(pickedYearExchangeRate === 0)">
                                {{pickedYearExchangeRate|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showEconomicEnvironmentEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="1000"
                                id="exchangeRate" formControlName="exchangeRate"
                                [ngClass]="{'is-invalid': (formSubmitted && economicEnvironmentActualFormValues['exchangeRate'].invalid)||(economicEnvironmentActualFormValues['exchangeRate'].touched && economicEnvironmentActualFormValues['exchangeRate'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showEconomicEnvironmentEdit">
                            <span *ngIf="(pickedYearExchangeRateActual === 0)">-</span>
                            <span *ngIf="!(pickedYearExchangeRateActual === 0)">
                                {{pickedYearExchangeRateActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Corporate Tax Rate :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearCorporateTaxRate === 0)">-</span>
                            <span *ngIf="!(pickedYearCorporateTaxRate === 0)">
                                {{pickedYearCorporateTaxRate|number: "1.2-2"}} %
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showEconomicEnvironmentEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="100"
                                id="corporateTaxRate" formControlName="corporateTaxRate"
                                [ngClass]="{'is-invalid': (formSubmitted && economicEnvironmentActualFormValues['corporateTaxRate'].invalid)||(economicEnvironmentActualFormValues['corporateTaxRate'].touched && economicEnvironmentActualFormValues['corporateTaxRate'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showEconomicEnvironmentEdit">
                            <span *ngIf="(pickedYearCorporateTaxRateActual === 0)">-</span>
                            <span *ngIf="!(pickedYearCorporateTaxRateActual === 0)">
                                {{pickedYearCorporateTaxRateActual|number: "1.2-2"}} %
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="showEconomicEnvironmentEdit">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="saveEconomicEnvironmentActual(economicEnvironmentActualForm.value)"
                                    type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                        class=""></i>Save</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm me-1" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <span>Remarks<br></span>
                </div>
                <div class="card-body">
                    <ul class="conversation-list px-3">
                        <li class="clearfix odd" *ngFor="let r of economicEnvironmentRemarkArray">
                            <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                    [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="conversation-text">
                                <div class="ctext-wrap">
                                    <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                    <i><span class="text-muted">{{r.from.role}}</span></i>
                                    <p *ngIf="r.remark">
                                        {{r.remark}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <p class="mt-0 mb-0 pb-0 ms-2">
                                <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                    [alt]="loggedInUserFirstName + loggedInUserSurname" />
                            </p>
                            <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                            </h5>
                            <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                            <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                        </div>
                        <div class="col-9 px-0" name="gas-product-form" [formGroup]="economicEnvironmentRemarkForm">
                            <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                id="example-textarea" rows="6" formControlName="remark"></textarea>
                            <div class="text-end">
                                <div class="btn-group mb-2 ms-2">
                                    <button type="button" class="btn btn-primary btn-sm"
                                        (click)="submitRemark(economicEnvironmentRemarkForm.value,'economicEnvironmentRemark')">Submit
                                        Remark</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row" *ngIf="showEconomicEnvironmentEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showEconomicEnvironmentEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showEconomicEnvironmentEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showEconomicEnvironmentEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Product Volume -->
    <div class="border border-secondary card stepThree" id="stepThree" *ngIf="pickedYear">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 3. Product Sales Volume.
                            </td>
                            <td class="p-0" style="width:30%">
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" [formGroup]="productSalesActualForm">

            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2"></span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Projection<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Actual<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Fuel + Lubricant Volume</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearFuelAndLubricantVolume === 0)">-</span>
                            <span *ngIf="!(pickedYearFuelAndLubricantVolume === 0)">
                                {{pickedYearFuelAndLubricantVolume|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProductSalesVolumeEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="1000000"
                                id="fuelAndLubricantVolume" formControlName="fuelAndLubricantVolume"
                                [ngClass]="{'is-invalid': (formSubmitted && productSalesActualFormValues['fuelAndLubricantVolume'].invalid)||(productSalesActualFormValues['fuelAndLubricantVolume'].touched && productSalesActualFormValues['fuelAndLubricantVolume'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProductSalesVolumeEdit">
                            <span *ngIf="(pickedYearFuelAndLubricantVolumeActual === 0)">-</span>
                            <span *ngIf="!(pickedYearFuelAndLubricantVolumeActual === 0)">
                                {{pickedYearFuelAndLubricantVolumeActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Gas Volume</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearGasVolume === 0)">-</span>
                            <span *ngIf="!(pickedYearGasVolume === 0)">
                                {{pickedYearGasVolume|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProductSalesVolumeEdit">
                            <input class="form-control form-control-sm" type="number" min="-1" max="1000000"
                                id="gasVolume" formControlName="gasVolume"
                                [ngClass]="{'is-invalid': (formSubmitted && productSalesActualFormValues['gasVolume'].invalid)||(productSalesActualFormValues['gasVolume'].touched && productSalesActualFormValues['gasVolume'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProductSalesVolumeEdit">
                            <span *ngIf="(pickedYearGasVolumeActual === 0)">-</span>
                            <span *ngIf="!(pickedYearGasVolumeActual === 0)">
                                {{pickedYearGasVolumeActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="showProductSalesVolumeEdit">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="saveProductSalesVolumeActual(productSalesActualForm.value)" type="button"
                                    *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Save</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm me-1" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <span>Remarks<br></span>
                </div>
                <div class="card-body">
                    <ul class="conversation-list px-3">
                        <li class="clearfix odd" *ngFor="let r of productSalesRemarkArray">
                            <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                    [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="conversation-text">
                                <div class="ctext-wrap">
                                    <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                    <i><span class="text-muted">{{r.from.role}}</span></i>
                                    <p *ngIf="r.remark">
                                        {{r.remark}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <p class="mt-0 mb-0 pb-0 ms-2">
                                <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                    [alt]="loggedInUserFirstName + loggedInUserSurname" />
                            </p>
                            <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                            </h5>
                            <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                            <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                        </div>
                        <div class="col-9 px-0" name="gas-product-form" [formGroup]="productSalesRemarkForm">
                            <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                id="example-textarea" rows="6" formControlName="remark"></textarea>
                            <div class="text-end">
                                <div class="btn-group mb-2 ms-2">
                                    <button type="button" class="btn btn-primary btn-sm"
                                        (click)="submitRemark(productSalesRemarkForm.value,'productSalesRemark')">Submit
                                        Remark</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <div class="row" *ngIf="showProductSalesVolumeEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProductSalesVolumeEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProductSalesVolumeEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProductSalesVolumeEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Profit & Loss -->
    <div class="border border-secondary card stepFour" id="stepFour" *ngIf="pickedYear">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 4. Profit & Loss.
                            </td>
                            <td class="p-0" style="width:30%">
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" [formGroup]="profitAndLossActualForm">

            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2"></span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Projection<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                        <div class="col-4 px-0 text-center">
                            Actual<span *ngIf="yearPicked"> - {{yearPicked}}</span>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Net Fuel Margin :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearNetFuelMargin === 0)">-</span>
                            <span *ngIf="!(pickedYearNetFuelMargin === 0)">
                                {{pickedYearNetFuelMargin|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="netFuelMargin" formControlName="netFuelMargin"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['netFuelMargin'].invalid)||(profitAndLossActualFormValues['netFuelMargin'].touched && profitAndLossActualFormValues['netFuelMargin'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearNetFuelMarginActual === 0)">-</span>
                            <span *ngIf="!(pickedYearNetFuelMarginActual === 0)">
                                {{pickedYearNetFuelMarginActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Diversification :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearDiversification === 0)">-</span>
                            <span *ngIf="!(pickedYearDiversification === 0)">
                                {{pickedYearDiversification|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="diversification" formControlName="diversification"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['diversification'].invalid)||(profitAndLossActualFormValues['diversification'].touched && profitAndLossActualFormValues['diversification'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearDiversificationActual === 0)">-</span>
                            <span *ngIf="!(pickedYearDiversificationActual === 0)">
                                {{pickedYearDiversificationActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Other Revenues :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearOtherRevenue === 0)">-</span>
                            <span *ngIf="!(pickedYearOtherRevenue === 0)">
                                {{pickedYearOtherRevenue|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="otherRevenue" formControlName="otherRevenue"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['otherRevenue'].invalid)||(profitAndLossActualFormValues['otherRevenue'].touched && profitAndLossActualFormValues['otherRevenue'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearOtherRevenueActual === 0)">-</span>
                            <span *ngIf="!(pickedYearOtherRevenueActual === 0)">
                                {{pickedYearOtherRevenueActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Operating Expenses :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearOperatingExpenses === 0)">-</span>
                            <span *ngIf="!(pickedYearOperatingExpenses === 0)">
                                {{pickedYearOperatingExpenses|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="operatingExpenses" formControlName="operatingExpenses"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['operatingExpenses'].invalid)||(profitAndLossActualFormValues['operatingExpenses'].touched && profitAndLossActualFormValues['operatingExpenses'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearOperatingExpensesActual === 0)">-</span>
                            <span *ngIf="!(pickedYearOperatingExpensesActual === 0)">
                                {{pickedYearOperatingExpensesActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Rentals & Fees Due :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearRentalsAndFeesDue === 0)">-</span>
                            <span *ngIf="!(pickedYearRentalsAndFeesDue === 0)">
                                {{pickedYearRentalsAndFeesDue|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="rentalsAndFeesDue" formControlName="rentalsAndFeesDue"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['rentalsAndFeesDue'].invalid)||(profitAndLossActualFormValues['rentalsAndFeesDue'].touched && profitAndLossActualFormValues['rentalsAndFeesDue'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearRentalsAndFeesDueActual === 0)">-</span>
                            <span *ngIf="!(pickedYearRentalsAndFeesDueActual === 0)">
                                {{pickedYearRentalsAndFeesDueActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-0">EBITDA :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearEBITDA === 0)">-</span>
                            <span *ngIf="!(pickedYearEBITDA === 0)">
                                {{pickedYearEBITDA|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number"  id="ebitda"
                                formControlName="ebitda"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['ebitda'].invalid)||(profitAndLossActualFormValues['ebitda'].touched && profitAndLossActualFormValues['ebitda'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearEBITDAActual === 0)">-</span>
                            <span *ngIf="!(pickedYearEBITDAActual === 0)">
                                {{pickedYearEBITDAActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Investments :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearInvestments === 0)">-</span>
                            <span *ngIf="!(pickedYearInvestments === 0)">
                                {{pickedYearInvestments|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="investments" formControlName="investments"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['investments'].invalid)||(profitAndLossActualFormValues['investments'].touched && profitAndLossActualFormValues['investments'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearInvestmentsActual === 0)">-</span>
                            <span *ngIf="!(pickedYearInvestmentsActual === 0)">
                                {{pickedYearInvestmentsActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Change in Working Capital :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearChangeInWorkingCapital === 0)">-</span>
                            <span *ngIf="!(pickedYearChangeInWorkingCapital === 0)">
                                {{pickedYearChangeInWorkingCapital|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="changeInWorkingCapital" formControlName="changeInWorkingCapital"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['changeInWorkingCapital'].invalid)||(profitAndLossActualFormValues['changeInWorkingCapital'].touched && profitAndLossActualFormValues['changeInWorkingCapital'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearChangeInWorkingCapitalActual === 0)">-</span>
                            <span *ngIf="!(pickedYearChangeInWorkingCapitalActual === 0)">
                                {{pickedYearChangeInWorkingCapitalActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Taxes :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearTaxes === 0)">-</span>
                            <span *ngIf="!(pickedYearTaxes === 0)">
                                {{pickedYearTaxes|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number"  id="taxes"
                                formControlName="taxes"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['taxes'].invalid)||(profitAndLossActualFormValues['taxes'].touched && profitAndLossActualFormValues['taxes'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearTaxesActual === 0)">-</span>
                            <span *ngIf="!(pickedYearTaxesActual === 0)">
                                {{pickedYearTaxesActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Other Cash Items :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearOtherCashItems === 0)">-</span>
                            <span *ngIf="!(pickedYearOtherCashItems === 0)">
                                {{pickedYearOtherCashItems|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="otherCashItems" formControlName="otherCashItems"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['otherCashItems'].invalid)||(profitAndLossActualFormValues['otherCashItems'].touched && profitAndLossActualFormValues['otherCashItems'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearOtherCashItemsActual === 0)">-</span>
                            <span *ngIf="!(pickedYearOtherCashItemsActual === 0)">
                                {{pickedYearOtherCashItemsActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-0">Net Cash After Tax :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearNetCashAfterTax === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTax === 0)">
                                {{pickedYearNetCashAfterTax|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="netCashAfterTax" formControlName="netCashAfterTax"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['netCashAfterTax'].invalid)||(profitAndLossActualFormValues['netCashAfterTax'].touched && profitAndLossActualFormValues['netCashAfterTax'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearNetCashAfterTaxActual === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTaxActual === 0)">
                                {{pickedYearNetCashAfterTaxActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Financial Result :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearFinancialResult === 0)">-</span>
                            <span *ngIf="!(pickedYearFinancialResult === 0)">
                                {{pickedYearFinancialResult|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="financialResult" formControlName="financialResult"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['financialResult'].invalid)||(profitAndLossActualFormValues['financialResult'].touched && profitAndLossActualFormValues['financialResult'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearFinancialResultActual === 0)">-</span>
                            <span *ngIf="!(pickedYearFinancialResultActual === 0)">
                                {{pickedYearFinancialResultActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-0">Net Cash After Tax (Discounted) :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearNetCashAfterTaxDiscounted === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTaxDiscounted === 0)">
                                {{pickedYearNetCashAfterTaxDiscounted|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="netCashAfterTaxDiscounted" formControlName="netCashAfterTaxDiscounted"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['netCashAfterTaxDiscounted'].invalid)||(profitAndLossActualFormValues['netCashAfterTaxDiscounted'].touched && profitAndLossActualFormValues['netCashAfterTaxDiscounted'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearNetCashAfterTaxDiscountedActual === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTaxDiscountedActual === 0)">
                                {{pickedYearNetCashAfterTaxDiscountedActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-0">Net Cash After Tax (Real & Discounted) :</span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center">
                            <span *ngIf="(pickedYearNetCashAfterTaxRealAndDiscounted === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTaxRealAndDiscounted === 0)">
                                {{pickedYearNetCashAfterTaxRealAndDiscounted|number: "1.2-2"}}
                            </span>
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="showProfitAndLossEdit">
                            <input class="form-control form-control-sm" type="number" 
                                id="netCashAfterTaxRealAndDiscounted" formControlName="netCashAfterTaxRealAndDiscounted"
                                [ngClass]="{'is-invalid': (formSubmitted && profitAndLossActualFormValues['netCashAfterTaxRealAndDiscounted'].invalid)||(profitAndLossActualFormValues['netCashAfterTaxRealAndDiscounted'].touched && profitAndLossActualFormValues['netCashAfterTaxRealAndDiscounted'].invalid)}">
                        </div>
                        <div class="col-4 border border-light px-0 text-center" *ngIf="!showProfitAndLossEdit">
                            <span *ngIf="(pickedYearNetCashAfterTaxRealAndDiscountedActual === 0)">-</span>
                            <span *ngIf="!(pickedYearNetCashAfterTaxRealAndDiscountedActual === 0)">
                                {{pickedYearNetCashAfterTaxRealAndDiscountedActual|number: "1.2-2"}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="showProfitAndLossEdit">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="saveProfitAndLossActual(profitAndLossActualForm.value)" type="button"
                                    *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Save</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm me-1" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <span>Remarks<br></span>
                </div>
                <div class="card-body">
                    <ul class="conversation-list px-3">
                        <li class="clearfix odd" *ngFor="let r of profitAndLossRemarkArray">
                            <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                    [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="conversation-text">
                                <div class="ctext-wrap">
                                    <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                    <i><span class="text-muted">{{r.from.role}}</span></i>
                                    <p *ngIf="r.remark">
                                        {{r.remark}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <p class="mt-0 mb-0 pb-0 ms-2">
                                <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                    [alt]="loggedInUserFirstName + loggedInUserSurname" />
                            </p>
                            <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                            </h5>
                            <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                            <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                        </div>
                        <div class="col-9 px-0" name="gas-product-form" [formGroup]="profitAndLossRemarkForm">
                            <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                id="example-textarea" rows="6" formControlName="remark"></textarea>
                            <div class="text-end">
                                <div class="btn-group mb-2 ms-2">
                                    <button type="button" class="btn btn-primary btn-sm"
                                        (click)="submitRemark(profitAndLossRemarkForm.value,'profitAndLossRemark')">Submit
                                        Remark</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <div class="row" *ngIf="showProfitAndLossEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProfitAndLossEditButton(false)"
                            type="button"><i class=""></i>Simple View</button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProfitAndLossEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProfitAndLossEditButton(true)"
                            type="button"><i class=""></i>Edit View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Post Investment Report -->
    <div class="border border-secondary card stepFive" id="stepFive" *ngIf="pickedYear">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 5. Post Investment Report.
                            </td>
                            <td class="p-0" style="width:30%">
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="!showPostInvestmentReportEdit">

            <div class="card">
                <div class="card-header">
                    <span>Final Remarks.</span>
                </div>
                <div class="card-body">
                    <div class="row" *ngIf="finalRemark">
                        <span>
                            <p [innerHTML]="finalRemark"></p><br>
                        </span>
                    </div>
                    <div class="row text-center" *ngIf="!finalRemark">
                        <div class="row flex-grow-1">
                            <div class="col">
                                <div class="pricing-header px-3 py-3 pt-md-4 pb-md-4 mx-auto text-center">
                                    <h1 class="display-4">Empty</h1>
                                    <p class="lead">No Remark!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <span>Recommendations.</span>
                </div>
                <div class="card-body">
                    <div class="row" *ngIf="postInvestmentRecommendations">
                        <span>
                            <p [innerHTML]="postInvestmentRecommendations"></p><br>
                        </span>
                    </div>
                    <div class="row text-center" *ngIf="!postInvestmentRecommendations">
                        <div class="row flex-grow-1">
                            <div class="col">
                                <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                    <h1 class="display-4">Empty</h1>
                                    <p class="lead">No Recommendation!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card-body" [formGroup]="postInvestmentReportForm" *ngIf="showPostInvestmentReportEdit">

            <div class="card">
                <div class="card-header">
                    <span>Final Remarks.<span class="text-danger"> *</span></span>
                </div>
                <div class="card-body">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <quill-editor formControlName="finalRemark" #quillEditor [sanitize]="true" [modules]="quillConfig"
                            [styles]="{height:'500px'}" maxlength="5000"
                            placeholder="Final post investment analysis remarks in detail..."
                            [ngClass]="{'is-valid': postInvestmentReportForm.controls['finalRemark'].value}"
                            [ngClass]="{'is-invalid': (postInvestmentReportForm.controls['finalRemark'].touched && postInvestmentReportForm.controls['finalRemark'].invalid)}">
                        </quill-editor>
                        <div *ngIf="(formSubmitted && postInvestmentReportFormValues['finalRemark'].invalid)||(postInvestmentReportFormValues['finalRemark'].touched && postInvestmentReportFormValues['finalRemark'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="postInvestmentReportFormValues['finalRemark'].errors?.['required']">
                                Final remarks is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="showPostInvestmentReportEdit">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="savePostInvestmentReport(postInvestmentReportForm.value,'finalRemark')" type="button"
                                    *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Save</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <span>Recommendations.<span class="text-danger"> *</span></span>
                </div>
                <div class="card-body">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <quill-editor formControlName="recommendation" #quillEditor [sanitize]="true"
                            [modules]="quillConfig" [styles]="{height:'500px'}" maxlength="5000"
                            placeholder="Post investment analysis recommendations in detail..."
                            [ngClass]="{'is-valid': postInvestmentReportForm.controls['recommendation'].value}"
                            [ngClass]="{'is-invalid': (postInvestmentReportForm.controls['recommendation'].touched && postInvestmentReportForm.controls['recommendation'].invalid)}">
                        </quill-editor>
                        <div *ngIf="(formSubmitted && postInvestmentReportFormValues['recommendation'].invalid)||(postInvestmentReportFormValues['recommendation'].touched && postInvestmentReportFormValues['recommendation'].invalid)"
                            class="invalid-tooltip">
                            <div *ngIf="postInvestmentReportFormValues['recommendation'].errors?.['required']">
                                Recommendation is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer" *ngIf="showPostInvestmentReportEdit">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="savePostInvestmentReport(postInvestmentReportForm.value,'recommendation')" type="button"
                                    *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Save</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="card-footer">
            <div class="row" *ngIf="showPostInvestmentReportEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showPostInvestmentReportEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showPostInvestmentReportEdit">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showPostInvestmentReportEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="pickedYear">
        <div class="col-lg-12">
            <div class="card bg-success text-white" role="button" (click)="navigateTo('postInvestmentPDF')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-download-outline"></i> Print Post Investment Analysis (PDF)</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>