import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, formatCurrency, ViewportScroller } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IpGeoService } from 'src/app/core/service/ip-geo.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { Subscription, first } from 'rxjs';
import { ProjectService } from 'src/app/core/service/project.service';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { Country } from 'src/app/core/models-two/Country';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { FuelLubricantGasProduct } from 'src/app/core/models-two/FuelLubricantGasProduct';
import { FuelProduct } from 'src/app/core/models-two/FuelProduct';
import { GasProduct } from 'src/app/core/models-two/GasProduct';
import { LubricantProduct } from 'src/app/core/models-two/LubricantProduct';
import { InvestmentItem } from 'src/app/core/models-two/InvestmentItem';
import { User } from 'firebase/auth';
import { DealerRevenue } from 'src/app/core/models-two/DealerRevenue';
import { DealerRevenueItem } from 'src/app/core/models-two/DealerRevenueItem';
import { DealerFees } from 'src/app/core/models-two/DealerFees';
import { DealerSummary } from 'src/app/core/models-two/DealerSummary';
import { DealerYearlyDiversificationFixedFeesItem } from 'src/app/core/models-two/DealerYearlyDiversificationFixedFeesItem';
import { DealerDiversificationVariableFeesItem } from 'src/app/core/models-two/DealerDiversificationVariableFeesItem';
import { CompanyOperatingExpenses } from 'src/app/core/models-two/CompanyOperatingExpenses';
import { CompanyTaxes } from 'src/app/core/models-two/CompanyTaxes';
import { CompanyFinancing } from 'src/app/core/models-two/CompanyFinancing';
import { CompanySummary } from 'src/app/core/models-two/CompanySummary';
import { WorkingCapital } from 'src/app/core/models-two/WorkingCapital';
import { CompanyFeesReceived } from 'src/app/core/models-two/CompanyFeesReceived';
import { CompanyInvestment } from 'src/app/core/models-two/CompanyInvestment';
import { DealerOperatingExpenses } from 'src/app/core/models-two/DealerOperatingExpenses';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';

import jsPDF from 'jspdf';
import { AuthenticationService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-project',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  subscription: Subscription = new Subscription();
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  tree: any;
  g: any;
  s: any;
  orderDetails: any = {
    "order_status": 'Processing',
  }

  yearlyEscalationDefault: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  projectObject: ProjectDetails;
  projectArray: ProjectDetails[];

  projectForm!: FormGroup;
  productForm!: FormGroup;
  projectSettingsEditForm!: FormGroup;
  companyOperatingExpensesForm!: FormGroup;
  fuelProductForm!: FormGroup;
  lubricantProductForm!: FormGroup;
  gasProductForm!: FormGroup;
  workingCapitalForm!: FormGroup;
  taxesForm!: FormGroup;
  financingForm!: FormGroup;
  loanToDealerForm!: FormGroup;

  fuelVolumeGrowthForm!: FormGroup;
  financingGrowthForm!: FormGroup;
  fuelVolumeGrowthPotentialForm!: FormGroup;
  monthsDuringThePeriodForm!: FormGroup;
  fuelMarginGrowthForm!: FormGroup;
  fuelVolumeForm!: FormGroup;
  fuelMarginForm!: FormGroup;
  gasMarginForm!: FormGroup;
  fuelMarginTotalForm!: FormGroup;

  financingInterestRateForFinancingForm!: FormGroup;
  financingInterestReceivedForm!: FormGroup;
  financingInterestPaidForm!: FormGroup;
  financingTotalForm!: FormGroup;

  formSubmitted: boolean = false;
  countryArray: Country[];
  selectedCountry: any | Country[];
  error: string;
  loading: boolean;
  projectId: any;
  unitsOfMeasureArray: string[];
  typeOfInvestmentOptionArray: string[];
  modeOfPartnershipOptionArray: string[];
  petroleumProductArray: FuelLubricantGasProduct[];
  fuelProductArray: FuelProduct[];
  pickedPetroleumProductCategory: any;
  lubricantProductArray: LubricantProduct[];
  gasProductArray: GasProduct[];
  localCurrencyISOCode: string;
  productExists_Update: boolean = false;
  showPetroleumProductForm: boolean = false;
  fuelAndLubricantProductTotal: FuelLubricantGasProduct;
  fuelProductTotal: FuelLubricantGasProduct;
  gasProductTotal: FuelLubricantGasProduct;
  lubricantProductTotal: FuelLubricantGasProduct;
  periodOfOperationOptionArray: number[];
  monthsDuringThePeriodArray: any;
  fuelMarginGrowthArray: any;
  fuelMarginArray: any;
  fuelVolumeGrowthArray: any;
  fuelMarginTotalArray: any;
  monthsBeforeOperationArray: number[] = this.yearlyEscalationDefault;
  gasMarginByVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthFactorTwoArray: number[] = this.yearlyEscalationDefault;
  fuelMarginGrowthFactorArray: number[] = this.yearlyEscalationDefault;
  fuelAndLubricantMarginByVolume: number[] = this.yearlyEscalationDefault;
  fuelVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeWithoutLubricantArray: number[] = this.yearlyEscalationDefault;
  gasMarginArray: number[] = this.yearlyEscalationDefault;
  gasVolumeArray: number[] = this.yearlyEscalationDefault;
  fuelVolumeGrowthPotentialArray: number[] = this.yearlyEscalationDefault;
  investmentItemArray: InvestmentItem[];
  totalInvestmentCost: number = 0;

  // Show & Hide Edit Pages
  showProjectSettingsEdit: boolean = false;
  showProjectProductMarginEdit: boolean = false;
  showProjectFuelVolumeGrowthEdit: boolean = false;
  showProjectDealerOperatingExpenseEdit: boolean = false;
  showProjectInvestmentEdit: boolean = false;
  showProjectWorkingCapitalEdit: boolean = false;
  showProjectOtherRevenueEdit: boolean = false;
  showProjectCompanyOperatingExpensesEdit: boolean = false;
  showProjectDealerRevenueEdit: boolean = false;
  showProjectFinancingEdit: boolean = false;
  showProjectDealerFeesEdit: boolean = false;

  // Operating Expenses
  salariesAndBonusItemForm!: FormGroup;
  employeeContributionItemForm!: FormGroup;
  electricityItemForm!: FormGroup;
  miscellaneousItemForm!: FormGroup;
  companyFeesReceivedForm!: FormGroup;
  dealerFeesReceivedForm!: FormGroup;
  dealerRevenueNonFuelForm!: FormGroup;
  diversificationVariableFeesForm!: FormGroup;
  diversificationFixedFeesForm!: FormGroup;


  loggedInUser: User;
  userId: any;


  // Investment
  brandingItemForm!: FormGroup;
  equipmentItemForm!: FormGroup;
  constructionItemForm!: FormGroup;
  investmentAmortizationForm!: FormGroup;
  investmentItemForm!: FormGroup;

  dealerOperatingExpenseSalariesAndBonusTotal: number = 0;
  dealerOperatingExpenseEmployeeContributionTotal: number = 0;
  dealerOperatingExpenseElectricityTotal: number = 0;
  dealerOperatingExpenseMiscellaneousTotal: number = 0;
  dealerOperatingExpenseProductLossTotal: number = 0;
  dealerOperatingExpenseTotal: number = 0;

  salariesAndBonusItemArray: any;
  employeeContributionItemArray: any;
  electricityItemArray: any;
  miscellaneousItemArray: any;
  productLossItemArray: any;
  brandingItemArray: any;
  constructionItemArray: any;
  equipmentItemArray: any;
  companyInvestmentBrandingTotal: any;
  companyInvestmentConstructionTotal: any;
  companyInvestmentEquipmentTotal: any;
  companyInvestmentTotal: any;
  progressPercentage: number;

  projectSettingsSubmitted: boolean;
  productMarginSubmitted: boolean;
  projectFuelVolumeGrowthSubmitted: boolean;
  projectInvestmentSubmitted: boolean;
  projectDealerOperatingExpenseSubmitted: boolean;
  projectWorkingCapitalSubmitted: boolean;
  projectTaxesSubmitted: boolean;
  projectOtherRevenueSubmitted: boolean;
  projectCompanyOperatingExpensesSubmitted: boolean;
  projectCompanySummarySubmitted: boolean;
  projectFinancingSubmitted: boolean;
  projectDealerRevenueSubmitted: boolean;
  projectDealerFeesSubmitted: any;
  projectDealerSummarySubmitted: any;
  projectMarginSubmitted: boolean;

  projectSettingsProgressPercentage: number;
  projectMarginProgressPercentage: number;
  projectFuelVolumeGrowthProgressPercentage: number;
  projectOperatingExpenseProgressPercentage: number;
  projectWorkingCapitalProgressPercentage: number;
  projectTaxesProgressPercentage: number;
  projectCompanySummaryProgressPercentage: number;
  projectCompanyOperatingExpensesProgressPercentage: number;
  projectOtherRevenueProgressPercentage: number;
  projectFinancingProgressPercentage: number;
  projectInvestmentProgressPercentage: number;
  projectDealerRevenueProgressPercentage: number;
  projectDealerFeesProgressPercentage: number;
  projectDealerSummaryProgressPercentage: number;

  showProjectSettingsSection: boolean = true;
  showProjectProductMarginSection: boolean = false;
  showProjectFuelVolumeGrowthSection: boolean = false;
  showProjectDealerOperatingExpenseSection: boolean = false;
  showProjectInvestmentSection: boolean = false;
  showProjectWorkingCapitalSection: boolean = false;
  showProjectTaxesSection: boolean = false;
  showProjectOtherRevenueSection: boolean = false;
  showProjectCompanyOperatingExpensesSection: boolean = false;
  showProjectFinancingSection: boolean = false;
  showProjectCompanySummarySection: boolean = false;
  showProjectDealerRevenueSection: boolean = false;
  showProjectDealerFeesSection: boolean = false;
  showProjectDealerSummarySection: boolean = false;

  submitInProgress: boolean = false;
  updateInProgress: boolean = false;

  whiteProductSupplierCreditTotal: number = 0;
  whiteProductStockLogisticsTotal: number = 0;
  whiteProductWorkingCapital: number = 0;
  lubricantSupplierCreditTotal: number = 0;
  lubricantStockLogisticsTotal: number = 0;
  lubricantWorkingCapital: number = 0;
  whiteProductLubricantWorkingCapital: number = 0;
  fuelYearlyVolume: number = 0;
  fuelAndLubricantYearlyVolume: number = 0;
  whiteProductLubricantAverageWorkingCapital: number = 0;
  firstYearWorkingCapital: number = 0;
  fuelUnitSupplyPrice: number = 0;
  lubricantUnitSupplyPrice: number = 0;
  whiteProductSupplierCreditDays: number = 0;
  whiteProductStockLogisticsDays: number = 0;
  lubricantSupplierCreditDays: number = 0;
  lubricantStockLogisticsDays: number = 0;

  averageWorkingCapitalArray: any[] = [];
  inflationRateArray: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  inflationFactorArray: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  inflationFactorTwoArray: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  averageWorkingCapitalByInflationFactorArray: any[] = [];
  fuelsAndLubricantVolumeArray: any[] = [];
  workingCapitalMainArray: any[] = [];
  taxesMainArray: any[] = [];
  financingMainArray: any[] = [];
  changeInWorkingCapitalArray: any[] = [];
  changeInWorkingCapitalRealArray: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  workingCapitalMainRealArray: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  lubricantYearlyVolume: number;

  dealerRevenueLubricantProductArray: DealerRevenueItem[];
  dealerRevenueFuelProductArray: DealerRevenueItem[];
  dealerRevenueGasProductArray: DealerRevenueItem[];
  dealerRevenueTotal: number = 0;
  dealerRevenueNonFuelProductArray: DealerRevenueItem[];
  dealerRevenueLubricantProductTotalRevenue: number = 0;
  dealerRevenueGasProductTotalRevenue: number = 0;
  dealerRevenueFuelProductTotalRevenue: number = 0;
  dealerRevenueNonFuelTotal: number = 0;
  dealerRevenueFuelLubricantGasTotal: number = 0;

  dealerFeesFixedFeesTotal: number = 0;
  dealerFeesFuelFixedFeesDodoDiscountGiven: number = 0;
  dealerFeesFuelFixedFeesTotal: number = 0;
  dealerFeesFuelFixedFeesRate: number = 0;
  dealerFeesFuelCostM3: number = 0;
  dealerFeesFuelFixedFeesProductVolume: number = 0;
  dealerFeesFuelFixedFeesDodoDiscountGivenM3: number = 0;
  dealerFeesDiversificationFixedFeesTotal: number = 0;
  dealerFeesDiversificationFixedFeesItemArray: DealerYearlyDiversificationFixedFeesItem[];
  dealerFeesVariableFeesTotal: number = 0;
  dealerFeesFuelVariableFeesYearly: number = 0;
  dealerFeesFuelVariableFeesRate: number = 0;
  dealerFeesFuelVariableFeesTotal: number = 0;
  dealerFeesDiversificationVariableFeesYearly: number = 0;
  dealerFeesDiversificationVariableFeesRate: number = 0;
  dealerFeesDiversificationVariableFeesTotal: number = 0;
  dealerFeesDiversificationVariableFeesItemArray: DealerDiversificationVariableFeesItem[];
  dealerFeesCardCommissionYearly: number = 0;
  dealerFeesCardCommissionRate: number = 0;
  dealerFeesCardCommissionTotal: number = 0;
  dealerFeesTotal: number = 0;

  companyFeesFixedFeesTotal: number = 0;
  companyFeesFuelFixedFeesTotal: number = 0;
  companyFeesFuelFixedFeesRate: number = 0;
  companyFeesFuelFixedFeesProductVolume: number = 0;
  companyFeesFuelFixedFeesDodoDiscountGivenM3: number = 0;
  companyFeesFuelCostM3: number = 0;
  companyFeesDiversificationFixedFeesTotal: number = 0;
  companyFeesDiversificationFixedFeesItemArray: DealerYearlyDiversificationFixedFeesItem[];
  companyFeesVariableFeesTotal: number = 0;
  companyFeesFuelVariableFeesYearly: number = 0;
  companyFeesFuelVariableFeesRate: number = 0;
  companyFeesFuelVariableFeesTotal: number = 0;
  companyFeesDiversificationVariableFeesYearly: number = 0;
  companyFeesDiversificationVariableFeesRate: number = 0;
  companyFeesDiversificationVariableFeesTotal: number = 0;
  companyFeesDiversificationVariableFeesItemArray: DealerDiversificationVariableFeesItem[];
  companyFeesCardCommissionYearly: number = 0;
  companyFeesCardCommissionRate: number = 0;
  companyFeesCardCommissionTotal: number = 0;
  companyFeesTotal: number = 0;

  companyOperatingExpensesOverheadCosts: number = 0;
  companyOperatingExpensesNetworkChannelFixedCostsPerStation: number = 0;
  companyOperatingExpensesOperatingExpensesFromInvestments: number = 0;
  companyOperatingExpensesDirectFixedCosts: number = 0;
  companyOperatingExpensesNetworkChannelFixedCostsAllStations: number = 0;
  companyOperatingExpensesNetworkChannelNumberOfStations: number = 0;

  companyOperatingExpensesInflationFactorArray: number[] = [];
  companyOperatingExpensesInflationRateArray: number[] = [];
  companyOperatingExpensesOperatingExpensesFromInvestmentsArray: number[] = [];
  companyOperatingExpensesOverheadCostsArray: number[] = [];
  companyOperatingExpensesTotalArray: number[] = [];

  fixedFeesDiversificationTotal: number = 0;
  variableFeesDiversificationTotal: number = 0;
  taxesRate: number = 0;

  localCurrencyName: string;

  companyTaxesRate: number = 0;
  companyTaxesTaxAmount: number = 0;
  companyTaxesTaxableAmount: number = 0;
  companyTaxesYearlyEscalationRateArray: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationEbitda: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationAmortizationExcludingGoodwill: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationAmortizationGoodwill: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationExpensesOverheadCosts: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationNonRefundedDeposits: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationFinancialResults: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationTaxableAmount: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationTotal: number[] = this.yearlyEscalationDefault;

  financingInvestmentPercentageFinancedByLoan: number = 0;
  financingInvestmentAmountFinancedByLoan: number = 0;
  financingInvestmentInterestRate: number = 0;
  financingLoanInterest: number = 0;
  financingLoanYears: number = 0;
  financingLoanAmount: number = 0;
  financingInterestPaidArray: number[] = this.yearlyEscalationDefault;
  companyTaxesYearlyEscalationFinancingInterestPaidArray: number[] = this.yearlyEscalationDefault;
  financingInterestRateForFinancingArray: number[] = this.yearlyEscalationDefault;
  financingLoanToCompanyBalanceArray: number[] = this.yearlyEscalationDefault;
  financingLoanToCompanyPaymentArray: number[] = this.yearlyEscalationDefault;
  financingInterestReceivedArray: number[] = this.yearlyEscalationDefault;
  financingTotalArray: number[] = this.yearlyEscalationDefault;
  companyPaybackBalanceFromInitialCashNeededArray: number[] = this.yearlyEscalationDefault;
  companyPaybackFromInitialCashNeededArray: number[] = this.yearlyEscalationDefault;
  companyCashFlowRealArray: number[] = this.yearlyEscalationDefault;

  companySummaryNetFuelMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiversificationMarginArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherRevenueArray: number[] = this.yearlyEscalationDefault;
  companySummaryOperatingExpensesArray: number[] = this.yearlyEscalationDefault;
  companySummaryRentalsAndFeesDueArray: number[] = this.yearlyEscalationDefault;
  companySummaryEBITDAArray: number[] = this.yearlyEscalationDefault;
  companySummaryInvestmentArray: number[] = this.yearlyEscalationDefault;
  companySummaryChangeInWorkingCapitalArray: number[] = this.yearlyEscalationDefault;
  companySummaryFinancingInterestPaidArray: number[] = this.yearlyEscalationDefault;
  companySummaryTaxesArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherCashItemsArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxArray: number[] = this.yearlyEscalationDefault;
  companySummaryFinancialResultArray: number[] = this.yearlyEscalationDefault;
  companySummaryInflationRateArray: number[] = this.yearlyEscalationDefault;
  companyInterestReceivedArray: number[] = this.yearlyEscalationDefault;

  companySummaryEBITDARealArray: number[] = this.yearlyEscalationDefault;
  companySummaryOtherCashItemsRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryInvestmentRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryTaxesRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryChangeInWorkingCapitalRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryRealCompanyCashBalanceArray: number[] = this.yearlyEscalationDefault;
  companySummaryDiscountedNetCashAfterTaxRealArray: number[] = this.yearlyEscalationDefault;
  companySummaryNetCashAfterTaxDiscountedArray: number[] = this.yearlyEscalationDefault;

  dealerSummaryRevenue: number = 0;
  dealerSummaryExpense: number = 0;
  dealerSummaryEbitdaBeforeFees: number = 0;
  dealerSummaryFees: number = 0;
  dealerSummaryEbitda: number = 0;
  dealerSummaryTaxableProfit: number = 0;
  dealerSummaryTaxRate: number = 0;
  dealerSummaryTax: number = 0;
  dealerSummaryNetCashAfterTaxBeforeDeposit: number = 0;
  dealerSummaryDepositToBuildWCROrLoanRepayVolume: number = 0;
  dealerSummaryDepositToBuildWCROrLoanRepayRate: number = 0;
  dealerSummaryDepositToBuildWCROrLoanRepay: number = 0;
  dealerSummaryDepositForSecurityVolume: number = 0;
  dealerSummaryDepositForSecurityRate: number = 0;
  dealerSummaryDepositForSecurity: number = 0;
  dealerSummaryNetCashAfterTaxAndDeposit: number = 0;


  companyFeesReceivedInflationRateArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedInflationFactorArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthInPotentialArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthFactorArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFuelVolumeGrowthFactorWithRampUpArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesFuelArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesDiversificationArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFixedFeesFuelArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedFixedFeesDiversificationArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedVariableFeesCardCommissionArray: number[] = this.yearlyEscalationDefault;
  companyFeesReceivedTotalArray: number[] = this.yearlyEscalationDefault;
  companyInvestmentTotalArray: number[] = this.yearlyEscalationDefault;
  companyInvestmentArray: number[] = this.yearlyEscalationDefault;

  financingLoanYearlyPaymentAmountArray: number[] = this.yearlyEscalationDefault;
  financingLoanInterestAmountArray: number[] = this.yearlyEscalationDefault;
  financingLoanBalanceArray: number[] = this.yearlyEscalationDefault;
  financingLoanDrawdownArray: number[] = this.yearlyEscalationDefault;
  financingLoanToDealerArray: number[] = this.yearlyEscalationDefault;
  financingOtherCashItemsArray: number[] = this.yearlyEscalationDefault;

  brandingAmortizationArray: number[] = this.yearlyEscalationDefault;
  constructionAmortizationArray: number[] = this.yearlyEscalationDefault;
  equipmentAmortizationArray: number[] = this.yearlyEscalationDefault;
  totalAmortizationArray: number[] = this.yearlyEscalationDefault;

  financingBalanceAmountArray: number[] = this.yearlyEscalationDefault;

  giftBase64ImageArray: any[] = [];
  giftBase64ImageArrayTwo: any[] = [];
  projectName: string;
  projectInvestmentStartDate: string;
  projectNPV: number;
  projectIrr: number;
  projectPaybackPeriod: number;
  projectCountry: string;
  userName: string;
  dateToday: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private ipGeoService: IpGeoService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private petroleumProductsService: PetroleumProductsService,
    private authenticationService: AuthenticationService,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    this.subscription.add(
      authenticationService.currentUser.pipe().subscribe((res: any) => {
        if (res) {
          if (res[0]) {
            this.loggedInUser = res[0];
            let firstName = "";
            let otherName = "";
            let surname = "";
            if (res[0].userId) {
              this.userId = res[0].userId;
            }
            if (res[0].firstName) {
              firstName = res[0].firstName;
            };
            if (res[0].otherName) {
              otherName = res[0].otherName;
            };
            if (res[0].surname) {
              surname = res[0].surname;
            };
            this.userName = `${firstName} ${otherName} ${surname}`;
          } else {
          }
        }
      })
    );

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 22) {
        this.projectId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.s[2].path.toString()).pipe(first()).subscribe((res: ProjectDetails[]) => {
            if (res) {
              if (res[0]) {

              }
            }
          }));

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.s[2].path.toString()).pipe().subscribe((res: ProjectDetails[]) => {
            if (res) {
              this.projectArray = res;
              if (res[0]) {

                if (res[0].startDate) { this.projectInvestmentStartDate = res[0].startDate };
                if (res[0].netPresentValue) { this.projectNPV = res[0].netPresentValue };
                if (res[0].internalRateOfReturn) { this.projectIrr = res[0].internalRateOfReturn };
                if (res[0].paybackPeriodDiscounted) { this.projectPaybackPeriod = res[0].paybackPeriodDiscounted };

                if (res[0].fuelVolumeAndMarginGrowth) {
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowth) {
                    this.fuelVolumeGrowthArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowth;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowthPotential) {
                    this.fuelVolumeGrowthPotentialArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolumeGrowthPotential;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod) {
                    this.monthsDuringThePeriodArray = res[0].fuelVolumeAndMarginGrowth.monthsDuringThePeriod;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowth) {
                    this.fuelMarginGrowthArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowth;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactor) {
                    this.fuelMarginGrowthFactorArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactor;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactorTwo) {
                    this.fuelMarginGrowthFactorTwoArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginGrowthFactorTwo;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.monthsBeforeOperation) {
                    this.monthsBeforeOperationArray = res[0].fuelVolumeAndMarginGrowth.monthsBeforeOperation;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant) {
                    this.fuelVolumeWithoutLubricantArray = res[0].fuelVolumeAndMarginGrowth.fuelVolumeWithoutLubricant;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
                    this.fuelVolumeArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin) {
                    this.fuelMarginArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMargin;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.gasMargin) {
                    this.gasMarginArray = res[0].fuelVolumeAndMarginGrowth.gasMargin;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.gasVolume) {
                    this.gasVolumeArray = res[0].fuelVolumeAndMarginGrowth.gasVolume;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume) {
                    this.fuelAndLubricantMarginByVolume = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginByVolume;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.gasMarginByVolume) {
                    this.gasMarginByVolumeArray = res[0].fuelVolumeAndMarginGrowth.gasMarginByVolume;
                  }
                  if (res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginTotal) {
                    this.fuelMarginTotalArray = res[0].fuelVolumeAndMarginGrowth.fuelAndLubricantMarginTotal;
                  }
                }

                this.progressPercentage = 0;
                this.projectSettingsProgressPercentage = 0;
                this.projectMarginProgressPercentage = 0;
                this.projectFuelVolumeGrowthProgressPercentage = 0;
                this.projectOperatingExpenseProgressPercentage = 0;
                this.projectWorkingCapitalProgressPercentage = 0;
                this.projectTaxesProgressPercentage = 0;
                this.projectOtherRevenueProgressPercentage = 0;
                this.projectCompanyOperatingExpensesProgressPercentage = 0;
                this.projectCompanySummaryProgressPercentage = 0;
                this.projectFinancingProgressPercentage = 0;
                this.projectInvestmentProgressPercentage = 0;
                this.projectDealerRevenueProgressPercentage = 0;
                this.projectDealerFeesProgressPercentage = 0;
                this.projectDealerSummaryProgressPercentage = 0;

                this.showProjectSettingsSection = true;
                this.showProjectProductMarginSection = true;
                this.showProjectFuelVolumeGrowthSection = true;
                this.showProjectDealerOperatingExpenseSection = true;
                this.showProjectInvestmentSection = true;
                this.showProjectWorkingCapitalSection = true;
                this.showProjectTaxesSection = true;
                this.showProjectOtherRevenueSection = true;
                this.showProjectCompanyOperatingExpensesSection = true;
                this.showProjectFinancingSection = true;
                this.showProjectCompanySummarySection = true;
                this.showProjectDealerRevenueSection = true;
                this.showProjectDealerFeesSection = true;
                this.showProjectDealerSummarySection = true;

                if (res[0].projectSettingsSubmitted) {
                  this.projectSettingsSubmitted = res[0].projectSettingsSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectSettingsProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = true;
                  this.showProjectFuelVolumeGrowthSection = true;
                  this.showProjectInvestmentSection = true;
                  this.showProjectWorkingCapitalSection = true;
                  this.showProjectOtherRevenueSection = true;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectSettingsSection = true;
                }
                if (res[0].projectMarginSubmitted) {
                  this.projectMarginSubmitted = res[0].projectMarginSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectMarginProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = true;
                  this.showProjectInvestmentSection = true;
                  this.showProjectWorkingCapitalSection = true;
                  this.showProjectOtherRevenueSection = true;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectProductMarginSection = true;
                }
                if (res[0].projectFuelVolumeGrowthSubmitted) {
                  this.projectFuelVolumeGrowthSubmitted = res[0].projectFuelVolumeGrowthSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectFuelVolumeGrowthProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = true;
                  this.showProjectWorkingCapitalSection = true;
                  this.showProjectOtherRevenueSection = true;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectFuelVolumeGrowthSection = true;
                }
                if (res[0].projectInvestmentSubmitted) {
                  this.projectInvestmentSubmitted = res[0].projectInvestmentSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectInvestmentProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = true;
                  this.showProjectOtherRevenueSection = true;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectInvestmentSection = true;
                }
                if (res[0].projectWorkingCapitalSubmitted) {
                  this.projectWorkingCapitalSubmitted = res[0].projectWorkingCapitalSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectWorkingCapitalProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = true;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectWorkingCapitalSection = true;
                }
                if (res[0].projectOtherRevenueSubmitted) {
                  this.projectOtherRevenueSubmitted = res[0].projectOtherRevenueSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectOtherRevenueProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = true;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectOtherRevenueSection = true;
                }
                if (res[0].projectCompanyOperatingExpensesSubmitted) {
                  this.projectCompanyOperatingExpensesSubmitted = res[0].projectCompanyOperatingExpensesSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectCompanyOperatingExpensesProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = true;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectCompanyOperatingExpensesSection = true;
                }
                if (res[0].projectTaxesSubmitted) {
                  this.projectTaxesSubmitted = res[0].projectTaxesSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectTaxesProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = true;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectTaxesSection = true;
                }
                if (res[0].projectFinancingSubmitted) {
                  this.projectFinancingSubmitted = res[0].projectFinancingSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectFinancingProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = true;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectFinancingSection = true;
                }

                if (res[0].projectCompanySummarySubmitted) {
                  this.projectCompanySummarySubmitted = res[0].projectCompanySummarySubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectCompanySummaryProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = false;
                  this.showProjectDealerOperatingExpenseSection = true;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectCompanySummarySection = true;
                }
                if (res[0].projectDealerOperatingExpenseSubmitted) {
                  this.projectDealerOperatingExpenseSubmitted = res[0].projectDealerOperatingExpenseSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectOperatingExpenseProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = false;
                  this.showProjectDealerOperatingExpenseSection = false;
                  this.showProjectDealerRevenueSection = true;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectDealerOperatingExpenseSection = true;
                }
                if (res[0].projectDealerRevenueSubmitted) {
                  this.projectDealerRevenueSubmitted = res[0].projectDealerRevenueSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectDealerRevenueProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = false;
                  this.showProjectDealerOperatingExpenseSection = false;
                  this.showProjectDealerRevenueSection = false;
                  this.showProjectDealerFeesSection = true;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectDealerRevenueSection = true;
                }
                if (res[0].projectDealerFeesSubmitted) {
                  this.projectDealerFeesSubmitted = res[0].projectDealerFeesSubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectDealerFeesProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = false;
                  this.showProjectDealerOperatingExpenseSection = false;
                  this.showProjectDealerRevenueSection = false;
                  this.showProjectDealerFeesSection = false;
                  this.showProjectDealerSummarySection = true;
                } else {
                  this.showProjectDealerFeesSection = true;
                }
                if (res[0].projectDealerSummarySubmitted) {
                  this.projectDealerSummarySubmitted = res[0].projectDealerSummarySubmitted;
                  this.progressPercentage += Number(100 / 14);
                  this.projectDealerSummaryProgressPercentage = 100;
                  this.showProjectSettingsSection = false;
                  this.showProjectProductMarginSection = false;
                  this.showProjectFuelVolumeGrowthSection = false;
                  this.showProjectInvestmentSection = false;
                  this.showProjectWorkingCapitalSection = false;
                  this.showProjectOtherRevenueSection = false;
                  this.showProjectCompanyOperatingExpensesSection = false;
                  this.showProjectTaxesSection = false;
                  this.showProjectFinancingSection = false;
                  this.showProjectCompanySummarySection = false;
                  this.showProjectDealerOperatingExpenseSection = false;
                  this.showProjectDealerRevenueSection = false;
                  this.showProjectDealerFeesSection = false;
                  this.showProjectDealerSummarySection = false;
                } else {
                  this.showProjectDealerSummarySection = true;
                }

                if (res[0].fuelProductTotal) {
                  this.fuelProductTotal = res[0].fuelProductTotal;
                }
                if (res[0].fuelAndLubricantProductTotal) {
                  this.fuelAndLubricantProductTotal = res[0].fuelAndLubricantProductTotal;
                }
                if (res[0].gasProductTotal) {
                  this.gasProductTotal = res[0].gasProductTotal;
                }
                if (res[0].lubricantProductTotal) {
                  this.lubricantProductTotal = res[0].lubricantProductTotal;
                }

                if (res[0].country) {
                  this.selectedCountry = res[0].country;
                  if (res[0].country.name) {
                    this.projectCountry = res[0].country.name;
                  };
                  if (res[0].country.id) {
                    this.projectSettingsEditForm.get("id").setValue(res[0].country.id);
                  }
                  if (res[0].country.localCurrencyISOCode) {
                    this.localCurrencyISOCode = res[0].country.localCurrencyISOCode;
                  };
                  if (res[0].country.localCurrencyName) {
                    this.localCurrencyName = res[0].country.localCurrencyName;
                  }
                  if (res[0].country.usdFxRate) {
                    this.projectSettingsEditForm.get("usdFxRate").setValue(res[0].country.usdFxRate);
                  }
                  if (res[0].country.taxCarryOverPeriod) {
                    this.projectSettingsEditForm.get("taxCarryOverPeriod").setValue(res[0].country.taxCarryOverPeriod);
                  }
                  if (res[0].country.discountRateForNetPresentValue) {
                    this.projectSettingsEditForm.get("discountRateForNetPresentValue").setValue(res[0].country.discountRateForNetPresentValue);
                  }
                  if (res[0].country.corporateTaxRate) {
                    this.projectSettingsEditForm.get("corporateTaxRate").setValue(res[0].country.corporateTaxRate);
                  }
                }
                if (res[0].name) {
                  this.projectSettingsEditForm.get("name").setValue(res[0].name);
                  this.projectName = res[0].name;
                }
                if (res[0].typeOfInvestment) {
                  this.projectSettingsEditForm.get("typeOfInvestment").setValue(res[0].typeOfInvestment);
                }
                if (res[0].monthsBeforeOperation) {
                  this.projectSettingsEditForm.get("monthsBeforeOperation").setValue(res[0].monthsBeforeOperation);
                }
                if (res[0].periodOfOperation) {
                  this.projectSettingsEditForm.get("periodOfOperation").setValue(res[0].periodOfOperation);
                }
                if (res[0].mode) {
                  this.projectSettingsEditForm.get("mode").setValue(res[0].mode);
                }
                if (res[0].economicEnvironment) {
                  if (res[0].economicEnvironment.yearlyEscalation) {
                    if (res[0].economicEnvironment.yearlyEscalation.inflationRate) {
                      this.inflationRateArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                      let indexA = 0;
                      for (let index = 0; index < 16; index++) {
                        this.inflationRateArray[indexA] = ((res[0].economicEnvironment.yearlyEscalation.inflationRate[indexA]));
                        indexA += 1;
                      }
                    }
                    if (res[0].economicEnvironment.yearlyEscalation.inflationFactor) {
                      this.inflationFactorArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                      let indexA = 0;
                      for (let index = 0; index < 16; index++) {
                        this.inflationFactorArray[indexA] = ((res[0].economicEnvironment.yearlyEscalation.inflationFactor[indexA]));
                        indexA += 1;
                      }
                    }
                    if (res[0].economicEnvironment.yearlyEscalation.inflationFactorTwo) {
                      this.inflationFactorTwoArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                      let indexA = 0;
                      for (let index = 0; index < 16; index++) {
                        this.inflationFactorTwoArray[indexA] = ((res[0].economicEnvironment.yearlyEscalation.inflationFactorTwo[indexA]));
                        indexA += 1;
                      }
                    }
                  }
                }
              }
            }
          })
        );

        this.subscription.add(
          this.projectService.getPetroleumProductArray().pipe().subscribe((res: FuelLubricantGasProduct[]) => {
            if (res) {
              this.petroleumProductArray = res;
            }
          })
        );

        // Get All Products
        this.subscription.add(
          this.projectService.getProjectFuelProductArray(this.projectId).pipe().subscribe((res: FuelProduct[]) => {
            if (res) {
              this.fuelProductArray = res;
            }
          })
        );
        this.subscription.add(
          this.projectService.getProjectLubricantProductArray(this.projectId).pipe().subscribe((res: LubricantProduct[]) => {
            if (res) {
              this.lubricantProductArray = res;
            }
          })
        );

        this.subscription.add(
          this.projectService.getProjectGasProductArray(this.projectId).pipe().subscribe((res: GasProduct[]) => {
            if (res) {
              this.gasProductArray = res;
            }
          })
        );

        this.subscription.add(
          this.projectService.getProjectDealerOperatingExpenseObject(this.projectId).pipe().subscribe((res: DealerOperatingExpenses) => {

            if (res) {
              if (res.salariesAndBonus) {
                this.salariesAndBonusItemArray = res.salariesAndBonus;
              }
              if (res.employeeContribution) {
                this.employeeContributionItemArray = res.employeeContribution;
              }
              if (res.electricity) {
                this.electricityItemArray = res.electricity;
              }
              if (res.miscellaneous) {
                this.miscellaneousItemArray = res.miscellaneous;
              }
              if (res.productLoss) {
                this.productLossItemArray = res.productLoss;
              }
              if (res.salariesAndBonusTotal) {
                this.dealerOperatingExpenseSalariesAndBonusTotal = res.salariesAndBonusTotal;
              }
              if (res.employeeContributionTotal) {
                this.dealerOperatingExpenseEmployeeContributionTotal = res.employeeContributionTotal;
              }
              if (res.electricityTotal) {
                this.dealerOperatingExpenseElectricityTotal = res.electricityTotal;
              }
              if (res.miscellaneousTotal) {
                this.dealerOperatingExpenseMiscellaneousTotal = res.miscellaneousTotal;
              }
              if (res.productLossTotal) {
                this.dealerOperatingExpenseProductLossTotal = res.productLossTotal;
              }
              if (res.total) {
                this.dealerOperatingExpenseTotal = res.total;
              }
            }
          })
        );

        this.subscription.add(
          this.projectService.getProjectWorkingCapitalObject(this.projectId).pipe().subscribe((res: WorkingCapital) => {

            if (res) {
              if (res.whiteProductSupplierCreditDays) { this.workingCapitalForm.get("whiteProductSupplierCreditDays").setValue(res.whiteProductSupplierCreditDays); this.whiteProductSupplierCreditDays = res.whiteProductSupplierCreditDays };
              if (res.whiteProductStockLogisticsDays) { this.workingCapitalForm.get("whiteProductStockLogisticsDays").setValue(res.whiteProductStockLogisticsDays); this.whiteProductStockLogisticsDays = res.whiteProductStockLogisticsDays };
              if (res.lubricantSupplierCreditDays) { this.workingCapitalForm.get("lubricantSupplierCreditDays").setValue(res.lubricantSupplierCreditDays); this.lubricantSupplierCreditDays = res.lubricantSupplierCreditDays };
              if (res.lubricantStockLogisticsDays) { this.workingCapitalForm.get("lubricantStockLogisticsDays").setValue(res.lubricantStockLogisticsDays); this.lubricantStockLogisticsDays = res.lubricantStockLogisticsDays };

              if (res.lubricantStockLogisticsTotal) { this.lubricantStockLogisticsTotal = res.lubricantStockLogisticsTotal; };
              if (res.lubricantSupplierCreditTotal) { this.lubricantSupplierCreditTotal = res.lubricantSupplierCreditTotal; };
              if (res.lubricantWorkingCapital) { this.lubricantWorkingCapital = res.lubricantWorkingCapital; };
              if (res.whiteProductStockLogisticsTotal) { this.whiteProductStockLogisticsTotal = res.whiteProductStockLogisticsTotal; };
              if (res.whiteProductSupplierCreditTotal) { this.whiteProductSupplierCreditTotal = res.whiteProductSupplierCreditTotal; };
              if (res.whiteProductWorkingCapital) { this.whiteProductWorkingCapital = res.whiteProductWorkingCapital; };

              if (res.whiteProductLubricantAverageWorkingCapital) { this.whiteProductLubricantAverageWorkingCapital = res.whiteProductLubricantAverageWorkingCapital; };
              if (res.whiteProductLubricantWorkingCapital) { this.whiteProductLubricantWorkingCapital = res.whiteProductLubricantWorkingCapital };
              if (res.firstYearWorkingCapital) { this.firstYearWorkingCapital = res.firstYearWorkingCapital };

              if (res.fuelAndLubricantYearlyVolume) { this.fuelAndLubricantYearlyVolume = res.fuelAndLubricantYearlyVolume };
              if (res.fuelYearlyVolume) { this.fuelYearlyVolume = res.fuelYearlyVolume };
              if (res.lubricantYearlyVolume) { this.lubricantYearlyVolume = res.lubricantYearlyVolume };

              if (res.fuelUnitSupplyPrice) { this.fuelUnitSupplyPrice = res.fuelUnitSupplyPrice };
              if (res.lubricantUnitSupplyPrice) { this.lubricantUnitSupplyPrice = res.lubricantUnitSupplyPrice };

              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.averageWorkingCapital) {
                  this.averageWorkingCapitalArray = [];
                  this.averageWorkingCapitalArray = res.yearlyEscalation.averageWorkingCapital;
                }
                if (res.yearlyEscalation.inflationRate) {
                  this.inflationRateArray = [];
                  this.inflationRateArray = res.yearlyEscalation.inflationRate;
                }
                if (res.yearlyEscalation.inflationFactor) {
                  this.inflationFactorArray = [];
                  this.inflationFactorArray = res.yearlyEscalation.inflationFactor;
                }
                if (res.yearlyEscalation.averageWorkingCapitalByInflationFactor) {
                  this.averageWorkingCapitalByInflationFactorArray = [];
                  this.averageWorkingCapitalByInflationFactorArray = res.yearlyEscalation.averageWorkingCapitalByInflationFactor;
                }
                if (res.yearlyEscalation.fuelAndLubricantVolume) {
                  this.fuelsAndLubricantVolumeArray = [];
                  this.fuelsAndLubricantVolumeArray = res.yearlyEscalation.fuelAndLubricantVolume;
                }
                if (res.yearlyEscalation.workingCapital) {
                  this.workingCapitalMainArray = [];
                  this.workingCapitalMainArray = res.yearlyEscalation.workingCapital;
                }
                if (res.yearlyEscalation.workingCapitalReal) {
                  this.workingCapitalMainRealArray = [];
                  this.workingCapitalMainRealArray = res.yearlyEscalation.workingCapitalReal;
                }
                if (res.yearlyEscalation.changeInWorkingCapital) {
                  this.changeInWorkingCapitalArray = [];
                  this.changeInWorkingCapitalArray = res.yearlyEscalation.changeInWorkingCapital;
                }
                if (res.yearlyEscalation.changeInWorkingCapitalReal) {
                  this.changeInWorkingCapitalRealArray = [];
                  this.changeInWorkingCapitalRealArray = res.yearlyEscalation.changeInWorkingCapitalReal;
                }
              }
            }
          })
        );

        this.subscription.add(
          this.projectService.getProjectCompanyTaxesObject(this.projectId).pipe().subscribe((res: CompanyTaxes) => {
            if (res.corporateTaxRate) { this.taxesRate = res.corporateTaxRate; };
          }));

        this.subscription.add(
          this.projectService.getProjectCompanySummaryObject(this.projectId).pipe().subscribe((res: CompanySummary) => {
            if (res) {
              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.netFuelMargin) { this.companySummaryNetFuelMarginArray = res.yearlyEscalation.netFuelMargin };
                if (res.yearlyEscalation.companyDiversificationMargin) { this.companySummaryDiversificationMarginArray = res.yearlyEscalation.companyDiversificationMargin };
                if (res.yearlyEscalation.companyOtherRevenue) { this.companySummaryOtherRevenueArray = res.yearlyEscalation.companyOtherRevenue };
                if (res.yearlyEscalation.companyOperatingExpenses) { this.companySummaryOperatingExpensesArray = res.yearlyEscalation.companyOperatingExpenses };
                if (res.yearlyEscalation.companyRentalsAndFeesDue) { this.companySummaryRentalsAndFeesDueArray = res.yearlyEscalation.companyRentalsAndFeesDue };
                if (res.yearlyEscalation.ebitda) { this.companySummaryEBITDAArray = res.yearlyEscalation.ebitda };
                if (res.yearlyEscalation.companyInvestment) { this.companySummaryInvestmentArray = res.yearlyEscalation.companyInvestment };
                if (res.yearlyEscalation.changeInWorkingCapital) { this.companySummaryChangeInWorkingCapitalArray = res.yearlyEscalation.changeInWorkingCapital };
                if (res.yearlyEscalation.financingInterestPaid) { this.companySummaryFinancingInterestPaidArray = res.yearlyEscalation.financingInterestPaid };
                if (res.yearlyEscalation.taxAmount) { this.companySummaryTaxesArray = res.yearlyEscalation.taxAmount };
                if (res.yearlyEscalation.otherCashItems) { this.companySummaryOtherCashItemsArray = res.yearlyEscalation.otherCashItems };
                if (res.yearlyEscalation.netCashAfterTax) { this.companySummaryNetCashAfterTaxArray = res.yearlyEscalation.netCashAfterTax };
                if (res.yearlyEscalation.financialResult) { this.companySummaryFinancialResultArray = res.yearlyEscalation.financialResult };
                if (res.yearlyEscalation.inflationRate) { this.companySummaryInflationRateArray = res.yearlyEscalation.inflationRate };
                if (res.yearlyEscalation.companyInterestReceived) { this.companyInterestReceivedArray = res.yearlyEscalation.companyInterestReceived };

                if (res.yearlyEscalation.ebitdaReal) { this.companySummaryEBITDARealArray = res.yearlyEscalation.ebitdaReal };
                if (res.yearlyEscalation.otherCashItemsReal) { this.companySummaryOtherCashItemsRealArray = res.yearlyEscalation.otherCashItemsReal };
                if (res.yearlyEscalation.companyInvestmentReal) { this.companySummaryInvestmentRealArray = res.yearlyEscalation.companyInvestmentReal };
                if (res.yearlyEscalation.taxAmountReal) { this.companySummaryTaxesRealArray = res.yearlyEscalation.taxAmountReal };
                if (res.yearlyEscalation.changeInWorkingCapitalReal) { this.companySummaryChangeInWorkingCapitalRealArray = res.yearlyEscalation.changeInWorkingCapitalReal };
                if (res.yearlyEscalation.netCashAfterTaxReal) { this.companySummaryNetCashAfterTaxRealArray = res.yearlyEscalation.netCashAfterTaxReal };
                if (res.yearlyEscalation.realCompanyCashBalance) { this.companySummaryRealCompanyCashBalanceArray = res.yearlyEscalation.realCompanyCashBalance };

                if (res.yearlyEscalation.discountedNetCashAfterTaxReal) { this.companySummaryDiscountedNetCashAfterTaxRealArray = res.yearlyEscalation.discountedNetCashAfterTaxReal };
                if (res.yearlyEscalation.netCashAfterTaxDiscounted) { this.companySummaryNetCashAfterTaxDiscountedArray = res.yearlyEscalation.netCashAfterTaxDiscounted };
              };
            }
          }));

        this.subscription.add(
          this.projectService.getProjectCompanyOperatingExpensesObject(this.projectId).pipe().subscribe((res: CompanyOperatingExpenses) => {
            if (res) {
              if (res.operatingExpensesFromInvestments) {
                this.companyOperatingExpensesForm.get("operatingExpensesFromInvestments").setValue(Number((res.operatingExpensesFromInvestments).toFixed(6)));
                this.companyOperatingExpensesOperatingExpensesFromInvestments = Number((res.operatingExpensesFromInvestments).toFixed(6));
              }
              if (res.directFixedCosts) {
                this.companyOperatingExpensesForm.get("directFixedCosts").setValue(Number((res.directFixedCosts).toFixed(6)));
                this.companyOperatingExpensesDirectFixedCosts = Number((res.directFixedCosts).toFixed(6));
              }
              if (res.networkChannelFixedCostsAllStations) {
                this.companyOperatingExpensesForm.get("networkChannelFixedCostsAllStations").setValue(Number((res.networkChannelFixedCostsAllStations).toFixed(6)));
                this.companyOperatingExpensesNetworkChannelFixedCostsAllStations = Number((res.networkChannelFixedCostsAllStations).toFixed(6));
              }
              if (res.networkChannelNumberOfStations) {
                this.companyOperatingExpensesForm.get("networkChannelNumberOfStations").setValue(Number((res.networkChannelNumberOfStations).toFixed(6)));
                this.companyOperatingExpensesNetworkChannelNumberOfStations = Number((res.networkChannelNumberOfStations).toFixed(6));
              }
              if (res.networkChannelFixedCostsPerStation) {
                this.companyOperatingExpensesForm.get("networkChannelFixedCostsPerStation").setValue(Number((res.networkChannelFixedCostsPerStation).toFixed(6)));
                this.companyOperatingExpensesNetworkChannelFixedCostsPerStation = Number((res.networkChannelFixedCostsPerStation).toFixed(6));
              }
              if (res.overheadCosts) {
                this.companyOperatingExpensesForm.get("overheadCosts").setValue(Number((res.overheadCosts).toFixed(6)));
                this.companyOperatingExpensesOverheadCosts = Number((res.overheadCosts).toFixed(6));
              }

              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.inflationRate) {
                  this.companyOperatingExpensesInflationRateArray = [];
                  this.companyOperatingExpensesInflationRateArray = res.yearlyEscalation.inflationRate;
                };
                if (res.yearlyEscalation.inflationFactor) {
                  this.companyOperatingExpensesInflationFactorArray = [];
                  this.companyOperatingExpensesInflationFactorArray = res.yearlyEscalation.inflationFactor;
                };
                if (res.yearlyEscalation.expensesFromInvestments) {
                  this.companyOperatingExpensesOperatingExpensesFromInvestmentsArray = [];
                  this.companyOperatingExpensesOperatingExpensesFromInvestmentsArray = res.yearlyEscalation.expensesFromInvestments;
                };
                if (res.yearlyEscalation.expensesOverheadCosts) {
                  this.companyOperatingExpensesOverheadCostsArray = [];
                  this.companyOperatingExpensesOverheadCostsArray = res.yearlyEscalation.expensesOverheadCosts;
                };
                if (res.yearlyEscalation.expensesTotal) {
                  this.companyOperatingExpensesTotalArray = [];
                  this.companyOperatingExpensesTotalArray = res.yearlyEscalation.expensesTotal;
                };
              };
            }

          }));

        this.subscription.add(
          this.projectService.getProjectCompanyTaxesObject(this.projectId).pipe().subscribe((res: CompanyTaxes) => {

            if (res) {

              if (res.corporateTaxRate) { this.companyTaxesRate = res.corporateTaxRate };
              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.taxRate) { this.companyTaxesYearlyEscalationRateArray = res.yearlyEscalation.taxRate; };
                if (res.yearlyEscalation.ebitda) { this.companyTaxesYearlyEscalationEbitda = res.yearlyEscalation.ebitda };
                if (res.yearlyEscalation.amortizationExcludingGoodwill) { this.companyTaxesYearlyEscalationAmortizationExcludingGoodwill = res.yearlyEscalation.amortizationExcludingGoodwill };
                if (res.yearlyEscalation.amortizationGoodwill) { this.companyTaxesYearlyEscalationAmortizationGoodwill = res.yearlyEscalation.amortizationGoodwill };
                if (res.yearlyEscalation.expensesOverheadCosts) { this.companyTaxesYearlyEscalationExpensesOverheadCosts = res.yearlyEscalation.expensesOverheadCosts };
                if (res.yearlyEscalation.nonRefundedDeposits) { this.companyTaxesYearlyEscalationNonRefundedDeposits = res.yearlyEscalation.nonRefundedDeposits };
                if (res.yearlyEscalation.financingInterestPaid) { this.companyTaxesYearlyEscalationFinancingInterestPaidArray = res.yearlyEscalation.financingInterestPaid };
                if (res.yearlyEscalation.financialResults) { this.companyTaxesYearlyEscalationFinancialResults = res.yearlyEscalation.financialResults };
                if (res.yearlyEscalation.taxableAmount) { this.companyTaxesYearlyEscalationTaxableAmount = res.yearlyEscalation.taxableAmount };
                if (res.yearlyEscalation.total) { this.companyTaxesYearlyEscalationTotal = res.yearlyEscalation.total };
              };
            }
          }));

        this.subscription.add(
          this.projectService.getProjectFinancingObject(this.projectId).pipe().subscribe((res: CompanyFinancing) => {
            if (res.loanAmount) {
              this.financingLoanAmount = res.loanAmount;
              this.financingForm.get("loanAmount").setValue(res.loanAmount);
            };
            if (res.loanYears) {
              this.financingLoanYears = res.loanYears;
              this.financingForm.get("loanYears").setValue(res.loanYears)
            };
            if (res.interestRate) {
              this.financingLoanInterest = res.interestRate;
              this.financingForm.get("interestRate").setValue(res.interestRate);
            };
            if (res.investmentPercentageFinancedByLoan) {
              this.financingInvestmentPercentageFinancedByLoan = res.investmentPercentageFinancedByLoan;
              this.financingForm.get("investmentPercentageFinancedByLoan").setValue(res.investmentPercentageFinancedByLoan);
            };
            if (res.investmentAmountFinancedByLoan) {
              this.financingInvestmentAmountFinancedByLoan = res.investmentAmountFinancedByLoan;
            };
            if (res.investmentInterestRate) {
              this.financingInvestmentInterestRate = res.investmentInterestRate;
              this.financingForm.get("investmentInterestRate").setValue(res.investmentInterestRate);
            }
            if (res.yearlyEscalation) {
              if (res.yearlyEscalation.loanToDealerYearlyPaymentAmount) {
                this.financingLoanYearlyPaymentAmountArray = res.yearlyEscalation.loanToDealerYearlyPaymentAmount;
              };
              if (res.yearlyEscalation.loanToDealerInterestAmount) {
                this.financingLoanInterestAmountArray = res.yearlyEscalation.loanToDealerInterestAmount;
              };
              if (res.yearlyEscalation.loanToDealerBalance) {
                this.financingLoanBalanceArray = res.yearlyEscalation.loanToDealerBalance;
              };
              if (res.yearlyEscalation.loanToDealerDrawdown) {
                this.financingLoanDrawdownArray = res.yearlyEscalation.loanToDealerDrawdown;
              };
              if (res.yearlyEscalation.otherCashItems) {
                this.financingOtherCashItemsArray = res.yearlyEscalation.otherCashItems;
              };
              if (res.yearlyEscalation.loanToDealer) {
                this.financingLoanToDealerArray = res.yearlyEscalation.loanToDealer;
              };
              if (res.yearlyEscalation.loanToCompanyInterestPaid) {
                this.financingInterestPaidArray = res.yearlyEscalation.loanToCompanyInterestPaid;
              };
              if (res.yearlyEscalation.loanToCompanyInterestRate) {
                this.financingInterestRateForFinancingArray = res.yearlyEscalation.loanToCompanyInterestRate;
              };
              if (res.yearlyEscalation.loanToCompanyBalance) {
                this.financingLoanToCompanyBalanceArray = res.yearlyEscalation.loanToCompanyBalance;
              };
              if (res.yearlyEscalation.loanToCompanyPayment) {
                this.financingLoanToCompanyPaymentArray = res.yearlyEscalation.loanToCompanyPayment;
              };
              if (res.yearlyEscalation.interestReceived) {
                this.financingInterestReceivedArray = res.yearlyEscalation.interestReceived;
              };
              if (res.yearlyEscalation.total) {
                this.financingTotalArray = res.yearlyEscalation.total;
              };

              // New - Temp
              if (res.yearlyEscalation.companyPaybackFromInitialCashNeeded) {
                this.companyPaybackFromInitialCashNeededArray = res.yearlyEscalation.companyPaybackFromInitialCashNeeded;
              };
              if (res.yearlyEscalation.companyPaybackBalanceFromInitialCashNeeded) {
                this.companyPaybackBalanceFromInitialCashNeededArray = res.yearlyEscalation.companyPaybackBalanceFromInitialCashNeeded;
              };
              if (res.yearlyEscalation.companyCashFlowReal) {
                this.companyCashFlowRealArray = res.yearlyEscalation.companyCashFlowReal;
              };
            }
          }));

        this.subscription.add(
          this.projectService.getProjectDealerRevenueObject(this.projectId).pipe().subscribe((res: DealerRevenue) => {
            if (res.fuelItems) {
              this.dealerRevenueFuelProductArray = res.fuelItems;
            }
            if (res.lubricantItems) {
              this.dealerRevenueLubricantProductArray = res.lubricantItems;
            }
            if (res.gasItems) {
              this.dealerRevenueGasProductArray = res.gasItems;
            }
            if (res.nonFuelRevenueItems) {
              this.dealerRevenueNonFuelProductArray = res.nonFuelRevenueItems;
            }

            this.dealerRevenueFuelLubricantGasTotal = 0;

            if (res.fuelTotal) { this.dealerRevenueFuelProductTotalRevenue = res.fuelTotal; };
            if (res.lubricantTotal) { this.dealerRevenueLubricantProductTotalRevenue = res.lubricantTotal; };
            if (res.gasTotal) { this.dealerRevenueGasProductTotalRevenue = res.gasTotal; };
            if (res.dealerRevenueNonFuelRevenueTotal) { this.dealerRevenueNonFuelTotal = res.dealerRevenueNonFuelRevenueTotal; };
            if (res.fuelGasLubricantTotal) { this.dealerRevenueFuelLubricantGasTotal = res.fuelGasLubricantTotal; };
            if (res.dealerRevenueTotal) { this.dealerRevenueTotal = res.dealerRevenueTotal; };
          }));


        this.subscription.add(
          this.projectService.getProjectDealerFeesObject(this.projectId).pipe().subscribe((res: DealerFees) => {
            if (res.fixedFeesFuelTotal) { this.dealerFeesFuelFixedFeesTotal = res.fixedFeesFuelTotal };
            if (res.fixedFeesFuelRate) { this.dealerFeesReceivedForm.get("fixedFeesFuelRate").setValue(res.fixedFeesFuelRate); this.dealerFeesFuelFixedFeesRate = res.fixedFeesFuelRate };
            if (res.fuelCostM3) { this.dealerFeesReceivedForm.get("fuelCostM3").setValue(res.fuelCostM3); this.dealerFeesFuelCostM3 = res.fuelCostM3 };
            if (res.fixedFeesFuelDodoDiscountGivenM3) { this.dealerFeesReceivedForm.get("fixedFeesFuelDodoDiscountGivenM3").setValue(res.fixedFeesFuelDodoDiscountGivenM3); this.dealerFeesFuelFixedFeesDodoDiscountGivenM3 = res.fixedFeesFuelDodoDiscountGivenM3 };
            if (res.fixedFeesFuelProductVolume) { this.dealerFeesFuelFixedFeesProductVolume = res.fixedFeesFuelProductVolume };
            if (res.fixedFeesDiversificationItem) {
              this.dealerFeesDiversificationFixedFeesItemArray = res.fixedFeesDiversificationItem;
            };
            if (res.fixedFeesDiversificationTotal) { this.dealerFeesDiversificationFixedFeesTotal = res.fixedFeesDiversificationTotal };
            if (res.fixedFeesTotal) { this.dealerFeesFixedFeesTotal = res.fixedFeesTotal };
            if (res.variableFeesFuelProductVolume) { this.dealerFeesFuelVariableFeesYearly = res.variableFeesFuelProductVolume };
            if (res.variableFeesFuelRate) { this.dealerFeesReceivedForm.get("variableFeesFuelRate").setValue(res.variableFeesFuelRate); this.dealerFeesFuelVariableFeesRate = res.variableFeesFuelRate };
            if (res.variableFeesFuelTotal) { this.dealerFeesFuelVariableFeesTotal = res.variableFeesFuelTotal };
            if (res.variableFeesDiversificationYearly) { this.dealerFeesDiversificationVariableFeesYearly = res.variableFeesDiversificationYearly };
            if (res.variableFeesDiversificationRate) { this.dealerFeesDiversificationVariableFeesRate = res.variableFeesDiversificationRate };
            if (res.variableFeesDiversificationTotal) { this.dealerFeesDiversificationVariableFeesTotal = res.variableFeesDiversificationTotal };
            if (res.variableFeesDiversificationItem) {
              this.dealerFeesDiversificationVariableFeesItemArray = res.variableFeesDiversificationItem;
            };
            if (res.variableFeesCardCommissionYearly) { this.dealerFeesCardCommissionYearly = res.variableFeesCardCommissionYearly };
            if (res.variableFeesCardCommissionRate) { this.dealerFeesReceivedForm.get("variableFeesCardCommissionRate").setValue(res.variableFeesCardCommissionRate); this.dealerFeesCardCommissionRate = res.variableFeesCardCommissionRate };
            if (res.variableFeesCardCommissionTotal) { this.dealerFeesCardCommissionTotal = res.variableFeesCardCommissionTotal };
            if (res.variableFeesTotal) { this.dealerFeesVariableFeesTotal = res.variableFeesTotal };
            if (res.total) { this.dealerFeesTotal = res.total };
          })
        );

        this.subscription.add(
          this.projectService.getProjectCompanyFeesReceivedObject(this.projectId).pipe().subscribe((res: CompanyFeesReceived) => {
            if (res.fixedFeesFuelTotal) { this.companyFeesFuelFixedFeesTotal = res.fixedFeesFuelTotal };
            if (res.fixedFeesFuelRate) { this.companyFeesReceivedForm.get("fixedFeesFuelRate").setValue(res.fixedFeesFuelRate); this.companyFeesFuelFixedFeesRate = res.fixedFeesFuelRate };
            if (res.fuelCostM3) { this.companyFeesReceivedForm.get("fuelCostM3").setValue(res.fuelCostM3); this.companyFeesFuelCostM3 = res.fuelCostM3 };
            if (res.fixedFeesFuelDodoDiscountGivenM3) { this.companyFeesReceivedForm.get("fixedFeesFuelDodoDiscountGivenM3").setValue(res.fixedFeesFuelDodoDiscountGivenM3); this.companyFeesFuelFixedFeesDodoDiscountGivenM3 = res.fixedFeesFuelDodoDiscountGivenM3 };
            if (res.fixedFeesFuelProductVolume) { this.companyFeesFuelFixedFeesProductVolume = res.fixedFeesFuelProductVolume };
            if (res.fixedFeesDiversificationItem) {
              this.companyFeesDiversificationFixedFeesItemArray = res.fixedFeesDiversificationItem
            };
            if (res.fixedFeesDiversificationTotal) { this.companyFeesDiversificationFixedFeesTotal = res.fixedFeesDiversificationTotal };
            if (res.fixedFeesTotal) { this.companyFeesFixedFeesTotal = res.fixedFeesTotal };
            if (res.variableFeesFuelProductVolume) { this.companyFeesFuelVariableFeesYearly = res.variableFeesFuelProductVolume };
            if (res.variableFeesFuelRate) { this.companyFeesReceivedForm.get("variableFeesFuelRate").setValue(res.variableFeesFuelRate); this.companyFeesFuelVariableFeesRate = res.variableFeesFuelRate };
            if (res.variableFeesFuelTotal) { this.companyFeesFuelVariableFeesTotal = res.variableFeesFuelTotal };
            if (res.variableFeesDiversificationYearly) { this.companyFeesDiversificationVariableFeesYearly = res.variableFeesDiversificationYearly };
            if (res.variableFeesDiversificationRate) { this.companyFeesDiversificationVariableFeesRate = res.variableFeesDiversificationRate };
            if (res.variableFeesDiversificationTotal) { this.companyFeesDiversificationVariableFeesTotal = res.variableFeesDiversificationTotal };
            if (res.variableFeesDiversificationItem) {
              this.companyFeesDiversificationVariableFeesItemArray = res.variableFeesDiversificationItem
            };
            if (res.variableFeesCardCommissionYearly) { this.companyFeesCardCommissionYearly = res.variableFeesCardCommissionYearly };
            if (res.variableFeesCardCommissionRate) { this.companyFeesReceivedForm.get("variableFeesCardCommissionRate").setValue(res.variableFeesCardCommissionRate); this.companyFeesCardCommissionRate = res.variableFeesCardCommissionRate };
            if (res.variableFeesCardCommissionTotal) { this.companyFeesCardCommissionTotal = res.variableFeesCardCommissionTotal };
            if (res.variableFeesTotal) { this.companyFeesVariableFeesTotal = res.variableFeesTotal };
            if (res.total) { this.companyFeesTotal = res.total };

            if (res.yearlyEscalation) {
              if (res.yearlyEscalation.inflationRate) { this.companyFeesReceivedInflationRateArray = res.yearlyEscalation.inflationRate; };
              if (res.yearlyEscalation.inflationFactor) { this.companyFeesReceivedInflationFactorArray = res.yearlyEscalation.inflationFactor; };
              if (res.yearlyEscalation.fuelVolumeGrowthInPotential) { this.companyFeesReceivedFuelVolumeGrowthInPotentialArray = res.yearlyEscalation.fuelVolumeGrowthInPotential; };
              if (res.yearlyEscalation.fuelVolumeGrowthFactor) { this.companyFeesReceivedFuelVolumeGrowthFactorArray = res.yearlyEscalation.fuelVolumeGrowthFactor; };
              if (res.yearlyEscalation.fuelVolumeGrowthFactorWithRampUp) { this.companyFeesReceivedFuelVolumeGrowthFactorWithRampUpArray = res.yearlyEscalation.fuelVolumeGrowthFactorWithRampUp; };
              if (res.yearlyEscalation.variableFeesFuel) { this.companyFeesReceivedVariableFeesFuelArray = res.yearlyEscalation.variableFeesFuel; };
              if (res.yearlyEscalation.variableFeesDiversification) { this.companyFeesReceivedVariableFeesDiversificationArray = res.yearlyEscalation.variableFeesDiversification; };
              if (res.yearlyEscalation.fixedFeesFuel) { this.companyFeesReceivedFixedFeesFuelArray = res.yearlyEscalation.fixedFeesFuel; };
              if (res.yearlyEscalation.fixedFeesDiversification) { this.companyFeesReceivedFixedFeesDiversificationArray = res.yearlyEscalation.fixedFeesDiversification; };
              if (res.yearlyEscalation.variableFeesCardCommission) { this.companyFeesReceivedVariableFeesCardCommissionArray = res.yearlyEscalation.variableFeesCardCommission; };
              if (res.yearlyEscalation.total) {
                this.companyFeesReceivedTotalArray = res.yearlyEscalation.total;
              };
            };
          })
        );

        this.subscription.add(
          this.projectService.getProjectDealerSummaryObject(this.projectId).pipe().subscribe((res: DealerSummary) => {

            if (res) {
              if (res.dealerRevenue) { this.dealerSummaryRevenue = res.dealerRevenue };
              if (res.dealerOperatingExpense) { this.dealerSummaryExpense = res.dealerOperatingExpense };
              if (res.ebitdaBeforeFees) { this.dealerSummaryEbitdaBeforeFees = res.ebitdaBeforeFees };
              if (res.dealerFees) { this.dealerSummaryFees = res.dealerFees };
              if (res.ebitda) { this.dealerSummaryEbitda = res.ebitda };
              if (res.taxableProfit) { this.dealerSummaryTaxableProfit = res.taxableProfit };
              if (res.corporateTaxRate) { this.dealerSummaryTaxRate = res.corporateTaxRate };
              if (res.taxAmount) { this.dealerSummaryTax = res.taxAmount };
              if (res.netCashAfterTaxBeforeDeposit) { this.dealerSummaryNetCashAfterTaxBeforeDeposit = res.netCashAfterTaxBeforeDeposit };
              if (res.depositToBuildWCROrLoanRepayVolume) { this.dealerSummaryDepositToBuildWCROrLoanRepayVolume = res.depositToBuildWCROrLoanRepayVolume };
              if (res.depositToBuildWCROrLoanRepayRate) { this.dealerSummaryDepositToBuildWCROrLoanRepayRate = res.depositToBuildWCROrLoanRepayRate };
              if (res.depositToBuildWCROrLoanRepay) { this.dealerSummaryDepositToBuildWCROrLoanRepay = res.depositToBuildWCROrLoanRepay };
              if (res.depositForSecurityVolume) { this.dealerSummaryDepositForSecurityVolume = res.depositForSecurityVolume };
              if (res.depositForSecurityRate) { this.dealerSummaryDepositForSecurityRate = res.depositForSecurityRate };
              if (res.depositForSecurity) { this.dealerSummaryDepositForSecurity = res.depositForSecurity };
              if (res.netCashAfterTaxAndDeposit) { this.dealerSummaryNetCashAfterTaxAndDeposit = res.netCashAfterTaxAndDeposit };
            }
          }));

        this.subscription.add(
          this.projectService.getProjectCompanyInvestmentObject(this.projectId).pipe().subscribe((res: CompanyInvestment) => {
            if (res) {
              if (res.branding) {
                this.brandingItemArray = res.branding;
              } else {
                this.brandingItemArray = [];
              }
              if (res.construction) {
                this.constructionItemArray = res.construction;
              } else {
                this.constructionItemArray = [];
              }
              if (res.equipment) {
                this.equipmentItemArray = res.equipment;
              } else {
                this.equipmentItemArray = [];
              }

              if (res.brandingTotal) {
                this.companyInvestmentBrandingTotal = res.brandingTotal;
              } else {
                this.companyInvestmentBrandingTotal = 0;
              };
              if (res.constructionTotal) {
                this.companyInvestmentConstructionTotal = res.constructionTotal;
              } else {
                this.companyInvestmentConstructionTotal = 0;
              };
              if (res.equipmentTotal) {
                this.companyInvestmentEquipmentTotal = res.equipmentTotal;
              } else {
                this.companyInvestmentEquipmentTotal = 0;
              };

              if (res.brandingAmortizationPeriod) {
                this.investmentAmortizationForm.get("brandingAmortizationPeriod").setValue(res.brandingAmortizationPeriod);
              } else{
                this.investmentAmortizationForm.get("brandingAmortizationPeriod").setValue(0);
              };
              if (res.constructionAmortizationPeriod) {
                this.investmentAmortizationForm.get("constructionAmortizationPeriod").setValue(res.constructionAmortizationPeriod);
              } else{
                this.investmentAmortizationForm.get("constructionAmortizationPeriod").setValue(0);
              };
              if (res.equipmentAmortizationPeriod) {
                this.investmentAmortizationForm.get("equipmentAmortizationPeriod").setValue(res.equipmentAmortizationPeriod);
              } else{
                this.investmentAmortizationForm.get("equipmentAmortizationPeriod").setValue(0);
              };

              if (res.total) { 
                this.companyInvestmentTotal = res.total; 
              }else {
                this.companyInvestmentTotal = 0;
              }

              if (res.yearlyEscalation) {
                if (res.yearlyEscalation.brandingAmortization) {
                  this.brandingAmortizationArray = res.yearlyEscalation.brandingAmortization;
                };
                if (res.yearlyEscalation.constructionAmortization) {
                  this.constructionAmortizationArray = res.yearlyEscalation.constructionAmortization;
                };
                if (res.yearlyEscalation.equipmentAmortization) {
                  this.equipmentAmortizationArray = res.yearlyEscalation.equipmentAmortization;
                };
                if (res.yearlyEscalation.totalAmortization) {
                  this.totalAmortizationArray = res.yearlyEscalation.totalAmortization;
                };
                if (res.yearlyEscalation.investment) {
                  this.companyInvestmentArray = res.yearlyEscalation.investment;
                };
                if (res.yearlyEscalation.total) {
                  this.companyInvestmentTotalArray = res.yearlyEscalation.total;
                };
              }
            }
          })
        );

      } else {
        this.router.navigate([`/portal/project`]);
      }
    };
  }

  ngOnInit(): void {

    this.salariesAndBonusItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });
    this.employeeContributionItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });
    this.electricityItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });
    this.miscellaneousItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });


    this.brandingItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });

    this.equipmentItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });

    this.constructionItemForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });

    this.investmentAmortizationForm = this.fb.group({
      brandingAmortizationPeriod: [10, Validators.required],
      constructionAmortizationPeriod: [10, Validators.required],
      equipmentAmortizationPeriod: [10, Validators.required],
    });

    this.dealerRevenueNonFuelForm = this.fb.group({
      name: ['', Validators.required],
      yearly: [0, Validators.required],
      rate: [0, Validators.required],
      total: [0, Validators.required],
    });

    this.diversificationVariableFeesForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });

    this.diversificationFixedFeesForm = this.fb.group({
      name: ['', Validators.required],
      total: [0, Validators.required],
    });

    this.investmentItemForm = this.fb.group({
      brandingTotal: [0, Validators.required],
      equipmentTotal: [0, Validators.required],
      constructionTotal: [0, Validators.required],
      total: [0, Validators.required],
    });

    this.companyFeesReceivedForm = this.fb.group({
      fixedFeesFuelDodoDiscountGivenM3: [3200, Validators.required],
      variableFeesFuelRate: [200, Validators.required],
      fuelCostM3: [171000, Validators.required],
      fixedFeesFuelRate: [17, Validators.required],
      // fixedFeesDiversificationItem: [0, Validators.required],
      variableFeesCardCommissionRate: [0.5, Validators.required],
    });

    this.dealerFeesReceivedForm = this.fb.group({
      fixedFeesFuelDodoDiscountGivenM3: [3200, Validators.required],
      variableFeesFuelRate: [200, Validators.required],
      fuelCostM3: [171000, Validators.required],
      fixedFeesFuelRate: [17, Validators.required],
      // fixedFeesDiversificationItem: [0, Validators.required],
      variableFeesCardCommissionRate: [0.5, Validators.required],
    });

    this.subscription.add(
      this.dealerRevenueNonFuelForm.get("rate").valueChanges.subscribe(x => {
        if ((x > 0) && (this.dealerRevenueNonFuelForm.get("yearly").value)) {
          this.dealerRevenueNonFuelForm.get("total").setValue((x / 100) * (this.dealerRevenueNonFuelForm.get("yearly").value));
        }
      }));

    this.subscription.add(
      this.dealerRevenueNonFuelForm.get("yearly").valueChanges.subscribe(x => {
        if ((x > 0) && (this.dealerRevenueNonFuelForm.get("rate").value)) {
          this.dealerRevenueNonFuelForm.get("total").setValue((x) * ((this.dealerRevenueNonFuelForm.get("rate").value) / 100));
        }
      }));


    this.unitsOfMeasureArray = [
      "Imperial",
      "Metric",
    ];

    this.modeOfPartnershipOptionArray = [
      "DODO",
      "COCO",
      "CODO",
    ];

    this.typeOfInvestmentOptionArray = [
      "Creation",
      "Renovation",
      "Maintenance",
    ]

    this.periodOfOperationOptionArray = [
      10,
      15,
      20,
      25,
    ]

    this.productForm = this.fb.group({
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
    });

    this.fuelProductForm = this.fb.group({
      industryName: ['', Validators.required],
      name: ['', Validators.required],
      industryAbbreviation: ['', Validators.required],
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkGlobalPrice: [0, Validators.required],
      networkEPRAMargin: [0, Validators.required],
      networkDODOMargin: [0, Validators.required],
      networkDealerMarginM3: [0, Validators.required],
      networkDealerMarginL: [0, Validators.required],
      networkDiscountGiven: [0, Validators.required],
      networkDepotCost: [0, Validators.required],
      networkGalanaPro: [0, Validators.required],
      networkVarex: [0, Validators.required],
      networkExpectedGMVE: [0, Validators.required],

      unitSupplyPrice: [0, Validators.required],
      unitaryTax: [0, Validators.required],
      unitRevenue: [0, Validators.required],
      companyUnitMargin: [0, Validators.required],

      unitGMVE: [0, Validators.required],
      gMVE: [0, Validators.required],

      monthlyVolume: [0, Validators.required],
      yearlyVolume: [0, Validators.required],
    });

    this.lubricantProductForm = this.fb.group({
      industryName: ['', Validators.required],
      name: ['', Validators.required],
      industryAbbreviation: ['', Validators.required],
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: [0, Validators.required],

      unitSupplyPrice: [0, Validators.required],
      unitaryTax: [0, Validators.required],
      unitRevenue: [0, Validators.required],
      companyUnitMargin: [0, Validators.required],

      unitGMVE: [0, Validators.required],
      gMVE: [0, Validators.required],

      monthlyVolume: [0, Validators.required],
      yearlyVolume: [0, Validators.required],
    });

    this.gasProductForm = this.fb.group({
      industryName: ['', Validators.required],
      name: ['', Validators.required],
      industryAbbreviation: ['', Validators.required],
      productId: ['', Validators.required],
      productCategory: ['', Validators.required],
      networkDealerMarginM3: [0, Validators.required],

      unitSupplyPrice: [0, Validators.required],
      unitaryTax: [0, Validators.required],
      unitRevenue: [0, Validators.required],
      companyUnitMargin: [0, Validators.required],

      unitGMVE: [0, Validators.required],
      gMVE: [0, Validators.required],

      monthlyVolume: [0, Validators.required],
      yearlyVolume: [0, Validators.required],
    });

    this.workingCapitalForm = this.fb.group({
      whiteProductSupplierCreditDays: [0, Validators.required],
      whiteProductStockLogisticsDays: [0, Validators.required],
      lubricantSupplierCreditDays: [0, Validators.required],
      lubricantStockLogisticsDays: [0, Validators.required],
    });

    this.taxesForm = this.fb.group({
      rate: [0, Validators.required],
    });

    this.financingForm = this.fb.group({
      loanAmount: [15000000, Validators.required],
      loanYears: [60, Validators.required],
      interestRate: [13, Validators.required],
      investmentPercentageFinancedByLoan: [50, Validators.required],
      investmentInterestRate: [2.7, Validators.required],
    });

    this.loanToDealerForm = this.fb.group({
      amount: [0, Validators.required],
      years: [0, Validators.required],
      interest: [0, Validators.required],
    });

    this.projectForm = this.fb.group({
      name: ['', Validators.required],
      periodOfOperation: ['', Validators.required],
      monthsBeforeOperation: ['', Validators.required],
      typeOfInvestment: ['', Validators.required],
      mode: ['', Validators.required],
    });

    this.projectSettingsEditForm = this.fb.group({
      id: ['TFD0126', Validators.required],
      name: ['', Validators.required],
      usdFxRate: [0.68, Validators.required],
      taxCarryOverPeriod: [5, Validators.required],
      discountRateForNetPresentValue: ['', Validators.required],
      typeOfInvestment: ['', Validators.required],
      monthsBeforeOperation: [6, Validators.required],
      periodOfOperation: [15, Validators.required],
      mode: ['', Validators.required],
      corporateTaxRate: [30, Validators.required],
    });

    this.companyOperatingExpensesForm = this.fb.group({
      operatingExpensesFromInvestments: [0, Validators.required],
      directFixedCosts: [0, Validators.required],
      networkChannelFixedCostsAllStations: [0, Validators.required],
      networkChannelNumberOfStations: [1, Validators.required],
      networkChannelFixedCostsPerStation: [0, Validators.required],
      overheadCosts: [0, Validators.required],
    });

    this.financingGrowthForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [4, Validators.required],
      y6: [4, Validators.required],
      y7: [4, Validators.required],
      y8: [4, Validators.required],
      y9: [4, Validators.required],
      y10: [4, Validators.required],
      y11: [4, Validators.required],
      y12: [4, Validators.required],
      y13: [4, Validators.required],
      y14: [4, Validators.required],
      y15: [4, Validators.required],
    });

    this.fuelVolumeGrowthForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [4, Validators.required],
      y6: [4, Validators.required],
      y7: [4, Validators.required],
      y8: [4, Validators.required],
      y9: [4, Validators.required],
      y10: [4, Validators.required],
      y11: [4, Validators.required],
      y12: [4, Validators.required],
      y13: [4, Validators.required],
      y14: [4, Validators.required],
      y15: [4, Validators.required],
    });

    this.fuelVolumeGrowthPotentialForm = this.fb.group({
      y0: [0],
      y1: [80],
      y2: [90],
      y3: [100],
      y4: [100],
      y5: [100],
      y6: [100],
      y7: [100],
      y8: [100],
      y9: [100],
      y10: [100],
      y11: [100],
      y12: [100],
      y13: [100],
      y14: [100],
      y15: [100],
    });

    this.monthsDuringThePeriodForm = this.fb.group({
      y0: [3],
      y1: [12, Validators.required],
      y2: [12, Validators.required],
      y3: [12, Validators.required],
      y4: [12, Validators.required],
      y5: [12, Validators.required],
      y6: [12, Validators.required],
      y7: [12, Validators.required],
      y8: [12, Validators.required],
      y9: [12, Validators.required],
      y10: [12, Validators.required],
      y11: [12, Validators.required],
      y12: [12, Validators.required],
      y13: [12, Validators.required],
      y14: [12, Validators.required],
      y15: [12, Validators.required],
    });

    this.fuelMarginGrowthForm = this.fb.group({
      y0: [0],
      y1: [2.5910931174089, Validators.required],
      y2: [2.88082083662193, Validators.required],
      y3: [11.3732259301879, Validators.required],
      y4: [0.473566385396929, Validators.required],
      y5: [1.42257262833148, Validators.required],
      y6: [1.49556400506972, Validators.required],
      y7: [2.47252747252746, Validators.required],
      y8: [2.47252747252746, Validators.required],
      y9: [2.47252747252746, Validators.required],
      y10: [2.47252747252746, Validators.required],
      y11: [2.47252747252746, Validators.required],
      y12: [2.47252747252746, Validators.required],
      y13: [2.47252747252746, Validators.required],
      y14: [2.47252747252746, Validators.required],
      y15: [2.47252747252746, Validators.required],
    });

    this.fuelVolumeForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.fuelMarginForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.gasMarginForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.fuelMarginTotalForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.financingInterestPaidForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.financingInterestReceivedForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.financingInterestRateForFinancingForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.financingTotalForm = this.fb.group({
      y0: [0],
      y1: [0, Validators.required],
      y2: [0, Validators.required],
      y3: [0, Validators.required],
      y4: [0, Validators.required],
      y5: [0, Validators.required],
      y6: [0, Validators.required],
      y7: [0, Validators.required],
      y8: [0, Validators.required],
      y9: [0, Validators.required],
      y10: [0, Validators.required],
      y11: [0, Validators.required],
      y12: [0, Validators.required],
      y13: [0, Validators.required],
      y14: [0, Validators.required],
      y15: [0, Validators.required],
    });

    this.subscription.add(
      this.fuelVolumeForm.get("y1").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y1").value > 0) && (this.fuelMarginForm.get("y1").value > 0)) {
          this.fuelMarginTotalForm.get("y1").setValue(((Number(this.fuelVolumeForm.get("y1").value)) * (Number(this.fuelMarginForm.get("y1").value))) + (Number(this.gasMarginForm.get("y1").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y2").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y2").value > 0) && (this.fuelMarginForm.get("y2").value > 0)) {
          this.fuelMarginTotalForm.get("y2").setValue(((Number(this.fuelVolumeForm.get("y2").value)) * (Number(this.fuelMarginForm.get("y2").value))) + (Number(this.gasMarginForm.get("y2").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y3").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y3").value > 0) && (this.fuelMarginForm.get("y3").value > 0)) {
          this.fuelMarginTotalForm.get("y3").setValue(((Number(this.fuelVolumeForm.get("y3").value)) * (Number(this.fuelMarginForm.get("y3").value))) + (Number(this.gasMarginForm.get("y3").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y4").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y4").value > 0) && (this.fuelMarginForm.get("y4").value > 0)) {
          this.fuelMarginTotalForm.get("y4").setValue(((Number(this.fuelVolumeForm.get("y4").value)) * (Number(this.fuelMarginForm.get("y4").value))) + (Number(this.gasMarginForm.get("y4").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y5").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y5").value > 0) && (this.fuelMarginForm.get("y5").value > 0)) {
          this.fuelMarginTotalForm.get("y5").setValue(((Number(this.fuelVolumeForm.get("y5").value)) * (Number(this.fuelMarginForm.get("y5").value))) + (Number(this.gasMarginForm.get("y5").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y6").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y6").value > 0) && (this.fuelMarginForm.get("y6").value > 0)) {
          this.fuelMarginTotalForm.get("y6").setValue(((Number(this.fuelVolumeForm.get("y6").value)) * (Number(this.fuelMarginForm.get("y6").value))) + (Number(this.gasMarginForm.get("y6").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y7").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y7").value > 0) && (this.fuelMarginForm.get("y7").value > 0)) {
          this.fuelMarginTotalForm.get("y7").setValue(((Number(this.fuelVolumeForm.get("y7").value)) * (Number(this.fuelMarginForm.get("y7").value))) + (Number(this.gasMarginForm.get("y7").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y8").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y8").value > 0) && (this.fuelMarginForm.get("y8").value > 0)) {
          this.fuelMarginTotalForm.get("y8").setValue(((Number(this.fuelVolumeForm.get("y8").value)) * (Number(this.fuelMarginForm.get("y8").value))) + (Number(this.gasMarginForm.get("y8").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y9").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y9").value > 0) && (this.fuelMarginForm.get("y9").value > 0)) {
          this.fuelMarginTotalForm.get("y9").setValue(((Number(this.fuelVolumeForm.get("y9").value)) * (Number(this.fuelMarginForm.get("y9").value))) + (Number(this.gasMarginForm.get("y9").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y10").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y10").value > 0) && (this.fuelMarginForm.get("y10").value > 0)) {
          this.fuelMarginTotalForm.get("y10").setValue(((Number(this.fuelVolumeForm.get("y10").value)) * (Number(this.fuelMarginForm.get("y10").value))) + (Number(this.gasMarginForm.get("y10").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y11").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y11").value > 0) && (this.fuelMarginForm.get("y11").value > 0)) {
          this.fuelMarginTotalForm.get("y11").setValue(((Number(this.fuelVolumeForm.get("y11").value)) * (Number(this.fuelMarginForm.get("y11").value))) + (Number(this.gasMarginForm.get("y11").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y12").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y12").value > 0) && (this.fuelMarginForm.get("y12").value > 0)) {
          this.fuelMarginTotalForm.get("y12").setValue(((Number(this.fuelVolumeForm.get("y12").value)) * (Number(this.fuelMarginForm.get("y12").value))) + (Number(this.gasMarginForm.get("y12").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y13").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y13").value > 0) && (this.fuelMarginForm.get("y13").value > 0)) {
          this.fuelMarginTotalForm.get("y13").setValue(((Number(this.fuelVolumeForm.get("y13").value)) * (Number(this.fuelMarginForm.get("y13").value))) + (Number(this.gasMarginForm.get("y13").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y14").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y14").value > 0) && (this.fuelMarginForm.get("y14").value > 0)) {
          this.fuelMarginTotalForm.get("y14").setValue(((Number(this.fuelVolumeForm.get("y14").value)) * (Number(this.fuelMarginForm.get("y14").value))) + (Number(this.gasMarginForm.get("y14").value)));
        }
      })
    );
    this.subscription.add(
      this.fuelVolumeForm.get("y15").valueChanges.subscribe(x => {
        if ((this.fuelVolumeForm.get("y15").value > 0) && (this.fuelMarginForm.get("y15").value > 0)) {
          this.fuelMarginTotalForm.get("y15").setValue(((Number(this.fuelVolumeForm.get("y15").value)) * (Number(this.fuelMarginForm.get("y15").value))) + (Number(this.gasMarginForm.get("y15").value)));
        }
      })
    );

    this.subscription.add(
      this.projectForm.get("name").valueChanges.subscribe(x => {

        if (this.projectId && x && (this.projectForm.get("name").dirty)) {

          // Replace Chosen Country
          this.projectService.updateProjectCountry(this.projectId, x).pipe(first()).subscribe((result) => {

            this.formSubmitted = true;
            this.loading = false;

          }, (error: string) => {
            this.error = error;
            this.loading = false;
          }

          )
        }
      }

      ));

    this.subscription.add(
      this.projectForm.get("periodOfOperation").valueChanges.subscribe(x => {

      }

      ));

    this.subscription.add(
      this.projectForm.get("monthsBeforeOperation").valueChanges.subscribe(x => {

      }

      ));

    this.subscription.add(
      this.projectForm.get("typeOfInvestment").valueChanges.subscribe(x => {

      }

      ));

    this.subscription.add(
      this.projectForm.get("mode").valueChanges.subscribe(x => {

      }

      ));

    this.subscription.add(
      this.productForm.get("productId").valueChanges.subscribe(x => {

        if (this.projectId && x && (this.productForm.get("productId").dirty)) {

          if (this.petroleumProductArray) {

            this.petroleumProductArray.forEach(element => {
              if (element[0].id === x) {
                if (element[0].petroleumProductCategory_name) {
                  this.showPetroleumProductForm = true;
                  this.pickedPetroleumProductCategory = element[0].petroleumProductCategory_name;
                  this.productForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);

                  if (element[0].petroleumProductCategory_name === "Fuel") {
                    this.fuelProductForm.get("productId").setValue(x);
                    this.fuelProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Lubricant") {
                    this.lubricantProductForm.get("productId").setValue(x);
                    this.lubricantProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  if (element[0].petroleumProductCategory_name === "Gas") {
                    this.gasProductForm.get("productId").setValue(x);
                    this.gasProductForm.get("productCategory").setValue(element[0].petroleumProductCategory_name);
                  }

                  // Load Product
                  if (element[0].name) {
                    this.loadProductFormData(element[0].petroleumProductCategory_name, element[0].name);
                  }

                }
              }
            });
          };
        }
      }));

    this.subscription.add(
      this.fuelProductForm.get("networkDODOMargin").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDODOMargin").dirty) && !(this.fuelProductForm.get("networkDODOMargin").pristine)) {
          // this.calculateFuelMarginFormItems();

          this.fuelProductForm.get("networkDiscountGiven").setValue(Number((Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number((Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGlobalPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGlobalPrice").dirty) && !(this.fuelProductForm.get("networkGlobalPrice").pristine)) {
          this.fuelProductForm.get("networkDealerMarginL").setValue(Number((Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value)).toFixed(6)));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number((Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDealerMarginL").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDealerMarginL").dirty) && !(this.fuelProductForm.get("networkDealerMarginL").pristine)) {
          this.fuelProductForm.get("networkDiscountGiven").setValue(Number((Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
          this.fuelProductForm.get("networkEPRAMargin").setValue(Number((Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkDepotCost").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkDepotCost").dirty) && !(this.fuelProductForm.get("networkDepotCost").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkGalanaPro").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkGalanaPro").dirty) && !(this.fuelProductForm.get("networkGalanaPro").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("networkVarex").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("networkVarex").dirty) && !(this.fuelProductForm.get("networkVarex").pristine)) {
          this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
          this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
          this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitSupplyPrice").dirty) && !(this.fuelProductForm.get("unitSupplyPrice").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("unitaryTax").dirty) && !(this.fuelProductForm.get("unitaryTax").pristine)) {
          this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    this.subscription.add(
      this.fuelProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.fuelProductForm.get("monthlyVolume").dirty) && !(this.fuelProductForm.get("monthlyVolume").pristine)) {
          this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
          this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
        }
      })
    )

    // Lubricant
    this.subscription.add(
      this.lubricantProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitGMVE").dirty) && !(this.lubricantProductForm.get("unitGMVE").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitaryTax").dirty) && !(this.lubricantProductForm.get("unitaryTax").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("monthlyVolume").dirty) && !(this.lubricantProductForm.get("monthlyVolume").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    this.subscription.add(
      this.lubricantProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.lubricantProductForm.get("unitSupplyPrice").dirty) && !(this.lubricantProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateLubricantMarginFormItems();
        }
      }));

    // Gas
    this.subscription.add(
      this.gasProductForm.get("unitGMVE").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitGMVE").dirty) && !(this.gasProductForm.get("unitGMVE").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitaryTax").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitaryTax").dirty) && !(this.gasProductForm.get("unitaryTax").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("monthlyVolume").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("monthlyVolume").dirty) && !(this.gasProductForm.get("monthlyVolume").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.subscription.add(
      this.gasProductForm.get("unitSupplyPrice").valueChanges.subscribe(x => {
        if ((this.gasProductForm.get("unitSupplyPrice").dirty) && !(this.gasProductForm.get("unitSupplyPrice").pristine)) {
          this.calculateGasMarginFormItems();
        }
      }));

    this.getCountryArray();

  }

  calculateLubricantMarginFormItems() {
    this.lubricantProductForm.get("companyUnitMargin").setValue(Number((Number(this.lubricantProductForm.get("unitGMVE").value)).toFixed(6)));
    this.lubricantProductForm.get("unitRevenue").setValue(Number((Number(this.lubricantProductForm.get("unitaryTax").value) + Number(this.lubricantProductForm.get("unitSupplyPrice").value) + Number(this.lubricantProductForm.get("companyUnitMargin").value)).toFixed(6)));
    this.lubricantProductForm.get("yearlyVolume").setValue(Number((Number(this.lubricantProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
    this.lubricantProductForm.get("gMVE").setValue(Number((Number(this.lubricantProductForm.get("unitRevenue").value) * Number(this.lubricantProductForm.get("yearlyVolume").value)).toFixed(6)));
  }

  calculateGasMarginFormItems() {
    this.gasProductForm.get("companyUnitMargin").setValue(Number((Number(this.gasProductForm.get("unitGMVE").value).toFixed(6))));
    this.gasProductForm.get("unitRevenue").setValue(Number((Number(this.gasProductForm.get("unitaryTax").value) + Number(this.gasProductForm.get("unitSupplyPrice").value) + Number(this.gasProductForm.get("companyUnitMargin").value)).toFixed(6)));
    this.gasProductForm.get("yearlyVolume").setValue(Number((Number(this.gasProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
    this.gasProductForm.get("gMVE").setValue(Number((Number(this.gasProductForm.get("unitRevenue").value) * Number(this.gasProductForm.get("yearlyVolume").value)).toFixed(6)));
  }

  calculateFuelMarginFormItems() {
    this.fuelProductForm.get("networkDiscountGiven").setValue(Number((Number(this.fuelProductForm.get("networkDODOMargin").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
    this.fuelProductForm.get("networkEPRAMargin").setValue(Number((Number(this.fuelProductForm.get("networkGlobalPrice").value) - Number(this.fuelProductForm.get("networkDealerMarginL").value)).toFixed(6)));
    this.fuelProductForm.get("networkExpectedGMVE").setValue(Number((Number(this.fuelProductForm.get("networkEPRAMargin").value) - Number(this.fuelProductForm.get("networkDiscountGiven").value) - Number(this.fuelProductForm.get("networkDepotCost").value) - Number(this.fuelProductForm.get("networkGalanaPro").value) - Number(this.fuelProductForm.get("networkVarex").value)).toFixed(6)));
    this.fuelProductForm.get("unitGMVE").setValue(Number((this.fuelProductForm.get("networkExpectedGMVE").value).toFixed(6)));
    this.fuelProductForm.get("companyUnitMargin").setValue(Number(((Number(this.fuelProductForm.get("networkExpectedGMVE").value)) * 1000).toFixed(6)));
    this.fuelProductForm.get("unitRevenue").setValue(Number(((Number(this.fuelProductForm.get("companyUnitMargin").value)) + (Number(this.fuelProductForm.get("unitSupplyPrice").value)) + (Number(this.fuelProductForm.get("unitaryTax").value))).toFixed(6)));
    this.fuelProductForm.get("yearlyVolume").setValue(Number((Number(this.fuelProductForm.get("monthlyVolume").value) * 12).toFixed(6)));
    this.fuelProductForm.get("gMVE").setValue(Number((Number(this.fuelProductForm.get("unitRevenue").value) * Number(this.fuelProductForm.get("yearlyVolume").value)).toFixed(6)));
  }


  /**
   * On form submit
   */
  saveInvestmentItems(
    investmentAmortizationForm: any,
  ): void {
    this.subscription.add(
      this.projectService.updateProjectCompanyInvestment(
        this.projectId,
        investmentAmortizationForm.brandingAmortizationPeriod,
        investmentAmortizationForm.constructionAmortizationPeriod,
        investmentAmortizationForm.equipmentAmortizationPeriod,
      )
        .pipe(first()).subscribe((result) => {

          this.showProjectInvestmentEdit = false;

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));

  }

  populateInvestmentData() {
    // Read Investment Data
    this.subscription.add(
      this.projectService.getProjectCompanyInvestmentArray(this.projectId).pipe(first()).subscribe((result: any) => {

        this.investmentItemArray = [];
        this.totalInvestmentCost = 0;
        this.investmentItemArray = result;

        for (const p in this.investmentItemArray) {
          this.investmentItemArray.push(this.investmentItemArray[p]);

          this.totalInvestmentCost += this.investmentItemArray[p].totalCost;
        }

        this.investmentItemArray.sort((a, b) => {
          const nameA = a.category.toUpperCase(); // ignore upper and lowercase
          const nameB = b.category.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        this.investmentItemArray = [...new Set(this.investmentItemArray)];

        this.formSubmitted = true;
        this.loading = false;

      }, (error: string) => {
        this.error = error;
        this.loading = false;
      }

      )
    );

  }

  getCountryArray() {
    this.subscription.add(
      this.petroleumProductsService.getCountryArray().pipe().subscribe((res: Country[]) => {
        if (res) {
          this.countryArray = res;
        }
      })
    );
  };

  loadProductFormData(productCategory: string, productName: string) {

    if (productCategory === "Fuel") {
      // Get Fuel Data
      if (this.projectArray) {
        if (this.projectArray[0]) {
          if (this.projectArray[0].fuelProduct) {
            let load: any = this.projectArray[0].fuelProduct;
            load.forEach((element: FuelProduct) => {
              if (element.name === productName) {
                this.productExists_Update = true;
                if (element.id) { this.fuelProductForm.get("productId").setValue(element.id); };
                if (element.networkGlobalPrice) { this.fuelProductForm.get("networkGlobalPrice").setValue(element.networkGlobalPrice); };
                if (element.networkEPRAMargin) { this.fuelProductForm.get("networkEPRAMargin").setValue(element.networkEPRAMargin); };
                if (element.networkDODOMargin) { this.fuelProductForm.get("networkDODOMargin").setValue(element.networkDODOMargin); };
                if (element.networkDealerMarginL) { this.fuelProductForm.get("networkDealerMarginL").setValue(element.networkDealerMarginL); };
                if (element.networkDiscountGiven) { this.fuelProductForm.get("networkDiscountGiven").setValue(element.networkDiscountGiven); };
                if (element.networkDepotCost) { this.fuelProductForm.get("networkDepotCost").setValue(element.networkDepotCost); };
                if (element.networkGalanaPro) { this.fuelProductForm.get("networkGalanaPro").setValue(element.networkGalanaPro); };
                if (element.networkVarex) { this.fuelProductForm.get("networkVarex").setValue(element.networkVarex); };
                if (element.networkExpectedGMVE) { this.fuelProductForm.get("networkExpectedGMVE").setValue(element.networkExpectedGMVE); };

                if (element.unitSupplyPrice) { this.fuelProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
                if (element.unitaryTax) { this.fuelProductForm.get("unitaryTax").setValue(element.unitaryTax); };
                if (element.unitRevenue) { this.fuelProductForm.get("unitRevenue").setValue(element.unitRevenue); };
                if (element.companyUnitMargin) { this.fuelProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
                if (element.monthlyVolume) { this.fuelProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
                if (element.yearlyVolume) { this.fuelProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
                if (element.unitGMVE) { this.fuelProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
                if (element.gMVE) { this.fuelProductForm.get("gMVE").setValue(element.gMVE); }
              }
            });

          }

        }
      }
    }

    if (productCategory === "Lubricant") {
      // Get Fuel Data
      if (this.projectArray) {
        if (this.projectArray[0]) {
          if (this.projectArray[0].lubricantProduct) {
            let load: any = this.projectArray[0].lubricantProduct;

            load.forEach((element: LubricantProduct) => {
              if (element.name === productName) {
                this.productExists_Update = true;
                if (element.id) { this.lubricantProductForm.get("productId").setValue(element.id); };
                if (element.networkDealerMarginM3) { this.lubricantProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
                if (element.unitSupplyPrice) { this.lubricantProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
                if (element.unitaryTax) { this.lubricantProductForm.get("unitaryTax").setValue(element.unitaryTax); };
                if (element.unitRevenue) { this.lubricantProductForm.get("unitRevenue").setValue(element.unitRevenue); };
                if (element.companyUnitMargin) { this.lubricantProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
                if (element.unitGMVE) { this.lubricantProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
                if (element.gMVE) { this.lubricantProductForm.get("gMVE").setValue(element.gMVE); };
                if (element.monthlyVolume) { this.lubricantProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
                if (element.yearlyVolume) { this.lubricantProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
              }
            });

          }
        }
      }
    }

    if (productCategory === "Gas") {
      // Get Fuel Data
      if (this.projectArray) {
        if (this.projectArray[0]) {
          if (this.projectArray[0].gasProduct) {
            let load: any = this.projectArray[0].gasProduct;

            load.forEach((element: GasProduct) => {
              if (element.name === productName) {
                this.productExists_Update = true;
                if (element.id) { this.gasProductForm.get("productId").setValue(element.id); };
                if (element.networkDealerMarginM3) { this.gasProductForm.get("networkDealerMarginM3").setValue(element.networkDealerMarginM3); };
                if (element.unitSupplyPrice) { this.gasProductForm.get("unitSupplyPrice").setValue(element.unitSupplyPrice); };
                if (element.unitaryTax) { this.gasProductForm.get("unitaryTax").setValue(element.unitaryTax); };
                if (element.unitRevenue) { this.gasProductForm.get("unitRevenue").setValue(element.unitRevenue); };
                if (element.companyUnitMargin) { this.gasProductForm.get("companyUnitMargin").setValue(element.companyUnitMargin); };
                if (element.unitGMVE) { this.gasProductForm.get("unitGMVE").setValue(element.unitGMVE ? element.unitGMVE : 0); };
                if (element.gMVE) { this.gasProductForm.get("gMVE").setValue(element.gMVE); };
                if (element.monthlyVolume) { this.gasProductForm.get("monthlyVolume").setValue(element.monthlyVolume); };
                if (element.yearlyVolume) { this.gasProductForm.get("yearlyVolume").setValue(element.yearlyVolume); };
              }
            });

          }
        }
      }
    }

    // Load Fuel Form


  }

  /**
   * convenience getter for easy access to form fields
   */
  get projectFormValues() {
    return this.projectForm.controls;
  }

  get projectSettingsEditFormValues() {
    return this.projectSettingsEditForm.controls;
  }

  get productFormValues() {
    return this.productForm.controls;
  }
  get gasProductFormValues() {
    return this.gasProductForm.controls;
  }
  get lubricantProductFormValues() {
    return this.lubricantProductForm.controls;
  }
  get fuelProductFormValues() {
    return this.fuelProductForm.controls;
  }

  get workingCapitalFormValues() {
    return this.workingCapitalForm.controls;
  }

  get companyOperatingExpensesFormValues() {
    return this.companyOperatingExpensesForm.controls;
  }

  get taxesFormValues() {
    return this.taxesForm.controls;
  }

  get financingFormValues() {
    return this.financingForm.controls;
  }

  get loanToDealerFormValues() {
    return this.loanToDealerForm.controls;
  }

  get companyFeesReceivedFormValues() {
    return this.companyFeesReceivedForm.controls;
  }

  get dealerFeesReceivedFormValues() {
    return this.dealerFeesReceivedForm.controls;
  }

  get investmentAmortizationFormValues() {
    return this.investmentAmortizationForm.controls;
  }

  hideProductMenu() {
    this.showPetroleumProductForm = false;
  }

  edit(itemName: string) {


  }

  showProjectFuelVolumeGrowth() {
    this.showProjectFuelVolumeGrowthEdit = true;
  }

  saveFuelVolumeGrowth(
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
    monthsDuringThePeriodForm: any,
    fuelMarginGrowthForm: any,
    fuelVolumeWithoutLubricantForm: any,
    fuelVolumeForm: any,
    fuelMarginForm: any,
    gasMarginForm: any,
    fuelMarginTotalForm: any,
  ) {

    this.subscription.add(
      this.projectService.updateFuelVolumeGrowth(
        this.projectId,
        fuelVolumeGrowthForm,
        fuelVolumeGrowthPotentialForm,
        monthsDuringThePeriodForm,
        fuelMarginGrowthForm,
        fuelVolumeWithoutLubricantForm,
        fuelVolumeForm,
        fuelMarginForm,
        gasMarginForm,
        fuelMarginTotalForm,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.formSubmitted = true;
          this.loading = false;
          this.showProjectFuelVolumeGrowthEdit = false;
          // Clear form

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }

  addProduct(load: any) {

    if (load.productCategory === 'Fuel') {
      this.updateInProgress = true;

      // Checks
      if (this.fuelProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGlobalPrice").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network global price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network global price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDODOMargin").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network DODO margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network DODO margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDealerMarginL").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDiscountGiven").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network discount given!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network discount given!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkDepotCost").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network depot cost!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network depot cost!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkGalanaPro").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network galana pro!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network galana pro!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("networkVarex").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network varex!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network varex!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      // Checks
      if (this.fuelProductForm.get("networkExpectedGMVE").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network expected GMVE!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network expected GMVE!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.fuelProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      this.subscription.add(
        this.projectService.updateProjectPetroleumProduct(this.projectId, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.toastr.success(`${load.productCategory} has been updated!`, "Success!");
            this.formSubmitted = true;
            this.updateInProgress = false;

            // Clear form
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.toastr.error(`Update Error: ${error}!`, "Error!");
              this.formSubmitted = true;
              this.updateInProgress = false;
              this.error = error;
            }));
    };

    if (load.productCategory === 'Lubricant') {
      this.updateInProgress = true;

      // Checks
      if (this.lubricantProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.lubricantProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      this.subscription.add(
        this.projectService.updateProjectPetroleumProduct(this.projectId, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.toastr.success(`${load.productCategory} has been updated!`, "Success!");
            this.formSubmitted = true;
            this.updateInProgress = false;

            // Clear form
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.toastr.error(`Update Error: ${error}!`, "Error!");
              this.formSubmitted = true;
              this.updateInProgress = false;
              this.error = error;
            }));

    };

    if (load.productCategory === 'Gas') {
      this.updateInProgress = true;

      // Checks
      if (this.gasProductForm.get("productId").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("networkDealerMarginM3").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product network dealer margin!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product network dealer margin!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitSupplyPrice").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unit supply price!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unit supply price!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }

      // Checks
      if (this.gasProductForm.get("unitaryTax").invalid) {
        this.show_alert = true;
        this.updateInProgress = false;
        this.alert_message = "Provide the product unitary tax!";
        this.alert_type = "danger";

        this.toastr.error('Provide the product unitary tax!', 'Error!');

        setTimeout(() => {
          this.show_alert = false;
          this.updateInProgress = false;
          this.selfClosingAlert.close();
        }, 3000);
        return;
      }


      this.subscription.add(
        this.projectService.updateProjectPetroleumProduct(this.projectId, load)
          .pipe(first()).subscribe((result) => {
            // navigates to confirm mail screen
            // this.router.navigate(['/account/confirm-email']);
            this.formSubmitted = true;
            this.toastr.success(`${load.productCategory} has been updated!`, "Success!");
            this.updateInProgress = false;

            // Clear form
            this.showPetroleumProductForm = false;
            this.productForm.reset();
            this.fuelProductForm.reset();
            this.lubricantProductForm.reset();
            this.gasProductForm.reset();

          },
            (error: string) => {
              this.toastr.error(`Update Error: ${error}!`, "Error!");
              this.formSubmitted = true;
              this.updateInProgress = false;
              this.error = error;
            }));

    };

  }

  saveProductMarginNegotiationItems(productMarginNegotiationItemForm: any) {

  }

  saveFuelVolumeGrowthItems() {

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  loadingAnimationStart() {
    this.loading = true;
  }

  loadingAnimationStop() {
    this.loading = false;
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  updateProjectOperatingExpenses(companyOperatingExpensesForm: any) {
    this.updateInProgress = true;

    // Checks
    if (this.companyOperatingExpensesForm.get("networkChannelFixedCostsAllStations").invalid) {
      this.show_alert = true;
      this.alert_message = "Network Channel Fixed Costs All Stations is missing!";
      this.alert_type = "danger";

      this.toastr.error('Network Channel Fixed Costs All Stations is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyOperatingExpensesForm.get("networkChannelNumberOfStations").invalid) {
      this.show_alert = true;
      this.alert_message = "Network Channel Number Of Stations is missing!";
      this.alert_type = "danger";

      this.toastr.error('Network Channel Number Of Stations is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyOperatingExpensesForm.get("operatingExpensesFromInvestments").invalid) {
      this.show_alert = true;
      this.alert_message = "Operating Expenses From Investments is missing!";
      this.alert_type = "danger";

      this.toastr.error('Operating Expenses From Investments is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    if (this.projectId) {
      this.subscription.add(
        this.projectService.updateProjectCompanyOperatingExpenses(
          this.projectId,
          companyOperatingExpensesForm,
        )
          .pipe(first()).subscribe((result) => {
            this.updateInProgress = false;
            this.showProjectSettingsEdit = false;
            this.toastr.success(`Company - Operating Expenses - Updated Successfully`, "Info!");
          },
            (error: string) => {
              this.toastr.error(`Company - Operating Expenses - Update Error: ${error}`, "Error!");
              this.updateInProgress = false;
              this.error = error;
            })
      );
    }

  };

  updateProjectOtherRevenue(companyFeesReceivedForm: any) {
    this.updateInProgress = true;

    // Checks
    if (this.companyFeesReceivedForm.get("fixedFeesFuelDodoDiscountGivenM3").invalid) {
      this.show_alert = true;
      this.alert_message = "Fixed Fees Fuel - Dodo Discount Given is missing!";
      this.alert_type = "danger";

      this.toastr.error('Fixed Fees Fuel - Dodo Discount Given is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyFeesReceivedForm.get("variableFeesFuelRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Variable Fees - Fuel Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Variable Fees - Fuel Rate is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyFeesReceivedForm.get("fuelCostM3").invalid) {
      this.show_alert = true;
      this.alert_message = "Fuel - Cost is missing!";
      this.alert_type = "danger";

      this.toastr.error('Fuel - Cost is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyFeesReceivedForm.get("fixedFeesFuelRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Fixed Fees Fuel - Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Fixed Fees Fuel - Rate is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.companyFeesReceivedForm.get("variableFeesCardCommissionRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Variable Fees - Card Commission Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Variable Fees - Card Commission Rate is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    if (this.projectId) {
      this.subscription.add(
        this.projectService.updateProjectCompanyOtherRevenue(
          this.projectId,
          companyFeesReceivedForm,
        )
          .pipe(first()).subscribe((result) => {
            this.updateInProgress = false;
            this.showProjectSettingsEdit = false;
            this.toastr.success(`Company - Other Revenue - Updated Successfully`, "Info!");
          },
            (error: string) => {
              this.toastr.error(`Company - Other Revenue - Update Error: ${error}`, "Error!");
              this.updateInProgress = false;
              this.error = error;
            })
      );
    }

  };

  updateProjectSettings(projectSettingsEditForm: any) {
    this.updateInProgress = true;

    // Checks
    if (this.projectSettingsEditForm.get("name").invalid) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("id").invalid) {
      this.show_alert = true;
      this.alert_message = "Country name missing!";
      this.alert_type = "danger";

      this.toastr.error('Country name missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("usdFxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "USD Fx Rate missing!";
      this.alert_type = "danger";

      this.toastr.error('USD Fx Rate missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("taxCarryOverPeriod").invalid) {
      this.show_alert = true;
      this.alert_message = "Tax carry over period missing!";
      this.alert_type = "danger";

      this.toastr.error('Tax carry over period missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("discountRateForNetPresentValue").invalid) {
      this.show_alert = true;
      this.alert_message = "Discount rate for net present value missing!";
      this.alert_type = "danger";

      this.toastr.error('Discount rate for net present value missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("typeOfInvestment").invalid) {
      this.show_alert = true;
      this.alert_message = "Type of investment missing!";
      this.alert_type = "danger";

      this.toastr.error('Type of investment missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("monthsBeforeOperation").invalid) {
      this.show_alert = true;
      this.alert_message = "Months before operation missing!";
      this.alert_type = "danger";

      this.toastr.error('Months before operation missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("periodOfOperation").invalid) {
      this.show_alert = true;
      this.alert_message = "Period of operation missing!";
      this.alert_type = "danger";

      this.toastr.error('Period of operation missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("mode").invalid) {
      this.show_alert = true;
      this.alert_message = "Operation mode missing!";
      this.alert_type = "danger";

      this.toastr.error('Operation mode missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Checks
    if (this.projectSettingsEditForm.get("corporateTaxRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Corporate tax rate missing!";
      this.alert_type = "danger";

      this.toastr.error('Corporate tax rate missing!', 'Error!');
      this.updateInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    if (this.projectId) {
      this.subscription.add(
        this.projectService.updateProjectSettings(
          this.projectId,
          projectSettingsEditForm,
        )
          .pipe(first()).subscribe((result) => {
            this.updateInProgress = false;
            this.showProjectSettingsEdit = false;
            this.toastr.success(`Step 1. Project Parameters - Updated Successfully`, "Info!");
          },
            (error: string) => {
              this.toastr.error(`Step 1. Project Parameters - Update error: ${error}`, "Error!");
              this.updateInProgress = false;
              this.error = error;
            })
      );
    }

  }

  updateProjectProductMargin() {

  }

  updateProjectFuelVolumeGrowth(
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
    monthsDuringThePeriodForm: any,
    fuelMarginGrowthForm: any
  ) {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectFuelVolumeGrowth(
        fuelVolumeGrowthForm,
        fuelVolumeGrowthPotentialForm,
        monthsDuringThePeriodForm,
        fuelMarginGrowthForm,
        this.projectId,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;

          // Clear form
          // this.investmentItemForm.reset();

          // navigate via window to same page
          // window.location.href = '/portal/operating-expenses';
          // Return to 
          this.updateInProgress = false;
          this.showProjectDealerOperatingExpenseEdit = false;
          this.toastr.success(`Step 11. Operating Expense - Dealer - Updated`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
          }));
  }

  // updateProjectWorkingCapital(
  //   workingCapitalForm: any,
  //   averageWorkingCapitalForm: any,
  //   inflationRateForm: any,
  //   inflationFactorForm: any,
  //   averageWorkingCapitalByInflationFactorForm: any,
  //   fuelsAndLubricantVolumeForm: any,
  //   workingCapitalMainForm: any,
  //   changeInWorkingCapitalForm: any,
  // ) {

  //   this.updateInProgress = true;
  //   this.subscription.add(
  //     this.projectService.updateProjectWorkingCapital(
  //       this.projectId,
  //       workingCapitalForm,
  //       averageWorkingCapitalForm,
  //       inflationRateForm,
  //       inflationFactorForm,
  //       averageWorkingCapitalByInflationFactorForm,
  //       fuelsAndLubricantVolumeForm,
  //       workingCapitalMainForm,
  //       changeInWorkingCapitalForm,

  //     )
  //       .pipe(first()).subscribe((result) => {
  //         // navigates to confirm mail screen
  //         // this.router.navigate(['/account/confirm-email']);
  //         this.loading = false;

  //         // Clear form
  //         // this.investmentItemForm.reset();

  //         // navigate via window to same page
  //         // window.location.href = '/portal/operating-expenses';
  //         // Return to 
  //         this.updateInProgress = false;
  //         this.toastr.success(`Step 5. Working Capital - Galana - Updated`, "Info!");

  //       },
  //         (error: string) => {
  //           this.error = error;
  //           this.loading = false;
  //         }));
  // }


  /**
 * On form submit
 */
  updateProjectInvestment(
    investmentAmortizationForm: any,
  ): void {
    this.updateInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectCompanyInvestment(
        this.projectId,
        investmentAmortizationForm.brandingAmortizationPeriod,
        investmentAmortizationForm.constructionAmortizationPeriod,
        investmentAmortizationForm.equipmentAmortizationPeriod,
      )
        .pipe(first()).subscribe((result) => {
          this.toastr.success(`Step 4. Investment - Galana - Update Successful`, "Success!");
          this.updateInProgress = false;
        },
          (error: string) => {
            this.toastr.error(`Error: ${error}`, "Error!");
            this.error = error;
            this.updateInProgress = false;
          }));

  }

  submitProjectSettings() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectSettingsSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 1. Project Settings - Submitted`, "Info!");
        if (this.projectSettingsSubmitted) {
          this.showProjectSettingsSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepTwo");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectProductMargin() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectMarginSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 2. Margin - Submitted`, "Info!");
        if (this.projectMarginSubmitted) {
          this.showProjectProductMarginSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepThree");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectFuelVolumeGrowth() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectFuelVolumeGrowthSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 3. Fuel Volume Growth - Submitted`, "Info!");
        if (this.projectFuelVolumeGrowthSubmitted) {
          this.showProjectFuelVolumeGrowthSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepFour");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectDealerOperatingExpense() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectDealerOperatingExpenseSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 11. Operating Expense - Dealer - Submitted`, "Info!");
        if (this.projectDealerOperatingExpenseSubmitted) {
          this.showProjectDealerOperatingExpenseSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepTwelve");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectWorkingCapital() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectWorkingCapitalSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 5. Working Capital - Galana - Submitted`, "Info!");
        if (this.projectWorkingCapitalSubmitted) {
          this.showProjectWorkingCapitalSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepSix");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  updateProjectWorkingCapital(
    workingCapitalForm: any
  ) {
    this.submitInProgress = true;
    this.updateInProgress = true;


    // Check
    if (this.workingCapitalForm.get("whiteProductStockLogisticsDays").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the White Product Stock Logistics Days!";
      this.alert_type = "danger";

      this.toastr.error('Provide the White Product Stock Logistics Days!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.workingCapitalForm.get("whiteProductSupplierCreditDays").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the White Product Supplier Credit Days!";
      this.alert_type = "danger";

      this.toastr.error('Provide the White Product Supplier Credit Days!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.workingCapitalForm.get("lubricantStockLogisticsDays").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Lubricant Stock Logistics Days!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Lubricant Stock Logistics Days!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.workingCapitalForm.get("lubricantSupplierCreditDays").invalid) {
      this.show_alert = true;
      this.alert_message = "Provide the Lubricant Supplier Credit Days!";
      this.alert_type = "danger";

      this.toastr.error('Provide the Lubricant Supplier Credit Days!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }


    this.subscription.add(
      this.projectService.updateProjectWorkingCapital(
        this.projectId,
        workingCapitalForm
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Step 5. Working Capital - Galana - Updated`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          }));
  }


  submitProjectTaxes() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectTaxesSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 8. Taxes - Galana - Submitted`, "Info!");
        if (this.projectTaxesSubmitted) {
          this.showProjectTaxesSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepNine");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectOtherRevenue() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectOtherRevenueSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 6. Other Revenue - Galana - Submitted`, "Info!");
        if (this.projectOtherRevenueSubmitted) {
          this.showProjectOtherRevenueSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepSeven");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectCompanyOperatingExpenses() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectCompanyOperatingExpensesSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 7. Operating Expenses - Galana - Submitted`, "Info!");
        if (this.projectCompanyOperatingExpensesSubmitted) {
          this.showProjectCompanyOperatingExpensesSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepEight");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  };

  updateProjectCompanyOperatingExpenses(
    companyOperatingExpensesForm: any
  ) {
    this.updateInProgress = true;
    this.submitInProgress = true;

    // First Update
    this.subscription.add(
      this.projectService.updateProjectCompanyOperatingExpenses(
        this.projectId,
        companyOperatingExpensesForm
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.submitInProgress = false;
          this.toastr.success(`Step 7. Operational Expenses - Galana - Updated`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  }

  submitProjectCompanySummary() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectCompanySummarySubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 10. Company Summary - Galana - Submitted`, "Info!");
        if (this.projectCompanySummarySubmitted) {
          this.showProjectCompanySummarySection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepEleven");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  updateProjectFinancing(
    financingForm: any
  ) {
    this.submitInProgress = true;
    this.updateInProgress = true;

    // Check
    if (this.financingForm.get("loanAmount").invalid) {
      this.show_alert = true;
      this.alert_message = "Loan amount is missing!";
      this.alert_type = "danger";

      this.toastr.error('Loan amount is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Check
    if (this.financingForm.get("interestRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Interest Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Interest Rate is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Check
    if (this.financingForm.get("loanYears").invalid) {
      this.show_alert = true;
      this.alert_message = "loan period is missing!";
      this.alert_type = "danger";

      this.toastr.error('loan period is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Check
    if (this.financingForm.get("investmentPercentageFinancedByLoan").invalid) {
      this.show_alert = true;
      this.alert_message = "Investment Percentage Financed By Loan is missing!";
      this.alert_type = "danger";

      this.toastr.error('Investment Percentage Financed By Loan is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    // Check
    if (this.financingForm.get("investmentInterestRate").invalid) {
      this.show_alert = true;
      this.alert_message = "Investment Interest Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Investment Interest Rate is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    };

    this.subscription.add(
      this.projectService.updateProjectFinancing(
        this.projectId,
        financingForm
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Step 9. Financing - Galana - Updated`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          }));

   };

  submitProjectFinancing() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectFinancingSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 9. Financing - Galana - Submitted`, "Info!");
        if (this.projectFinancingSubmitted) {
          this.showProjectFinancingSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepTen");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectInvestment() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectInvestmentSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 4. Investment - Galana - Submitted`, "Info!");
        if (this.projectInvestmentSubmitted) {
          this.showProjectInvestmentSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepFive");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  updateProjectDealerFees() { };

  submitProjectDealerRevenue() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectDealerRevenueSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 12. Revenue - Dealer - Submitted`, "Info!");
        if (this.projectDealerRevenueSubmitted) {
          this.showProjectDealerRevenueSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepThirteen");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  };

  submitProjectDealerFees() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectDealerFeesSubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 13. Fees - Dealer - Submitted`, "Info!");
        if (this.projectDealerFeesSubmitted) {
          this.showProjectDealerFeesSection = false;
        };
        setTimeout(() => {
          this.scrollToElement("stepFourteen");
        }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  submitProjectDealerSummary() {
    this.submitInProgress = true;
    this.subscription.add(
      this.projectService.updateProjectProgress("projectDealerSummarySubmitted", true, this.projectId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Step 14. Summary - Dealer - Submitted`, "Info!");
        if (this.projectDealerSummarySubmitted) {
          this.showProjectDealerSummarySection = false;
        };
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  addDealerRevenueNonFuelItem(dealerRevenueNonFuelItemForm: any) {
    this.updateInProgress = true;

    // Check
    if (this.dealerRevenueNonFuelForm.get("name").invalid) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.dealerRevenueNonFuelForm.get("yearly").invalid) {
      this.show_alert = true;
      this.alert_message = "Yearly amount is missing!";
      this.alert_type = "danger";

      this.toastr.error('Yearly amount is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.dealerRevenueNonFuelForm.get("rate").invalid) {
      this.show_alert = true;
      this.alert_message = "Rate is missing!";
      this.alert_type = "danger";

      this.toastr.error('Rate is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (this.dealerRevenueNonFuelForm.get("total").invalid) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.updateDealerRevenueNonFuelItem(
        this.projectId,
        dealerRevenueNonFuelItemForm,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.dealerRevenueNonFuelForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${dealerRevenueNonFuelItemForm.name} - Dealer Revenue Non Fuel Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  removeDealerRevenueNonFuelItem(index: number) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeDealerRevenueNonFuelItem(
        this.projectId,
        index,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${this.dealerRevenueNonFuelProductArray[index].name} - Dealer Revenue Non Fuel Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  removeFuelLubricantGasProductItem(itemName: string, index: number) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeFuelLubricantGasProductItem(
        this.projectId,
        itemName,
        index,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemName} - Fuel Product Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  }

  addFuelLubricantGasProductItem(itemName: string, form: any) {
    this.updateInProgress = true;

    // Check Items


    this.subscription.add(
      this.projectService.addFuelLubricantGasProductItem(
        this.projectId,
        itemName,
        form,
      )
        .pipe(first()).subscribe((result) => {
          // navigates to confirm mail screen
          // this.router.navigate(['/account/confirm-email']);
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemName} - Fuel Product Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  }


  // Show & Hide Functions
  showProjectSettingsEditButton(a: boolean) {
    this.showProjectSettingsEdit = a;
    this.scrollToElement("stepOne");
  }

  showProjectProductMarginEditButton(a: boolean) {
    this.showProjectProductMarginEdit = a;
    this.scrollToElement("stepTwo");
  };

  showProjectDealerOperatingExpenseEditButton(a: boolean) {
    this.showProjectDealerOperatingExpenseEdit = a;
    this.scrollToElement("stepEleven");
  };

  showProjectInvestmentEditButton(a: boolean) {
    this.showProjectInvestmentEdit = a;
    this.scrollToElement("stepFour");
  };

  showProjectOtherRevenueEditButton(a: boolean) {
    this.showProjectOtherRevenueEdit = a;
    this.scrollToElement("stepSix");
  };

  showProjectCompanyOperatingExpensesEditButton(a: boolean) {
    this.showProjectCompanyOperatingExpensesEdit = a;
    this.scrollToElement("stepSeven");
  }

  showProjectWorkingCapitalEditButton(a: boolean) {
    this.showProjectWorkingCapitalEdit = a;
    this.scrollToElement("stepFive");
  }

  showProjectFuelVolumeGrowthEditButton(a: boolean) {
    this.showProjectFuelVolumeGrowthEdit = a;
    this.scrollToElement("stepThree");
  }

  showProjectDealerRevenueEditButton(a: boolean) {
    this.showProjectDealerRevenueEdit = a;
    this.scrollToElement("stepTwelve");
  }

  showProjectFinancingEditButton(a: boolean) {
    this.showProjectFinancingEdit = a;
    this.scrollToElement("stepNine");
  }

  showProjectDealerFeesEditButton(a: boolean) {
    this.showProjectDealerFeesEdit = a;
    this.scrollToElement("stepThirteen");
  }

  // Show Hide Sections
  showProjectSettingsSectionButton(a: boolean) {
    this.showProjectSettingsSection = a;
    // Toast
    this.toastr.info(`View - Project Settings`, "Info!");
  }

  showProjectProductMarginSectionButton(a: boolean) {
    this.showProjectProductMarginSection = a;
    // Toast
    this.toastr.info(`View - Product Margins`, "Info!");
  }

  showProjectDealerOperatingExpenseSectionButton(a: boolean) {
    this.showProjectDealerOperatingExpenseSection = a;
    // Toast
    this.toastr.info(`View - Operating Expense`, "Info!");
  }

  showProjectWorkingCapitalSectionButton(a: boolean) {
    this.showProjectWorkingCapitalSection = a;
    // Toast
    this.toastr.info(`View - Working Capital`, "Info!");
  }

  showProjectTaxesSectionButton(a: boolean) {
    this.showProjectTaxesSection = a;
    // Toast
    this.toastr.info(`View - Taxes`, "Info!");
  }

  showProjectCompanySummarySectionButton(a: boolean) {
    this.showProjectCompanySummarySection = a;
    // Toast
    this.toastr.info(`View - Company Summary`, "Info!");
  }

  showProjectCompanyOperatingExpensesSectionButton(a: boolean) {
    this.showProjectCompanyOperatingExpensesSection = a;
    // Toast
    this.toastr.info(`View - Operational Expenses`, "Info!");
  }

  showProjectOtherRevenueSectionButton(a: boolean) {
    this.showProjectOtherRevenueSection = a;
    // Toast
    this.toastr.info(`View - Other Revenue`, "Info!");
  }

  showProjectFinancingSectionButton(a: boolean) {
    this.showProjectFinancingSection = a;
    // Toast
    this.toastr.info(`View - Financing`, "Info!");
  }

  showProjectInvestmentSectionButton(a: boolean) {
    this.showProjectInvestmentSection = a;
    // Toast
    this.toastr.info(`View - Investment`, "Info!");
  }

  showProjectFuelVolumeGrowthSectionButton(a: boolean) {
    this.showProjectFuelVolumeGrowthSection = a;
    // Toast
    this.toastr.info(`View - Fuel Volume Growth`, "Info!");
  }

  showProjectDealerRevenueSectionButton(a: boolean) {
    this.showProjectDealerRevenueSection = a;
    // Toast
    this.toastr.info(`View - Dealer Revenue`, "Info!");
  }

  showProjectDealerFeesSectionButton(a: boolean) {
    this.showProjectDealerFeesSection = a;
    // Toast
    this.toastr.info(`View - Dealer Fees`, "Info!");
  }

  showProjectDealerSummarySectionButton(a: boolean) {
    this.showProjectDealerSummarySection = a;
    // Toast
    this.toastr.info(`View - Dealer Results`, "Info!");
  }

  navigateTo(type: string) {

    if (type === "report") {
      this.router.navigate([`/portal/report/${this.projectId}`]);
      this.toastr.info(`View/Edit Report`, "Info!");
    }

    if (type === "result") {
      this.router.navigate([`/portal/result/${this.projectId}`]);
      this.toastr.info(`View/Edit Result`, "Info!");
    }

    if (type === "companyPDF") {
      // this.toastr.info(`PDF Download Feature under development`, "Info!");
      this.printCompanySummaryPDF();
    }

    if (type === "dealerPDF") {
      // this.toastr.info(`PDF Download Feature under development`, "Info!");
      this.printDealerSummaryPDF();
    }

  }

  getBase64Image() {
    let arrayIndex = 0;
    let giftBase64ImageArrayTwo = [];

    this.giftBase64ImageArray.forEach(element => {
      // let url = "https://cdn.glitch.com/4c9ebeb9-8b9a-4adc-ad0a-238d9ae00bb5%2Fmdn_logo-only_color.svg?1535749917189";
      let url = element.url;
      let w = element.width;
      let h = element.height;
      let t = element.type;
      let giftIndex = element.giftId;

      var img = new Image();
      img.crossOrigin = 'anonymous';
      img.addEventListener("load", imageReceived, true);
      img.src = url;

      function imageReceived() {

        const canvas = document.createElement("canvas");
        canvas.id = `canvas${giftIndex}`;
        canvas.getContext("canvas", { preserveDrawingBuffer: true });
        canvas.setAttribute('crossOrigin', 'anonymous');
        const context = canvas.getContext("2d");

        canvas.width = w;
        canvas.height = h;
        canvas.innerText = "";

        context.fillStyle = "#FFF";
        context.fillRect(0, 0, w, h);
        context.drawImage(img, 0, 0, w, h);

        // imageBox.appendChild(canvas);

        try {

          if (t === "image/jpeg") {
            var dataURL = canvas.toDataURL("image/jpeg", 0.5);
            arrayIndex += 1;

            if (dataURL) {
              giftBase64ImageArrayTwo.push({
                giftId: giftIndex,
                width: w,
                height: h,
                type: t,
                url: url,
                dataURL: dataURL,
              });
            }
          }

          if (t === "image/png") {
            var dataURL = canvas.toDataURL("image/png", 0.5);
            arrayIndex += 1;

            if (dataURL) {
              giftBase64ImageArrayTwo.push({
                giftId: giftIndex,
                width: w,
                height: h,
                type: t,
                url: url,
                dataURL: dataURL,
              });
            }
          }


        } catch (err) {
          console.error(`Error: ${err}`);
        }
      }



    });

    this.giftBase64ImageArrayTwo = giftBase64ImageArrayTwo;

  }

  printDealerSummaryPDF() {
    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Dealer Summary ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.projectName} - Dealer Summary - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      //currentHeight = 15;
      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        10 + 0,
        currentHeight - 5 + 0,
        35,
        35
      );

      doc.setTextColor(colorGray);
      doc.setFontSize(pdfConfig.headerFieldTextSize);

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Delta Office Suites 2nd floor,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Block B Off Waiyaki Way &", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Manyani West Road, Muthangari.", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("+254 709 497 000", docWidth - 10, currentHeight, { align: 'right' });
      doc.setFontSize(pdfConfig.headerFieldTextSize);
      // doc.setTextColor(colorGray);
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("info@galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("www.galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      //line breaker after logo & business info
      doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Project Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    doc.setFontSize(12);

    // General Info
    doc.line(5, currentHeight, docWidth - 5, currentHeight);
    currentHeight += pdfConfig.lineHeight;
    doc.text("Project Info", (docWidth / 4), currentHeight, { align: 'center' },);
    doc.text("Results Summary", (docWidth / 4) * 3, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.line(5, currentHeight, docWidth - 5, currentHeight);
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Project Name :", 15, currentHeight, { align: 'left' },);
    if (this.projectName) { doc.text(`${this.projectName}`, (docWidth / 4), currentHeight, { align: 'left' },); };
    doc.text("NPV at 11% :", (docWidth / 2) + 15, currentHeight, { align: 'left' },);
    if (this.projectNPV) { doc.text(`${formatCurrency(this.projectNPV, 'en-US', '', '', '1.2-2')}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Country :", 15, currentHeight, { align: 'left' },);
    if (this.projectCountry) { doc.text(`${this.projectCountry}`, (docWidth / 4), currentHeight, { align: 'left' },); }
    doc.text("IRR :", (docWidth / 2) + 15, currentHeight, { align: 'left' },);
    if (this.projectIrr) { doc.text(`${this.projectIrr}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Project Start :", 15, currentHeight, { align: 'left' },);
    if (this.projectInvestmentStartDate) { doc.text(`${this.projectInvestmentStartDate}`, (docWidth / 4), currentHeight, { align: 'left' },); }
    doc.text("Payback Period :", (docWidth / 2) + 15, currentHeight, { align: 'left' },);
    if (this.projectPaybackPeriod) { doc.text(`${this.projectPaybackPeriod}`, (docWidth / 4) * 3, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Dealer Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;
    doc.setFontSize(11);

    doc.text("Revenues", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryRevenue) { doc.text(`${formatCurrency(this.dealerSummaryRevenue, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Expenses", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryExpense) { doc.text(`(${formatCurrency(this.dealerSummaryExpense, 'en-US', '', '', '1.2-2')})`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("1.", 15, currentHeight, { align: 'left' },);
    doc.text("EBITDA (Before Fees)", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryEbitdaBeforeFees) { doc.text(`${formatCurrency(this.dealerSummaryEbitdaBeforeFees, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Fees", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryFees) { doc.text(`(${formatCurrency(this.dealerSummaryFees, 'en-US', '', '', '1.2-2')})`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("2.", 15, currentHeight, { align: 'left' },);
    doc.text("EBITDA", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryEbitda) { doc.text(`${formatCurrency(this.dealerSummaryEbitda, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.text("Taxable Profit", (((docWidth / 4) * 3) - 70), currentHeight, { align: 'left' },);
    doc.text("Rate", (((docWidth / 4) * 3) - 20), currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Corporate Income Tax", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryTaxableProfit) { doc.text(`${formatCurrency(this.dealerSummaryTaxableProfit, 'en-US', '', '', '1.2-2')}`, (((docWidth / 4) * 3) - 70), currentHeight, { align: 'left' },); }
    if (this.dealerSummaryTaxRate) { doc.text(`${formatCurrency(this.dealerSummaryTaxRate, 'en-US', '', '', '1.2-2')} %`, (((docWidth / 4) * 3) - 20), currentHeight, { align: 'left' },); }
    if (this.dealerSummaryTax) { doc.text(`(${formatCurrency(this.dealerSummaryTax, 'en-US', '', '', '1.2-2')})`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("3.", 15, currentHeight, { align: 'left' },);
    doc.text("Net Cash After Tax Before Deposit", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryNetCashAfterTaxBeforeDeposit) { doc.text(`${formatCurrency(this.dealerSummaryNetCashAfterTaxBeforeDeposit, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },); }
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Deposit", 20, currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.text("White Products Volume", (((docWidth / 4) * 3) - 70), currentHeight, { align: 'left' },);
    doc.text("Rate", (((docWidth / 4) * 3) - 20), currentHeight, { align: 'left' },);
    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.subLineHeight;
    doc.text("to build WCR or loan repay.", 20, currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')} m3`, (((docWidth / 4) * 3) - 70), currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')} %`, (((docWidth / 4) * 3) - 20), currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.text("for security deposit", 20, currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')} m3`, (((docWidth / 4) * 3) - 70), currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')} %`, (((docWidth / 4) * 3) - 20), currentHeight, { align: 'left' },);
    doc.text(`${formatCurrency(0, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.text("4.", 15, currentHeight, { align: 'left' },);
    doc.text("Net Cash After Tax & Deposits", 20, currentHeight, { align: 'left' },);
    if (this.dealerSummaryNetCashAfterTaxAndDeposit) { doc.text(`${formatCurrency(this.dealerSummaryNetCashAfterTaxAndDeposit, 'en-US', '', '', '1.2-2')}`, (docWidth - 20), currentHeight, { align: 'right' },); }

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorGray);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            1,
            doc.internal.pageSize.height - 30,
            210,
            30
          );

          // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
          if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
          if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
          doc.text("From: www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
          doc.setPage(i);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 7
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        1,
        doc.internal.pageSize.height - 30,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorGray);
      // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
      if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
      if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
      doc.text("www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 7
      );
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  }

  printCompanySummaryPDF() {
    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    this.notify("Downloading Company Summary ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.projectName} - Company Summary - ${this.dateToday}.pdf`,
      orientationLandscape: true,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      //currentHeight = 15;
      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        10 + 0,
        currentHeight - 5 + 0,
        35,
        35
      );

      doc.setTextColor(colorGray);
      doc.setFontSize(pdfConfig.headerFieldTextSize);

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Delta Office Suites 2nd floor,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Block B Off Waiyaki Way", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("& Manyani West Road,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Muthangari.", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("+254 709 497 000", docWidth - 10, currentHeight, { align: 'right' });
      doc.setFontSize(pdfConfig.headerFieldTextSize);
      // doc.setTextColor(colorGray);
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("info@galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("www.galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      //line breaker after logo & business info
      // doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 5;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    doc.line(60, currentHeight, docWidth - 60, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Project Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(60, currentHeight, docWidth - 60, currentHeight);

    doc.setFontSize(12);

    // General Info
    doc.line(60, currentHeight, docWidth - 60, currentHeight);
    currentHeight += pdfConfig.lineHeight;
    doc.text("Project Info", (210 / 4) + 40, currentHeight, { align: 'center' },);
    doc.text("Results Summary", ((210 / 4) * 3) + 40, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;
    doc.line(60, currentHeight, docWidth - 60, currentHeight);
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Project Name :", 60, currentHeight, { align: 'left' },);
    if (this.projectName) { doc.text(`${this.projectName}`, (210 / 4) + 40, currentHeight, { align: 'left' },); };
    doc.text("NPV at 11% :", (210 / 2) + 50, currentHeight, { align: 'left' },);
    if (this.projectNPV) { doc.text(`${formatCurrency(this.projectNPV, 'en-US', '', '', '1.2-2')}`, ((210 / 4) * 3) + 40, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Country :", 60, currentHeight, { align: 'left' },);
    if (this.projectCountry) { doc.text(`${this.projectCountry}`, (210 / 4) + 40, currentHeight, { align: 'left' },); }
    doc.text("IRR :", (210 / 2) + 50, currentHeight, { align: 'left' },);
    if (this.projectIrr) { doc.text(`${this.projectIrr}`, ((210 / 4) * 3) + 40, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;
    doc.text("Project Start :", 60, currentHeight, { align: 'left' },);
    if (this.projectInvestmentStartDate) { doc.text(`${this.projectInvestmentStartDate}`, (210 / 4) + 40, currentHeight, { align: 'left' },); }
    doc.text("Payback Period :", (210 / 2) + 50, currentHeight, { align: 'left' },);
    if (this.projectPaybackPeriod) { doc.text(`${this.projectPaybackPeriod}`, ((210 / 4) * 3) + 40, currentHeight, { align: 'left' },); };
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Company - Profit & Loss Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;
    doc.setFontSize(12);

    doc.setTextColor(colorGray);

    // Titles
    doc.text(`Year 1`, 100, currentHeight, { align: 'left' },);
    doc.text(`Year 2`, 135, currentHeight, { align: 'left' },);
    doc.text(`Year 3`, 170, currentHeight, { align: 'left' },);
    doc.text(`Year 4`, 205, currentHeight, { align: 'left' },);
    doc.text(`Year 5`, 240, currentHeight, { align: 'left' },);

    doc.setTextColor(colorBlack);
    currentHeight += pdfConfig.lineHeight;

    doc.text("Net Fuel Margin", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryNetFuelMarginArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryNetFuelMarginArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }

        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Diversification", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryDiversificationMarginArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryDiversificationMarginArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Other Revenues", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryOtherRevenueArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryOtherRevenueArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Operating Expenses", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryOperatingExpensesArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryOperatingExpensesArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Rentals & Fees Due", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryRentalsAndFeesDueArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryRentalsAndFeesDueArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;
    doc.setTextColor(colorGray);
    doc.setFont('Volkhov', 'italic');
    doc.text("Financial Interest Received", 23, currentHeight, { align: 'left' },);

    if (this.companyInterestReceivedArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companyInterestReceivedArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.text("EBITDA", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryEBITDAArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryEBITDAArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Investments", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryInvestmentArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryInvestmentArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Change in Working Capital", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryChangeInWorkingCapitalArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryChangeInWorkingCapitalArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Taxes", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryTaxesArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryTaxesArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Other Cash Items", 20, currentHeight, { align: 'left' },);
    if (this.companySummaryOtherCashItemsArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryOtherCashItemsArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              if (indexA === 0) {
                doc.text(`(${formatCurrency(element, 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
              } else {
                doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
              }

            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Net Cash After Tax", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryNetCashAfterTaxArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryNetCashAfterTaxArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Financial Result", 20, currentHeight, { align: 'left' },);
    if (this.financingTotalArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.financingTotalArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency(element * (-1), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency(element, 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Inflation Rate (%)", 20, currentHeight, { align: 'left' },);
    if (this.inflationRateArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.inflationRateArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * 100 * (-1)), 'en-US', '', '', '1.2-2')}) %`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element * 100), 'en-US', '', '', '1.2-2')} %`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.lineHeight;

    doc.text("Net Cash After Tax (Discounted)", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryNetCashAfterTaxDiscountedArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryNetCashAfterTaxDiscountedArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };
    currentHeight += pdfConfig.subLineHeight;

    doc.text("Net Cash After Tax (Real & Discounted)", 15, currentHeight, { align: 'left' },);
    if (this.companySummaryDiscountedNetCashAfterTaxRealArray.length > 0) {

      let x: number[] = [];
      let indexA = 0;
      let xPosition = 0;
      xPosition = 100;
      x = this.companySummaryDiscountedNetCashAfterTaxRealArray;

      x.forEach(element => {
        if (indexA < 5) {
          if (element === 0) {
            doc.text(`-`, xPosition, currentHeight, { align: 'left' },);
          } else {
            if (element < 0) {
              doc.text(`(${formatCurrency((element * (-1)), 'en-US', '', '', '1.2-2')})`, xPosition, currentHeight, { align: 'left' },);
            } else {
              doc.text(`${formatCurrency((element), 'en-US', '', '', '1.2-2')}`, xPosition, currentHeight, { align: 'left' },);
            }
          }
        };

        indexA += 1;
        xPosition += 35;
      });


    };


    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorGray);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_297_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            1,
            doc.internal.pageSize.height - 30,
            297,
            30
          );

          // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
          if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
          if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
          doc.text("From: www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
          doc.setPage(i);
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 7
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_297_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        1,
        doc.internal.pageSize.height - 30,
        297,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorGray);
      // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
      if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
      if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
      doc.text("www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 7
      );
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  }

  scrollToElement(a: string) {

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  addConstructionItem(itemForm: any) {

    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }
    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addCompanyInvestmentItem(
        this.projectId,
        "construction",
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.constructionItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };

  addEquipmentItem(itemForm: any) {

    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }
    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addCompanyInvestmentItem(
        this.projectId,
        "equipment",
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.equipmentItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };

  addBrandingItem(itemForm: any) {
    this.updateInProgress = true;

    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }
    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addCompanyInvestmentItem(
        this.projectId,
        "branding",
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.brandingItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };

  removeConstructionItem(i: number) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeCompanyInvestmentItem(
        this.projectId,
        "construction",
        i,
      )
        .pipe(first()).subscribe((result) => {
          this.constructionItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };
  removeEquipmentItem(i: number) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeCompanyInvestmentItem(
        this.projectId,
        "equipment",
        i,
      )
        .pipe(first()).subscribe((result) => {
          this.equipmentItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };
  removeBrandingItem(i: number) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeCompanyInvestmentItem(
        this.projectId,
        "branding",
        i,
      )
        .pipe(first()).subscribe((result) => {
          this.brandingItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );
  };


  removeDiversificationVariableFeesItem(itemIndex: any) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeDiversificationVariableFeesItem(
        this.projectId,
        itemIndex,
      )
        .pipe(first()).subscribe((result) => {
          this.diversificationVariableFeesForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  addDiversificationVariableFeesItem(itemForm: any) {

    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addDiversificationVariableFeesItem(
        this.projectId,
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.diversificationVariableFeesForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  removeDiversificationFixedFeesItem(itemIndex: any) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeDiversificationFixedFeesItem(
        this.projectId,
        itemIndex,
      )
        .pipe(first()).subscribe((result) => {
          this.diversificationFixedFeesForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  addDiversificationFixedFeesItem(itemForm: any) {
    this.updateInProgress = true;
    
    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addDiversificationFixedFeesItem(
        this.projectId,
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.diversificationFixedFeesForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  removeDealerOperatingExpenseItem(itemIndex: any, itemName: string) {
    this.updateInProgress = true;

    this.subscription.add(
      this.projectService.removeDealerOperatingExpenseItem(
        this.projectId,
        itemName,
        itemIndex,
      )
        .pipe(first()).subscribe((result) => {
          this.electricityItemForm.reset();
          this.salariesAndBonusItemForm.reset();
          this.employeeContributionItemForm.reset();
          this.miscellaneousItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`Item - Removed`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  addDealerOperatingExpenseItem(itemForm: any, itemName: string) {
    this.updateInProgress = true;

    // Check
    if (!(itemForm.name)) {
      this.show_alert = true;
      this.alert_message = "Name is missing!";
      this.alert_type = "danger";

      this.toastr.error('Name is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(itemForm.total > 0)) {
      this.show_alert = true;
      this.alert_message = "Total is missing!";
      this.alert_type = "danger";

      this.toastr.error('Total is missing!', 'Error!');
      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;
      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.addDealerOperatingExpenseItem(
        this.projectId,
        itemName,
        itemForm,
      )
        .pipe(first()).subscribe((result) => {
          this.electricityItemForm.reset();
          this.salariesAndBonusItemForm.reset();
          this.employeeContributionItemForm.reset();
          this.miscellaneousItemForm.reset();
          this.loading = false;
          this.updateInProgress = false;
          this.submitInProgress = false;
          this.toastr.success(`${itemForm.name} - Item - Added`, "Info!");

        },
          (error: string) => {
            this.error = error;
            this.loading = false;
            this.updateInProgress = false;
            this.submitInProgress = false;
          })
    );

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
